import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import FooterSection from "../components/UI/FooterSection";
import ReactDatatable from "@ashvin27/react-datatable";
import BusinessUserSideBar from "../components/container/SideBar";
import Header from "../components/container/layout/pharmacy/Header";
import CheckboxOrRadioGroup from "../components/UI/CheckboxOrRadioGroup";
import InputField from "../components/UI/InputField";
import axios from "axios";
import { Popup } from "../components/UI/Popup";
import { Spinner } from "../components/UI/Spinner";
import { ToastContainer, toast } from 'react-toastify';
import { gst_type } from "../config/data";
import 'react-toastify/dist/ReactToastify.css';
import Label from "../components/UI/Label";
import DatePicker from "react-date-picker";
import PhoneInput, {
    parsePhoneNumber,
    isValidPhoneNumber,
    isPossiblePhoneNumber,
  } from "react-phone-number-input";
import {
  
  getAllDrugs,
   getPharmacyList,
  addPharmacy,
  hospitalpharmacyimport,
  getAllPharmacyVendorList,
  addPharmacyVendor,
  updatePharmacyVendor,
   deleteDrug,
  searchDrug,
  getTimeoutbycategory,
  
} from "../config/url.json";
import { CONFIG } from "../config/data";
import Select from "react-select";
import Validation from "../actions/Validation";
import SpanField from "../components/UI/SpanField";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import "react-table/react-table.css";
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModal } from '../components/container/modal/IdleModal';
import ExpirePopup from "../components/UI/ExpirePopup"
import Tooltip from '@mui/material/Tooltip';
import DrugsExport from "./DrugsExport";
import DrugTemplate from "./DrugTemplate";
import LoadingSpinner from "../components/UI/LoadingSpinner";
import LoadingHeartrate from "../components/UI/Heartrate";
import readXlsxFile from 'read-excel-file';
import { Popupinfo } from "../components/UI/Popupinfo";
import Dnaspinner from "../components/UI/Dnaspinner";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";



function mapStateToProps(state) {
  return {
    appdata: state.AppData,
    userdata: state.AuthReducer.user,
    access_token: state.AuthReducer.access_token,
    validity: state.ValidityReducer.validity
  };
}
function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch({ type: "SET_LOGOUT" }),
  };
}

const google = window.google;
const vendor_status = ["Active", "In-active"];

class VendorList extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = React.createRef()
    const hospitaltimeout = this.state;

    this.state = {
      adminstatus: this.props.userdata.adminstatus,
      showexpirePopup: this.props.validity,
      timeout: hospitaltimeout,
      isTimedOut: false,
      showModal: false,
      records: [],
      google_address: false,
      searchrecords: [],
      vendor_action: "Add",
      loading: false,
      addDrugSuccess: "",
      addVendorSuccess: "",
      showDeleteConfirmPopup: false,
      confirmedDelete: false,
      search_action: false,
      recordToDelete: [],
      newDatatoAdd: {},
      addPopup: false,
      formSuccess: {
        message: "",
        status: "",
    },
       
      vendorpharmacyData: {
        // id: "",
        vendor_id:"",
        vendor_name: "",
        address1: "",
        address2: "",
        city: "",
        country: "",
        state: "",
        zip_code: "",
        phone_number: "",
        email:"",
        contact_name:"",
        gstreg_type:"",
        pan:"",
        gst_no:"",
        gst_country:"",
        gst_state:"",
        food_licence: "",
        food_expirydate:"",
        drug_licence1: "",
        licence1_expirydate:"",
        drug_licence2: "",
        licence2_expirydate:"",
        drug_licence3: '',
        licence3_expirydate:"",
        vendor_status:"Active",
        credit_days:"",
        hold_payment:"",
        holdpaymentreason:""
             },
    
      errors: {
      
        id:"",
        vendor_id:"",
        vendor_name: "",
        address1: "",
        address2: "",
        city: "",
        country: "",
        state: "",
        zip_code: "",
        phone_number: "",
        email:"",
        contact_name:"",
        gstreg_type:"",
        pan:"",
        gst_no:"",
        gst_state:"",
        gst_country:"",
        gst_state:"",
        food_licence: "",
        food_expirydate:"",
        drug_licence1: "",
        licence1_expirydate:"",
        drug_licence2: "",
        licence2_expirydate:"",
        drug_licence3: '',
        licence3_expirydate:"",
        vendor_status:"",
        credit_days:"",
        hold_payment:"",
        holdpaymentreason:""
      },
      
      searchData: {
       
        vendor_id:"",
        vendor_name: "",
        address1: "",
        phone_number: "",
      
      },
      tableSuccess: {
        message: "",
        status: "",
      },
      formSuccess: {
        message: "",
        status: "",
      },
      editformSuccess: {
        message: "",
        status: "",
      },
      searchTableSuccess: {
        message: "",
        status: "",
      },
      columns: [
        { title: "Vendor Id", field: "vendor_id" },
        { title: "Vendor Name", field: "vendor_name" },
        { title: "address1", field: "address1" },
        { title: "phone_number", field: "phone_number" },
        // { title: "Manufacturer", field: "manufacturer" },
        // { title: "Active Ingredients", field: "active_ingredients" },
      ],
      data: [
        {
          vendor_id: "1",
          vendor_name: "da",
          address1: "ds",
          phone_number: "sdd",
          // manufacturer: "sdsd",
          // active_ingredients: "adsfd",
        },
      ],
      searchErrorMessage: "",
    };
    this.columns = [
      {
        key: "s_no",
        text: "S.No",
        className: "s_no",
        align: "left",
        sortable: true,
        width: 10,
      },
      {
        key: "vendor_id",
        text: "Vendor ID",
        className: "vendor_id",
        align: "left",
        sortable: true,
        width: 100,
      },
      {
        key: "vendor_name",
        text: "Vendor Name",
        className: "vendor_name",
        align: "left",
        sortable: true,
        width: 100,
      },
      {
        key: "address1",
        text: "Address",
        className: "address1",
        sortable: true,
        align: "left",
        width: 100,

      
      },
      
      {
        key: "phone_number",
        text: "Phone",
        className: "phone_number",
        sortable: true,
        align: "left",
        width: 100,
      },

    
      {
        key: "action",
        text: "Action",
        className: "action",
        width: 100,
        align: "left",
        sortable: false,
        cell: (record) => {
          return (
            <Fragment>
                 <Tooltip title="Edit">
              <button
                className="btn btn-primary btn-sm"
                onClick={() => this.editRecord(record)}
                style={{ marginRight: "5px" }}
              >
                <i className="fa fa-edit"></i>
              </button>
              </Tooltip>
              <Tooltip title="Delete">
              <button
                className="btn btn-danger btn-sm"
                onClick={() => this.onDeleteClick(record)}
              >
                <i className="fa fa-trash"></i>
              </button>
              </Tooltip>
            </Fragment>
          );
        },
      },
    ];
    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50],
      button: {
        excel: false,
        print: false,
      },
    };
    this.searchconfig = {
      page_size: 10,
      length_menu: [10, 20, 50],
      button: {
        excel: false,
        print: false,
      },
    };

    this.searchcolumns = [
      // {
      //   key: "s_no",
      //   text: "S.No",
      //   className: "s_no",
      //   align: "left",
      //   sortable: true,
      // },
      {
        key: "vendor_id",
        text: "Vendor ID",
        className: "vendor_id",
        align: "left",
        sortable: true,
        width: 10,
        cell: (record) => {
          // let obj = {
          //   label: record.availability,
          //   value: "Yes" === record.availability ? 1 : 0,
          // };
          return (
            <Fragment>
              <InputField
                inputType={"text"}
                isMandatory={true}
                name={"searchvendor_id"}
                controlFunc={this.handleInputsearch.bind(this, record)}
                content={record.searchvendor_id}
                placeholder={""}
                max={5}
              />
              <SpanField classname="text-danger" title={record.vendorid_error} />
            </Fragment>
          );
        },
      },
      {
        key: "vendor_name_hide",
        text: "vendor_name_hide",
        className: "vendor_name hide",
        TrOnlyClassName: "hide",
        align: "left",
        sortable: true,
        width: 100,
      },

      {
        key: "vendor_name",
        text: "Vendor Name",
        className: "vendor_name",
        align: "left",
        sortable: true,
        width: 100,

        // cell: (record) =>
        //  {
        //   return (
        //     <Fragment>

        /* <InputField
          inputType={"text"}
           isMandatory={true}
            name={"drug_name"}
            controlFunc={this.handleInput.bind(this)}
            content={record.drug_name.label}
             placeholder={""}
             max={50}
             />
              */
        //       <SpanField
        //         classname="text-danger"
        //         title={record.drugname_error}
        //       />
        //     </Fragment>
        //   );
        // },
      },
      {
        key: "address1",
        text: "Address",
        className: "address1",
        sortable: true,
        align: "left",
        width: 100,

      
      },
      {
        key: "phone_number",
        text: "Phone",
        className: "phone_number",
        align: "left",
        sortable: true,
        width: 100,

        // cell: (record) => {
        //   return (
        //     <Fragment>
           
        //       <InputField
        //         inputType={"text"}
        //         isMandatory={true}
        //         name={"unit_of_measure"}
        //         controlFunc={this.handleInputsearch.bind(this, record)}
        //         content={record.unit_of_measure ? record.unit_of_measure.label : ""}
        //         placeholder={""}
        //         max={50}
        //       />

        //       <SpanField
        //         classname="text-danger"
        //         title={record.measure_error}
        //       />
        //     </Fragment>
        //   );
        // },

      },

      {
        key: "action",
        text: "Action",
        className: "action",
        width: 20,
        align: "left",
        sortable: false,
        cell: (record) => {
          return (
            <Fragment>
              <button
                className="btn btn-primary btn-sm"
                onClick={() => this.addRecord(record)}
                style={{ marginRight: "5px" }}
              >
                <i className="fa fa-plus"></i>
              </button>
              {/* <button
                className="btn btn-danger btn-sm"
                onClick={() => this.deleteRecord(record)}
              >
                <i className="fa fa-trash"></i>
              </button> */}
            </Fragment>
          );
        },
      },
    ];
    this.autocomplete = null;
    this.handlePlaceSelect = this.handlePlaceSelect.bind(this);
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleLogout = this.handleLogout.bind(this)
    
  }

  handlePlaceSelect() {
    let place = this.autocomplete.getPlace();
    let errors=this.state.errors;
     let { vendorpharmacyData } = this.state;
    
    var componentForm = {
      street_number: "long_name",
      administrative_area_level_1: "long_name",
      locality: "long_name",
      country: "long_name",
      sublocality_level_1: "long_name",
      postal_code: "long_name",
    };
   
   

    for (var i = 0; i < place.address_components.length; i++) {
      var addressType = place.address_components[i].types[0];

      if (componentForm[addressType]) {
        var val = place.address_components[i][componentForm[addressType]];
        // var valShort = place.address_components[i].short_name;
        // console.log(val);
        if (addressType === "administrative_area_level_1")
          vendorpharmacyData.state = val;
        if (addressType === "locality")
          vendorpharmacyData.city = val;
        if (addressType === "country")
             vendorpharmacyData.country = val;
        // if (addressType === "street_number") {

        vendorpharmacyData.address1 = place.name;
        // }
        if (addressType === "sublocality_level_1")
             vendorpharmacyData.address2 = val;
        if (addressType === "postal_code") 
            vendorpharmacyData.zip_code = val;
      }
    }
    
    this.setState({
      vendorpharmacyData,
      google_address: true,
      // errors
    });
  }
   handleClose() {
    this.setState({ showModal: false })
  }


  handleLogout = (e) => {
    this.setState({ showModal: false })
    e.preventDefault();
    localStorage.removeItem("token");
    this.props.logout();

  };

  _onAction(e) {
    console.log('user did something', e)

    this.setState({ isTimedOut: false })
  }

  _onActive(e) {

    console.log('user is active', e)
    this.setState({ isTimedOut: false })
  }

  _onIdle = (e) => {
    console.log('user is idle', e)
    let isTimedOut = this.state.isTimedOut
    let timeout = this.state.timeout;
    console.log(timeout);
    console.log(isTimedOut);
    if (isTimedOut===false) 
    {
     // console.log("the time is over");
      this.setState({showModal: true})
      console.log(this.state.showModal);
       this.idleTimer.reset();
       this.setState({isTimedOut: true})

    } 
  if(isTimedOut===true)
  {
    localStorage.removeItem("token");
    this.props.logout();
    
  }
  }

  handledrugType = (e) => {
    let data = this.state.drugData;
    data.drug_type = [e.target.value];
    this.setState({ drugData: data });
  };
 
  handleInputsearch = (record, event) => {
    record[event.target.name] = event.target.value;
    let max = event.target.max;
    let value=event.target.value;
     if (
       event.target.name === "vendor_id"
 
     ) {
       if (value.length > max) {
         const value1 = value.slice(0, max);
         record.vendorid_error  = "Maximum Length has exceeded.";
         record[event.target.name] = value1;
       }
      else {
       record.vendorid_error  = "";
       record[event.target.name] = event.target.value;
     }
   }
    this.UpdateColumnChange(record);
  };

  
  
  handleWorkingDays = (event) => {
    const checked = event.target.checked;
    let data = this.state.vendorpharmacyData;
    data.hold_payment=event.target.checked;
    // Check if the checkbox being changed is the first one
    if (event.target.value === '') {
      this.setState({ creditDaysChecked: checked,vendorpharmacyData:data });
    }
  };

 

  handleDatePickerChange = (date, field) => {
  const offsetDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
  
  this.setState(prevState => ({
    vendorpharmacyData: {
      ...prevState.vendorpharmacyData,
      [field]: offsetDate, // Update the specific expiry date field
    },
  }));
};

 
  UpdateColumnChange = (record) => {
    let records = this.state.searchrecords;
    var recIndex = findWithAttr(records, "id", record.id);
    records[recIndex].vendor_id = record.vendor_id;
    records[recIndex].vendor_name = record.vendor_name;
    records[recIndex].vendor_category = record.vendor_category;
    records[recIndex].vendor_type = record.vendor_type;

    this.setState({ searchrecords: records });
  };

  componentDidMount = () => {
    console.log(this.props.userdata.user_type);
    console.log(this.props.userdata.id);
    this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById("address1"),
      {}
    );
    this.autocomplete.addListener("place_changed", this.handlePlaceSelect);
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;

    axios
      .post(getTimeoutbycategory, { category: 'hospital' }, CONFIG)
      .then((res) => {
        let data = res.data;
        console.log(res);
        console.log(data);
        console.log(data.data.timeout);
        if (data.status === "1")
          this.setState({
            hospitaltimeout: data.data.timeout

          });
      })
      .catch(() => { });

      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.props.access_token}`;
      axios
        .post(getAllPharmacyVendorList  , { user_id: this.props.userdata.id }, CONFIG)
        .then((list) => {
          let drugList = list.data.data;
          let drugDataArr = [];
          drugList.forEach((element, index) => {
            let drugData = {};
            drugData.s_no = index + 1;
            drugData.id = element.id;
            drugData.user_id = this.props.userdata.id;
            drugData.vendor_id = element.vendor_id;
            drugData.vendor_name = element.vendor_name;
            drugData.address1 = element.address1;
            drugData.phone_number = element.phone_number;
            drugDataArr[index] = drugData;
          });
  
          this.setState({ records: drugDataArr });
        })
        .catch(() => { });
  
      
   
  };

  

  handlesearchinputChange = (eve) => {
    let data = this.state.searchData;

    data[eve.target.name] = eve.target.value;
    // }
    this.setState({ searchData: data });
  };
  handlesearchselectChange = (selectedOptions, e) => {
    let data = this.state.searchData;

   
    data[e.name] = selectedOptions;
    // }
    this.setState({ searchData: data });
  };

 
  editRecord(record) {
    let vendor_action = this.state.vendor_action;
    vendor_action = "Edit";
    this.setState({ vendor_action });

    let vendorpharmacyData = this.state.vendorpharmacyData;
    vendorpharmacyData.s_no = record.s_no;
    vendorpharmacyData.id = record.id;
    vendorpharmacyData.vendor_id = record.vendor_id;
    vendorpharmacyData.vendor_name = record.vendor_name;
    vendorpharmacyData.address1 = record.address1;
    vendorpharmacyData.address2 = record.address2;
   
    vendorpharmacyData.city = record.city;
    vendorpharmacyData.zip_code = record.zip_code;
    vendorpharmacyData.phone_number = record.phone_number;
    vendorpharmacyData.email = record.email;
    vendorpharmacyData.contact_name = record.contact_name;
   
   
    vendorpharmacyData.pan = record.pan;
    vendorpharmacyData.gst_no = record.gst_no;
    vendorpharmacyData.gst_state = record.gst_state;
    vendorpharmacyData.food_licence = record.food_licence;
    vendorpharmacyData.drug_licence1 = record.drug_licence1;
    vendorpharmacyData.drug_licence2 = record.drug_licence2;
    vendorpharmacyData.drug_licence3 = record.drug_licence3;
    vendorpharmacyData.expiry_date = record.expiry_date;
    vendorpharmacyData.vendor_status = record.vendor_status;
    vendorpharmacyData.credit_days = record.credit_days;
    vendorpharmacyData.hold_payment = record.hold_payment;

  
   
    this.setState({ vendorpharmacyData: vendorpharmacyData });
  }

  
  handleselectChange = (selectedOptions, e) => {
    let data = this.state.vendorpharmacyData;
    let errors = this.state.errors;
    errors[e.name] = ""
    data[e.name] = selectedOptions;
    
    this.setState({ vendorpharmacyData: data, errors,searchErrorMessage: "" });
  }

  handleVendorStatusChange = (e) => {
    let data = this.state.vendorpharmacyData; // Assuming you have vendorpharmacyData in your state
    data.vendor_status = [e.target.value]; // Update the vendor_status with the selected value in an array
    this.setState({ vendorpharmacyData: data }); // Set the updated state
  };


 handleGstType = (selectedOption) => {
    this.setState(prevState => ({
      vendorpharmacyData: {
        ...prevState.vendorpharmacyData,
        gst_type: selectedOption ? selectedOption.value : ''
      }
    }));
  };

  handlesearchinputChange = (eve) => {
    let data = this.state.searchData;

    data[eve.target.name] = eve.target.value;
    // }
    this.setState({ searchData: data });
  };
  handlesearchselectChange = (selectedOptions, e) => {
    let data = this.state.searchData;

   
    data[e.name] = selectedOptions;
    // }
    this.setState({ searchData: data });
  };
 
  validateMaxLength(name, value, max) {
    let data = this.state.vendorpharmacyData;
    let errors = this.state.errors;

    if (value.length > max) {
      const value1 = value.slice(0, max);
      errors[name] = "Maximum Length has exceeded.";
      data[name] = value1;
    } else {
      errors[name] = "";
      data[name] = value;
    }
    this.setState({ vendorpharmacyData: data, errors: errors });
  }

  handleInput = (e) => {
    let data = this.state.vendorpharmacyData;
    let errors = this.state.errors;
    let name = e.target.name;
    let value = e.target.value;
    let max = e.target.max;
    if (
      name === "vendor_id" ||
      name === "vendor_name" ||
      name === "address1" ||
      name === "address2" ||
      name === "city"||
      name === "country"||
      name === "state" ||
      name === "zip_code" ||
      name === "email" ||
      name === "contact_name" ||
      name === "gstreg_type" ||
      name === "pan" ||
      name === "gst_no" ||
      name === "gst_state" ||
      name === "food_licence" ||
      name === "drug_licence1" ||
      name === "drug_licence2" ||
      name === "drug_licence3" ||
      name === "expiry_date" ||
      name === "vendor_status" ||
      name === "credit_days" ||
      name === "holdpaymentreason" 

    ) {
      this.validateMaxLength(name, value, max);
    } else {
      data[name] = value;
      errors[name] = ""
    }
    this.setState({ vendorpharmacyData: data, errors,searchErrorMessage: "" });
  };

  handlePhoneInput = (event) => {
    let vendorpharmacyData = this.state.vendorpharmacyData;
    vendorpharmacyData.phone_number = event;
    this.setState({ vendorpharmacyData });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState(prevState => ({
      vendorpharmacyData: {
        ...prevState.vendorpharmacyData,
        [name]: value
      }
    }));
  };
  handleselect= (selectedOptions, e) => {
    let data = this.state.vendorpharmacyData;
    data[e.name] = selectedOptions;
    this.setState({ vendorpharmacyData: data });

    
  };
  handleAddressInput = (e) => {
    let data = this.state.vendorpharmacyData;
    let name = e.target.name;
    let value = e.target.value;
    let max = e.target.max;
    let errors = this.state.errors
    if (name === "address") {
     
      this.validateMaxLength(name, value, max);
    } else {
      data[name] = value;
      errors[name] = "";
    }
    this.setState({ vendorpharmacyData: data, errors,searchErrorMessage: "" });
  };

  

  /* for country detail change */
  selectCountry(val) {
    let data = this.state.vendorpharmacyData;
    data.country = val;
    this.setState({ vendorpharmacyData: data });
  }

  selectgstCountry(val) {
    let data = this.state.vendorpharmacyData;
    data.gst_country = val;
    this.setState({ vendorpharmacyData: data });
  }

  /* for state detail change */
  selectState(val) {
    let data = this.state.vendorpharmacyData;
    data.state = val;
    this.setState({ vendorpharmacyData: data });
  }
  selectgstState(val) {
    let data = this.state.vendorpharmacyData;
    data.gst_state = val;
    this.setState({ vendorpharmacyData: data });
  }

 addorupdateVendor() {
  
  let { vendorpharmacyData, errors } = this.state;
  vendorpharmacyData.type = "vendorpharmacy";
  errors = Validation(vendorpharmacyData, errors);
  this.setState({ errors: errors });
   let food_licence = {
    food_licence:vendorpharmacyData.food_licence,
    food_expirydate:vendorpharmacyData.food_expirydate
  };

  let drug_licence1 = {
    drug_licence1:vendorpharmacyData.drug_licence1,
    licence1_expirydate:vendorpharmacyData.licence1_expirydate
  };
  let drug_licence2 = {
    drug_licence2:vendorpharmacyData.drug_licence2,
    licence2_expirydate:vendorpharmacyData.licence2_expirydate
  };
  let drug_licence3 = {
    drug_licence3:vendorpharmacyData.drug_licence3,
    licence3_expirydate:vendorpharmacyData.licence3_expirydate
  }; 
  
  if (errors.formIsValid) {
   // this.setState({ loading: true });
    
    let data = {};
    
   
    data.user_id = this.props.userdata.id;
    data.vendor_name = vendorpharmacyData.vendor_name;
    data.address1 = vendorpharmacyData.address1;
    data.address2 = vendorpharmacyData.address2;
    data.country = vendorpharmacyData.country;
    data.state = vendorpharmacyData.state;
    data.city = vendorpharmacyData.city;
    data.zip_code = vendorpharmacyData.zip_code;
    data.phone_number = vendorpharmacyData.phone_number;
    data.email = vendorpharmacyData.email;
    data.contact_name = vendorpharmacyData.contact_name;
    data.gstreg_type = vendorpharmacyData.gstreg_type.label;
    data.gst_no = vendorpharmacyData.gst_no;
    data.gst_country = vendorpharmacyData.gst_country;
    data.gst_state = vendorpharmacyData.gst_state;
    data.pan = vendorpharmacyData.pan;
    data.food_licence =food_licence;
    data.drug_licence1 = drug_licence1;
    data.drug_licence2 = drug_licence2;
    data.drug_licence3 =drug_licence3;
    data.vendor_status = vendorpharmacyData.vendor_status;
    data.credit_days = vendorpharmacyData.credit_days;
    data.hold_payment = vendorpharmacyData.hold_payment;
    data.holdpaymentreason=vendorpharmacyData.holdpaymentreason;
   console.log(data);
   let records = this.state.records;
      
    if (this.state.vendor_action === "Add") {
       
        axios
      
        .post(addPharmacyVendor, data, CONFIG)
        .then((addVendorResponse) => {
          let res = addVendorResponse.data;
          console.log(res)
          if (res.status === "1") {
          
            toast.success("The vendor details have been added successfully.", {
                className: "toast-success"
              });
            this.clearMessage();
            this.clearAll();
          } else {
           
            toast.error("vendor could not be Added. ", {
              className: "toast-error"
            });
            this.clearMessage();
          }
        })
        .catch(() => { });
    } 
    else {
      axios
        .put(updatePharmacyVendor, data, CONFIG)
        .then((updateVendorResponse) => {
          let updateResponse = updateVendorResponse.data;
          if (updateResponse.status === "1") {
            let addVendorSuccess = this.state.addVendorSuccess;
            addVendorSuccess = updateResponse.message;
            this.setState({ addVendorSuccess: addVendorSuccess });

            /** Updating the data table */
            let existingRecords = this.state.records;
            var index = existingRecords.findIndex(
              (item) => item.id === data.id
            );
            existingRecords.splice(index, 1, data);
            this.setState({ records: existingRecords });
             this.setState({ tableSuccess: updateResponse });
            this.setState({ editformSuccess: updateResponse });
            toast.success("The Vendor details have been updated successfully.", {
              className: "toast-success"
            });
            this.clearMessage();
            this.clearAll();
          } else {
            this.setState({ tableSuccess: updateResponse });
            this.setState({ editformSuccess: updateResponse });
            toast.error("Vendor could not be updated. ", {
              className: "toast-error"
            });
            this.clearMessage();
          }
          this.setState({ loading: false });
        })
        .catch(() => { });
    }
  }
};



 clearErrors = (errors) => {

  errors.vendor_id = ""
  errors.vendor_name = ""
  errors.address1 = ""
  errors.address2 = ""
  errors.city = ""
  errors.country = ""
  errors.state = ""
  errors.zip_code = ""
  errors.phone_number = ""
  errors.email = ""
  errors.contact_name = ""
  errors.gstreg_type = ""
  errors.gst_type = ""
  errors.pan = ""
  errors.gst_no = ""
  errors.gst_state = ""
  errors.food_licence = ""
  errors.expiry1_date = ""
  errors.drug_licence1 = ""
  errors.expiry2_date = ""
  errors.drug_licence2 = ""
  errors.expiry3_date = ""
  errors.drug_licence3 = ""
  errors.expiry4_date = ""
  errors.vendor_status = ""
  errors.credit_days = ""
  errors.hold_payment = ""
  return errors
}

cancel = () => {
  let vendor_action = this.state.vendor_action;
  vendor_action = "Add";
  this.setState({ vendor_action });
  let data = this.state.vendorpharmacyData;
  data.vendor_id = ""
  data.vendor_name = ""
  data.id = ""
  data.user_id = ""
 
  data.address1 = ""
  data.address2 = ""
  data.city = ""
  data.country = ""
  data.state = ""
  data.zip_code = ""
  data.phone_number = ""
  data.email = ""
  data.contact_name = ""
  data.gstreg_type = ""
  data.gst_type = ""
  data.pan = ""
  data.gst_no = ""
  data.gst_state = ""
  data.food_licence = ""
  data.expiry1_date = ""
  data.drug_licence1 = ""
  data.expiry2_date = ""
  data.drug_licence2 = ""
  data.expiry3_date = ""
  data.drug_licence3 = ""
  data.expiry4_date = ""
  data.vendor_status = ""
  data.credit_days = ""
  data.hold_payment = ""
  
  // data.phone_number = ""
  // data.email = ""
  // data.pharmacy_location = ""
  // data.city = ""
  // data.country = ""
  // data.state = ""
  // data.zip_code = ""
  // data.online_prescription = ""

  let errors = this.state.errors;

  errors = this.clearErrors(errors);

  this.setState({ vendorpharmacyData: data, actions: "add", errors: errors, searchErrorMessage: "", searchrecords: [], search_action: false });
}

clearimport=()=>{
  this.setState({ file:"" });
};



  

  onDeleteClick(record) {
    this.setState({ addPopup: true, record: record });

  }

  cancelDelete() {
    this.setState({
      showDeleteConfirmPopup: !this.state.showDeleteConfirmPopup,
    });
  }



  deleteRecord(record) {
    let userId = record.id;
    this.setState({ loading: true });
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
      .delete(deleteDrug, { data: { id: userId } }, CONFIG)
      .then((res) => {
        let response = res.data;
        if (response.status === "1") {
          let records = this.state.records;
          const deleteIndex = records.map((item) => item.id).indexOf(record.id);
          records.splice(deleteIndex, 1);
          this.setState({ records });
        }
        this.setState({ addPopup: false, record: "", loading: false });
        this.setState({ tableSuccess: res.data });
        toast.error("The Vendor detail has been deleted successfully.", {
          className: "toast-error"
        });
        this.clearMessage();
        this.clearAll();
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${this.props.access_token}`;
        axios
          .post(getAllDrugs, { user_id: this.props.userdata.id }, CONFIG)
          .then((list) => {
            let VendorList = list.data.data;
            let vendorpharmacyDataArr = [];
            VendorList.forEach((element, index) => {
              let vendorpharmacyData = {};
              vendorpharmacyData.s_no = index + 1;
              vendorpharmacyData.id = element.id;
              vendorpharmacyData.user_id = this.props.userdata.id;
              vendorpharmacyData.vendor_id = element.vendor_id;
              vendorpharmacyData.vendor_name = element.vendor_name;
              vendorpharmacyData.address1 = element.address1;
              vendorpharmacyData.address2 = element.address2;
              vendorpharmacyData.city = element.city;
              vendorpharmacyData.country = element.country;
              vendorpharmacyData.state = element.state;
              vendorpharmacyData.zip_code = element.zip_code;
              vendorpharmacyData.phone_number = element.phone_number;
              vendorpharmacyData.email = element.email;
              vendorpharmacyData.contact_name = element.contact_name;
              vendorpharmacyData.gstreg_type = element.gstreg_type;
              vendorpharmacyData.gst_type = element.gst_type;
              vendorpharmacyData.pan = element.pan;
              vendorpharmacyData.gst_no = element.gst_no;
              vendorpharmacyData.gst_state = element.gst_state;
              vendorpharmacyData.food_licence = element.food_licence;
              vendorpharmacyData.gst_state = element.gst_state;
              vendorpharmacyData.drug_licence1 = element.drug_licence1;
              vendorpharmacyData.drug_licence2 = element.drug_licence2;
              vendorpharmacyData.drug_licence3 = element.drug_licence3;
              vendorpharmacyData.expiry_date = element.expiry_date;
              vendorpharmacyData.vendor_status = element.vendor_status;
              vendorpharmacyData.credit_days = element.credit_days;
              vendorpharmacyData.hold_payment = element.hold_payment;
              
              
             
              vendorpharmacyDataArr[index] = vendorpharmacyData;
            });
    
            this.setState({ records: vendorpharmacyDataArr });
          })
          .catch(() => { });
    
      })
      .catch(() => { });
  
  }


  clearMessage = () => {
    setTimeout(
      () =>
        this.setState({
          formSuccess: { message: "" },
          tableSuccess: { message: "" },
          searchTableSuccess: { message: "" },
          editformSuccess: { message: "" },
        }),
      5000
    );
  };
  clearAll=()=> {
    let vendor_action = this.state.vendor_action;
    vendor_action = "Add";
    this.setState({ vendor_action });
    let vendorpharmacyData = {};
    vendorpharmacyData.vendor_id = "";
    vendorpharmacyData.vendor_name = "";
    vendorpharmacyData.address1 = "";
    vendorpharmacyData.address2 = "";
    vendorpharmacyData.city = "";
    vendorpharmacyData.country = "";
    vendorpharmacyData.state = "";
    vendorpharmacyData.zip_code = "";
    vendorpharmacyData.phone_number = "";
    vendorpharmacyData.email = "";
    vendorpharmacyData.contact_name = "";
    vendorpharmacyData.gstreg_type = "";
    vendorpharmacyData.gst_type = "";
    vendorpharmacyData.pan = "";
    vendorpharmacyData.gst_no = "";
    vendorpharmacyData.gst_state = "";
    vendorpharmacyData.food_licence = "";
    vendorpharmacyData.drug_licence1 = "";
    vendorpharmacyData.drug_licence2 = "";
    vendorpharmacyData.drug_licence3 = "";
    vendorpharmacyData.expiry_date = "";
    vendorpharmacyData.vendor_status = "";
    vendorpharmacyData.credit_days = "";
    vendorpharmacyData.hold_payment = "";
   
    let errors = {
      id:"",
        vendor_id:"",
        vendor_name: "",
        address1: "",
        address2: "",
        city: "",
        country: "",
        state: "",
        zip_code: "",
        phone_number: "",
        email:"",
        contact_name:"",
        gstreg_type:"",
        gst_type:"",
        pan:"",
        gst_no:"",
        gst_state:"",
        food_licence:"",
        drug_licence1:"",
        drug_licence2:"",
        drug_licence3:"",
        expiry_date: "",
        vendor_status:"",
        credit_days:"",
        hold_payment:"",
    }
    let tableSuccess = {
      message: "",
      status: "",
    };
    let formSuccess = {
      message: "",
      status: "",
    };
    let editformSuccess = {
      message: "",
      status: "",
    };
    let searchTableSuccess = {
      message: "",
      status: "",
    }
    

    this.setState({ vendorpharmacyData, errors, tableSuccess, formSuccess, editformSuccess, searchTableSuccess, searchErrorMessage: "",searchrecords: [],search_action:false });
    

  }
  clearimport=()=>{
    this.setState({ file:"" });
  };
  searchandshowDrug() {
    let drugData = this.state.drugData;
    console.log(drugData);
    if (
      (drugData.drug_name === "" || null === drugData.drug_name) &&
      (drugData.drug_category === "" || null === drugData.drug_category) &&
      (drugData.drug_type === "" || null === drugData.drug_type) &&
      (drugData.unit_of_measure === "" || null === drugData.unit_of_measure) &&
      drugData.manufacturer === "" &&
      drugData.active_ingredients === "" &&
      drugData.generic_name===""
    ) {
      this.setState({
        searchErrorMessage:
          "Choose any one of the search filters other than Drug ID.",
      });
      return;
    } else {
      this.setState({
        searchErrorMessage: "",
      });
    }
    let data = {};
    data.drug_name = drugData.drug_name;
    data.drug_category = drugData.drug_category
      ? drugData.drug_category.label
      : "";
    data.drug_type = drugData.drug_type ? drugData.drug_type.label : "";
    data.manufacturer = drugData.manufacturer;
    data.active_ingredients = drugData.active_ingredients;
    data.generic_name=drugData.generic_name;
    data.unit_of_measure=drugData.unit_of_measure.label;
    data.type = "admin";
    data.user_id = this.props.userdata.id;
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    this.setState({ loading: true }, () => {
      axios.post(searchDrug, data, CONFIG).then((searchres) => {
        this.setState({ loading: false });
        let result = searchres.data;
        let data = [];
        if (result.countOfRecords > 0) {
          let records = result.data;
          //  console.log(this.state.masterdrugmeasure[0]);
          records.forEach((element) => {
            console.log(element);
            console.log(element.drug_name);
            console.log(element.drug_category);
            console.log(element.unit_of_measure);
            data.push({
              drug_id: "",
              drug_name: element.drug_name,
              drug_name_hide: element.drug_name,
              drug_category: element.drug_category,
              unit_of_measure: element.unit_of_measure,
              drug_category_hide: element.drug_category,
              drug_type: element.drug_type,
              drug_type_hide: element.drug_type,
              generic_name:element.generic_name,
              manufacturer: element.manufacturer,
              active_ingredients: element.active_ingredients,
              id: element.id,
              drugid_error: "",
              drugcategory_error: "",
              drugtype_error: "",
              drugname_error: "",
              generic_name_error:"",
              manufacturer_error:"",
              active_incredients_error:"",
            });
          });

          this.setState({ searchrecords: data });
          this.setState({
            search_action: true,
          });
        } else {
          this.setState({ searchErrorMessage:"No Drug Found",searchrecords: [], search_action: true });
        }
      });
    });
  }
  onFileChange = event => {

    // Update the state
    this.setState({ file: event.target.files[0] });
    console.log(event.target.files[0]);
    readXlsxFile(event.target.files[0]).then((rows) => {
        console.log(rows);
        console.log("rows");
        this.setState({ vendorpharmacyData: rows });
        // `rows` is an array of rows
        // each row being an array of cells.
    })

};

//       axios.post(hospitaldrugimport, {data: this.state.drugData,user_id:this.props.userdata.id}).then((result) => {
//           console.log(result);
//           this.setState({ formSuccess: result.data, file: "", drugData: [] })
//           axios
//           .post(getAllDrugs, { user_id: this.props.userdata.id }, CONFIG)
//           .then((list) => {
//             let drugList = list.data.data;
//             let drugDataArr = [];
//             drugList.forEach((element, index) => {
//               let drugData = {};
//               drugData.s_no = index + 1;
//               drugData.id = element.id;
//               drugData.user_id = this.props.userdata.id;
//               drugData.drug_id = element.drug_id;
//               drugData.drug_name = element.drug_name;
//               drugData.drug_type = element.drug_type;
//               drugData.drug_category = element.drug_category;
//               drugData.generic_name=element.generic_name;
//               drugData.manufacturer = element.manufacturer;
//               drugData.active_ingredients = element.active_ingredients;
//               drugData.unit_of_measure = element.unit_of_measure;
//               drugDataArr[index] = drugData;
//             });
    
//             this.setState({ records: drugDataArr });
//           })
//           .catch(() => { });
//           this.clearMessage();
//       });
//   }

// };
  render() {

    let ExpirePopupClose = () => {
      window.location.reload();
      this.setState({ showexpirePopup: true })
    };
    let ExpirePopupConfirm = () => {
      window.location.href = "/showsubscription";

    };
    let PopupClose = () => this.setState({ addPopup: false });
    let PopupConfirm = () => this.deleteRecord(this.state.record);
    let PopupHospitalClose = () => {
      this.setState({ hospitalpopup:true})
     
    };
    const {
     
      vendorpharmacyData,
      errors,
      loading,
      searchTableSuccess,
      editformSuccess,
      tableSuccess,
      searchErrorMessage,
      hospitaltimeout,
     
    } = this.state;
    const modalVar = this.state.showDeleteConfirmPopup;
   
    
    const gstreg_type = [
      { value: 'registered', label: 'Registered' },
      { value: 'non-registered', label: 'Non-Registered' }
    ];
    
    
  
    return (
      <div id="layout-wrapper">
        {/* <SideBarNew /> */}
       {/*  <ExpirePopup
          show={!this.state.showexpirePopup}
          onHide={ExpirePopupClose}
          onConfirm={ExpirePopupConfirm}
          title={"Alert"}
          message={"Your current plan has been expired. Please activate new plan and try again"}
        /> */}
        <IdleTimeOutModal
          showModal={this.state.showModal}
          handleClose={this.handleClose}
          handleLogout={this.handleLogout}
        />

        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={hospitaltimeout} />

 {loading && <Dnaspinner />} 
{/* {loading && <LoadingHeartrate />} */}

        <div className="main-content bacfot">
          <div className="mainpage">
            <Header user_id={this.props.userdata.id} />
            <div className="main-box">
              <div className="sidemenu pr0">
                <BusinessUserSideBar />
              </div>

              {
                                   (this.state.adminstatus !== "" && this.state.adminstatus === "Pending") ? (
                                        <div className="container-fluid">
                                                    <div className="checkout-tabs">
                                                        
                                    <div className="row">
                                        <div className="col-sm-12 pr0">
                                            <div className="card">
                                                <div className="card-body a-page-1-hight-right ">
                                                    {/* Admin not approved your account yet to view this page. Please contact the administrator to approve your account. */}
                                                    <Popupinfo
                                                     show={!this.state.hospitalpopup}
                                                     onHide={PopupHospitalClose}
                                                     title={"Alert!!"}
                                                      message={" Your Account is now waiting for our approval. Kindly contact the administrator to approve your request."}
                                                      />
                                               </div>

                                            </div>
                                        </div>
                                        </div>
                                        </div>

                                    </div>) :
           (

              <section className="main-body hospital-profilegap">
                <div className="container-fluid">
                  <div className="checkout-tabs">

                    <div className="col-sm-12">
                      <h4 className="font-weight-bold"> Vendor List </h4>
                      <div className="row mt-4">

                        <div className="col-sm-12">
                          <div className="card ">
                            <div className="card-body ">
                              <div
                                className="tab-content"
                                id="v-pills-tabContent"
                              >
                                    {/* <div className="float-right btn "> <DrugsExport business_id={this.props.userdata.id} records={this.state.records}/></div> */}
                              
                                <div
                                  className="tab-pane fade show active"
                                  id="v-pills-shipping"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-shipping-tab"
                                >
                                  <div className="user-notification">
                                    <SpanField
                                      classname={
                                        "0" === tableSuccess.status
                                          ? "text-danger user-notification"
                                          : "text-success user-notification"
                                      }
                                      title={tableSuccess.message}
                                    />
                                  </div>
                                  <ReactDatatable
                                    className="table table-bordered table-striped kas  mobile_table"
                                    config={this.config}
                                    records={this.state.records}
                                    columns={this.columns}
                                  />
                                  <Popup
                                    show={this.state.addPopup}
                                    onHide={PopupClose}
                                    onConfirm={PopupConfirm}
                                    title={"Confirmation"}
                                    message={
                                      "Are you sure you want to delete this record ?"
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                       
                        <div className="col-sm-12 pr0">
                          <div className="card m565">
                          <div className="card-header"> <div className="card-title"> 
                            {this.state.vendor_action === "Add"
                                  ? "Add"
                                  : "Edit"}{" "}
                                  Vendor </div> </div>
                            <div className="card-body">
                              
                              <div className="user-notification-box text-center">
                                <SpanField
                                  classname={
                                    "0" === editformSuccess.status
                                      ? "text-danger user-notification"
                                      : "text-success user-notification"
                                  }
                                  title={editformSuccess.message}
                                />
                                <ToastContainer position="bottom-center" hideProgressBar={true} autoClose={2000} closeOnClick={false} pauseOnHover />

                              </div>
                              <div className="user-notification">
                                {searchErrorMessage ? (
                                  <div
                                    className="alert alert-danger"
                                    role="alert"
                                  >
                                    {searchErrorMessage}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              {/* <div>

                                <hr className="rowline" />
                              </div> */}


                              
<div className="row">
  <div className="col-sm-6">
    <div className="frllftfull">
      <div className="form-group frllft mbbre">
        <Label title="Vendor ID" isMandatory={true} />
      </div>
      <div className="form-group frlrit">
        <div className="position-relative">
          <div className="input-group">
          <InputField
              inputType={"text"}
              name={"vendor_id"}
              controlFunc={this.handleInput}
              content={vendorpharmacyData.vendor_id}
              placeholder={""}
              max={5}
            />
          </div>
          <SpanField
            classname="text-danger"
            title={errors.vendor_id}
          />
        </div>
      </div>
    </div>
      
    <div className="frllftfull">
      <div className="form-group frllft mbbre">
        <Label title="Vendor Name" isMandatory={true} />
      </div>
      <div className="form-group frlrit">
        <div className="position-relative">
          <div className="input-group">
          <InputField
              inputType={"text"}
              name={"vendor_name"}
              controlFunc={this.handleInput}
              content={vendorpharmacyData.vendor_name}
              placeholder={""}
              max={50}
            />
            
          </div>
          <SpanField
            classname="text-danger"
            title={errors.vendor_name}
          />
        </div>
      </div>
    </div>
      
    <div className="frllftfull">
      <div className="form-group frllft mbbre">
        <Label title="Address1" isMandatory={true} />
      </div>
      <div className="form-group frlrit">
        <div className="position-relative">
          <div className="input-group">
          <InputField
            id="address1"
              inputType={"text"}
              name={"address1"}
              controlFunc={this.handleInput}
              content={vendorpharmacyData.address1}
              placeholder={""}
              max={50}
            />
             
          </div>
          <SpanField
            classname="text-danger"
            title={errors.address1}
          />
        </div>
      </div>
    </div>

    <div className="frllftfull">
      <div className="form-group frllft mbbre">
        <Label title="Address 2" />
      </div>
      <div className="form-group frlrit">
        <div className="position-relative">
          <div className="input-group">
          <InputField
            id="address2"
              inputType={"text"}
              name={"address2"}
              controlFunc={this.handleInput}
              content={vendorpharmacyData.address2}
              placeholder={""}
              max={50}
            />
             
          </div>
          <SpanField
            classname="text-danger"
            title={errors.address2}
          />
        </div>
      </div>
    </div>

    <div className="frllftfull">
      <div className="form-group frllft mbbre">
        <Label title="Country" isMandatory={true} />
      </div>
      <div className="form-group frlrit">
        <div className="position-relative">
          <div className="input-group">
          <CountryDropdown
           placeholder="Country"
           // name="country"
           value={vendorpharmacyData.country || ""}
           className="form-control"
           onChange={(val) => this.selectCountry(val)}
         />
          </div>
          <SpanField
           classname="text-danger"
           title={errors.country}
         />
                                         
        </div>
      </div>
    </div>

    <div className="frllftfull">
      <div className="form-group frllft mbbre">
        <Label title="State" isMandatory={true} />
      </div>
      <div className="form-group frlrit">
        <div className="position-relative">
          <div className="input-group">
          <RegionDropdown
          // name="state"
          defaultOptionLabel="Select State"
          country={ vendorpharmacyData.country}
          value={vendorpharmacyData.state || ""}
          className="form-control"
          onChange={(val) => this.selectState(val)}
          placeholder="State"
        />
      </div>
      <SpanField
        classname="text-danger"
        title={errors.state}
      />
                              
        </div>
      </div>
    </div>

    <div className="frllftfull">
      <div className="form-group frllft mbbre">
        <Label title="City" isMandatory={true} />
      </div>
      <div className="form-group frlrit">
        <div className="position-relative">
          <div className="input-group">
          <InputField
              inputType={"text"}
              name={"city"}
              controlFunc={this.handleInput}
              // content={vendorpharmacyData.city}
              content={vendorpharmacyData.city}
              placeholder={""}
              isvisible={this.state.isDisable ? "true" :  undefined}
              max={50}
            />
             
          </div>
          <SpanField
            classname="text-danger"
            title={errors.city}
          />
        </div>
      </div>
    </div>

   

   

    <div className="frllftfull">
      <div className="form-group frllft mbbre">
        <Label title="Pincode" isMandatory={true} />
      </div>
      <div className="form-group frlrit">
        <div className="position-relative">
          <div className="input-group">
          <InputField
              inputType={"text"}
              name={"zip_code"}
              controlFunc={this.handleInput}
              content={vendorpharmacyData.zip_code}
              placeholder={""}
              max={50}
            />
             
          </div>
          <SpanField
            classname="text-danger"
            title={errors.zip_code}
          />
        </div>
      </div>
    </div>

    <div className="frllftfull">
      <div className="form-group frllft mbbre">
        <Label title="Phone" isMandatory={true} />
      </div>
      <div className="form-group frlrit">
        <div className="position-relative">
          <div className="input-group">
          <PhoneInput
                  id="phone_number"
                    international
                    defaultCountry="IN"
                    placeholder="Enter phone number"
                    value={vendorpharmacyData.phone_number}
                    onChange={this.handlePhoneInput}
                    max={13} error={
                      
                        vendorpharmacyData.phone_number
                      ? isValidPhoneNumber(vendorpharmacyData.phone_number)
                        
                      ? undefined
                      : "Invalid phone number"
                    : "Phone number required"
                     
                    }
                  />
          </div>
          <SpanField
            classname="text-danger"
            title={errors.phone_number}
          />
        </div>
      </div>
    </div>

    <div className="frllftfull">
      <div className="form-group frllft mbbre">
        <Label title="Email" isMandatory={true} />
      </div>
      <div className="form-group frlrit">
        <div className="position-relative">
          <div className="input-group">
          <InputField
              inputType={"text"}
              name={"email"}
              controlFunc={this.handleInput}
              content={vendorpharmacyData.email}
              placeholder={""}
              max={50}
            />
             
          </div>
          <SpanField
            classname="text-danger"
            title={errors.email}
          />
        </div>
      </div>
    </div>

    <div className="frllftfull">
      <div className="form-group frllft mbbre">
        {/* <Label title="Contact Person Name" isMandatory={true} /> */}
        <Label title="Contact Person Name"  />
      </div>
      <div className="form-group frlrit">
        <div className="position-relative">
          <div className="input-group">
          <InputField
              inputType={"text"}
              name={"contact_name"}
              controlFunc={this.handleInput}
              content={vendorpharmacyData.contact_name}
              placeholder={""}
              max={50}
            />
             
          </div>
          <SpanField
            classname="text-danger"
            title={errors.contact_name}
          />
        </div>
      </div>
    </div>

    </div>

    <div className="col-sm-6">
   
    <div className="frllftfull">
      <div className="form-group frllft mbbre">
        <Label title="GST Reg Type" isMandatory={true} />
      </div>
      <div className="form-group frlrit">
        <div className="position-relative">
          <div className="input-group">
        
        <Select
                maxMenuHeight={120}
                className="selectcls p-0"
                name="gstreg_type"
                isMandatory={true}
                value={vendorpharmacyData.gstreg_type}
                onChange={this.handleselectChange}
                options={gstreg_type}
                placeholder="Select GST Type" 
              />
                                           
          </div>
          <SpanField
            classname="text-danger"
            title={errors.gstreg_type}
          />
        </div>
      </div>
    </div>

    
      
   
    <div className="frllftfull">
      <div className="form-group frllft mbbre">
        {/* <Label title="GST Registeration No" isMandatory={true} /> */}
        <Label title="GST Registration No"/>
      </div>
      <div className="form-group frlrit">
        <div className="position-relative">
          <div className="input-group">
         
             <input
            type="text"
            name="gst_no"
            value={vendorpharmacyData.gst_no}
            onChange={this.handleInput}
            readOnly={vendorpharmacyData.gst_type === 'non-registered'} 
            className="form-control"
            max={50}
          />
          </div>
          <SpanField
            classname="text-danger"
            title={errors.gst_no}
          />
        </div>
      </div>
    </div>


<div className="frllftfull">
    <div className="form-group frllft mbbre">
      <Label title="GST Country" />
    </div>
    <div className="form-group frlrclfgf">
      <div className="position-relative">
        <div className="input-group">
        <CountryDropdown
           placeholder="Country"
           // name="country"
           value={ vendorpharmacyData.gst_country || ""}
           className="form-control"
           onChange={(val) => this.selectgstCountry(val)}
         />
          
        </div>
        <SpanField
          classname="text-danger"
          title={errors.gst_country}
        />
      </div>
    </div>
    <div className="form-group frlrclfgf-1">
      <Label title="GST State" />
    </div>
    <div className="form-group frlrclfgf">
      <div className="position-relative">
        <div className="input-group">
        <RegionDropdown
          // name="state"
          defaultOptionLabel="Select State"
          country={vendorpharmacyData.gst_country}
          value={vendorpharmacyData.gst_state || ""}
          className="form-control"
          onChange={(val) => this.selectgstState(val)}
          placeholder="State"
        />
                                    
        </div>
        <SpanField
          classname="text-danger"
          title={errors.gst_state}
        />
      </div>
    </div>
  </div>

    <div className="frllftfull">
      <div className="form-group frllft mbbre">
        <Label title="PAN" isMandatory={true} />
      </div>
      <div className="form-group frlrit">
        <div className="position-relative">
          <div className="input-group">
          <InputField
              inputType={"text"}
              name={"pan"}
              controlFunc={this.handleInput}
              content={vendorpharmacyData.pan}
              placeholder={""}
              max={50}
              
              
            />
            
          </div>
          <SpanField
            classname="text-danger"
            title={errors.pan}
          />
        </div>
      </div>
    </div>


    <div className="frllftfull">
    <div className="form-group frllft mbbre">
      <Label title="Food Licence" />
    </div>
    <div className="form-group frlrclfgf">
      <div className="position-relative">
        <div className="input-group">
        <InputField
            inputType={"text"}
            name={"food_licence"}
            controlFunc={this.handleInput}
            content={vendorpharmacyData.food_licence}
            placeholder={""}
            max={50}
          />
          
        </div>
        <SpanField
          classname="text-danger"
          title={errors.food_licence}
        />
      </div>
    </div>
    <div className="form-group frlrclfgf-1">
      <Label title="Valid Till" />
    </div>
    <div className="form-group frlrclfgf">
      <div className="position-relative">
        <div className="input-group">
      
         <DatePicker
          className="form-control"
          value={vendorpharmacyData.food_expirydate}
          format="dd-MM-yyyy"
          onChange={(date) => this.handleDatePickerChange(date, 'food_expirydate')} 
          yearPlaceholder='YYYY'
          monthPlaceholder='MM'
          dayPlaceholder='DD'
          disabled={!vendorpharmacyData.food_licence}
        />
                                                
        </div>
        <SpanField
          classname="text-danger"
          title={errors.food_expirydate}
        />
      </div>
    </div>
  </div>

    <div className="frllftfull">
    <div className="form-group frllft mbbre">
      <Label title="Drug Licence 1" />
    </div>
    <div className="form-group frlrclfgf">
      <div className="position-relative">
        <div className="input-group">
        <InputField
            inputType={"text"}
            name={"drug_licence1"}
            controlFunc={this.handleInput}
            content={vendorpharmacyData.drug_licence1}
            placeholder={""}
            max={50}
          />
          
        </div>
        <SpanField
          classname="text-danger"
          title={errors.drug_licence1}
        />
      </div>
    </div>
    <div className="form-group frlrclfgf-1">
      <Label title="Valid Till" />
    </div>
    <div className="form-group frlrclfgf">
      <div className="position-relative">
        <div className="input-group">
      
           <DatePicker
            className="form-control"
            value={vendorpharmacyData.licence1_expirydate}
            format="dd-MM-yyyy"
            onChange={(date) => this.handleDatePickerChange(date, 'licence1_expirydate')} 
            yearPlaceholder='YYYY'
            monthPlaceholder='MM'
            dayPlaceholder='DD'
            disabled={!vendorpharmacyData.drug_licence1}
          />                                     
        </div>
        <SpanField
          classname="text-danger"
          title={errors.licence1_expirydate}
        />
      </div>
    </div>
  </div>

  <div className="frllftfull">
    <div className="form-group frllft mbbre">
      <Label title="Drug Licence 2" />
    </div>
    <div className="form-group frlrclfgf">
      <div className="position-relative">
        <div className="input-group">
        <InputField
            inputType={"text"}
            name={"drug_licence2"}
            controlFunc={this.handleInput}
            content={vendorpharmacyData.drug_licence2}
            placeholder={""}
            max={50}
          />
          
        </div>
        <SpanField
          classname="text-danger"
          title={errors.drug_licence2}
        />
      </div>
    </div>
    <div className="form-group frlrclfgf-1">
      <Label title="Valid Till" />
    </div>
    <div className="form-group frlrclfgf">
      <div className="position-relative">
        <div className="input-group">
      
         <DatePicker
          className="form-control"
          value={vendorpharmacyData.licence2_expirydate}
          format="dd-MM-yyyy"
          onChange={(date) => this.handleDatePickerChange(date, 'licence2_expirydate')} 
          yearPlaceholder='YYYY'
          monthPlaceholder='MM'
          dayPlaceholder='DD'
          disabled={!vendorpharmacyData.drug_licence2}
        />
                                                
        </div>
        <SpanField
          classname="text-danger"
          title={errors.licence2_expirydate}
        />
      </div>
    </div>
  </div>

  <div className="frllftfull">
    <div className="form-group frllft mbbre">
      <Label title="Drug Licence 3"  />
    </div>
    <div className="form-group frlrclfgf">
      <div className="position-relative">
        <div className="input-group">
        <InputField
            inputType={"text"}
            name={"drug_licence3"}
            controlFunc={this.handleInput}
            content={vendorpharmacyData.drug_licence3}
            placeholder={""}
            max={50}
          />
        </div>
        <SpanField
          classname="text-danger"
          title={errors.drug_licence3}
        />
      </div>
    </div>
    <div className="form-group frlrclfgf-1">
      <Label title="Valid Till"  />
    </div>
    <div className="form-group frlrclfgf">
      <div className="position-relative">
        <div className="input-group">
      
        <DatePicker
          className="form-control"
          value={vendorpharmacyData.licence3_expirydate}
          format="dd-MM-yyyy"
          onChange={(date) => this.handleDatePickerChange(date, 'licence3_expirydate')} 
          yearPlaceholder='YYYY'
          monthPlaceholder='MM'
          dayPlaceholder='DD'
          disabled={!vendorpharmacyData.drug_licence3}
        />

        </div>
        <SpanField
          classname="text-danger"
          title={errors.licence3_expirydate}
        />
      </div>
    </div>
  </div>

  <div className="frllftfull">
      <div className="form-group frllft mbbre">
        <Label title="Vendor Status"  />
      </div>
      <div className="form-group frlrit">
        <div className="position-relative">
          <div className="input-group">
         
     {/*  <CheckboxOrRadioGroup
   setName={"vendor_status"}
  type={"radio"}
  controlFunc={this.handleVendorStatusChange} 
  options={vendor_status} 
  selectedOptions={this.state.vendorpharmacyData.vendor_status} 
   checked={this.state.vendorpharmacyData.vendor_status === "Active" ? true : false}
/>
         */}
                                              
        </div>
  <SpanField
    classname="text-danger"
    title={errors.vendor_status}
  />
                                              
                                     
        
        </div>
      </div>
    </div>

    <div className="frllftfull">
    <div className="form-group frllft mbbre">
      <Label title="Credit Days"  />
    </div>
    <div className="form-group frlrclfgf">
      <div className="position-relative">
        <div className="input-group">
        <InputField
        inputType={"text"}
        name={"credit_days"}  
        controlFunc={this.handleInput}
        content={vendorpharmacyData.credit_days}  
        placeholder={""}
        max={30}
    />
    </div>
    <SpanField
        classname="text-danger"
        title={errors.credit_days} 
    />
                                      
      
      </div>
      
    </div>
 
  </div>

  <div className="frllftfull">
      <div className="form-group frllft mbbre">
        <Label title="Hold Payment"  />
      </div>
      <div className="form-group frlrit">
        <div className="position-relative">
          <div className="input-group">
                <input
            type="checkbox"
            value={vendorpharmacyData.hold_payment}
            checked={this.state.creditDaysChecked ? true : false}
            onChange={this.handleWorkingDays}
          />
         
        </div>
   
      </div>
      {this.state.creditDaysChecked && (
    <textarea
      className="form-control"
      rows="2"
      id="comment"
      maxLength={100}
      name="holdpaymentreason"
      value={vendorpharmacyData.holdpaymentreason}
      onChange={this.handleInput}
    ></textarea>
      )}
                                                  
      </div>
    </div>

    </div>
                                       
                              
                              </div>

                            


                              <div className="row">
                               
                                <div className="col-sm-12 text-right">
                               

                                <button
                                  className="signupbtn abtn mr-2 phone-button"
                                  onClick={this.addorupdateVendor.bind(this)} 
                                >
                                 {" "}
                                    {this.state.vendor_action === "Add"
                                      ? "Add"
                                      : "Update"}
                                </button>

                               
                                  <button className="signupbtn abtn phone-button" align="left" onClick={this.cancel}>
                                    Reset
                                  </button>
                                </div>
                                {" "}
                               
                                {""}
                                
                              </div>

                            </div>
                          </div>
                        </div>

                        

                        <div
                          className="w100"
                          style={{
                            display: this.state.search_action
                              ? "block"
                              : "none",
                          }}
                        >
                          <div className="col-sm-12">
                            <div className="hmeclr bgligtrn">
                              <h5 className="fnsz hptd"> Search Result </h5>
                            </div>
                          </div>
                          <div className="col-sm-12">
                            <div className="card">
                              <div className="card-body">
                                <div
                                  className="tab-content"
                                  id="v-pills-tabContent"
                                >
                                  <div
                                    className="tab-pane fade show active"
                                    id="v-pills-shipping"
                                    role="tabpanel"
                                    aria-labelledby="v-pills-shipping-tab"
                                  >
                                    <div className="user-notification">
                                      <SpanField
                                        classname={
                                          "0" === searchTableSuccess.status
                                            ? "text-danger user-notification"
                                            : "text-success user-notification"
                                        }
                                        title={searchTableSuccess.message}
                                      />
                                    </div>
                                    <ReactDatatable
                                      className="table table-bordered table-striped kas  mobile_table"
                                      config={this.searchconfig}
                                      records={this.state.searchrecords}
                                      columns={this.searchcolumns}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                         
                        </div>
                      </div>
                      {/* <Footerad /> */}
                    </div>
                    <Modal isOpen={modalVar}>
                      <ModalHeader>
                        <label className="text-center wdt100">
                          Confirmation
                        </label>
                      </ModalHeader>
                      <ModalBody>
                        <div className="col-sm-12">
                        Are you sure you want to delete this record ?
                        </div>
                        <div className="row">
                          <div className="col-sm-3">
                            <Button
                              color="success"
                              onClick={() => this.okDelete.bind(this)}
                            >
                              Ok
                            </Button>
                          </div>
                          <div className="col-sm-3">
                            <Button
                              color="danger"
                              onClick={this.cancelDelete.bind(this)}
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                      </ModalBody>
                    </Modal>

                  </div>
                </div>

          
              </section>)}
              <FooterSection />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VendorList);
function findWithAttr(array, attr, value) {
  for (var i = 0; i < array.length; i += 1) {
    if (array[i][attr] === value) {
      return i;
    }
  }
  return -1;
}
