import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

// import '../assets/css/main.css';

// image path 
import issue1 from '../assets/images/website/issue1.png';
import issue2 from '../assets/images/website/issue2.png';
import issue3 from '../assets/images/website/issue3.png';
import issue4 from '../assets/images/website/issue4.png';
import issue5 from '../assets/images/website/issue5.png';
import issue6 from '../assets/images/website/issue6.png';
import issue7 from '../assets/images/website/issue7.png';
import issue8 from '../assets/images/website/issue8.png';

class LogoSlider extends Component {
    constructor(props) {
        super(props);
        this.logos = [
            { 'image': issue1, 'name': 'Urinary Issues' },
            { 'image': issue2, 'name': 'Kidney Issues' },
            { 'image': issue3, 'name': 'Brain and Nerves' },
            { 'image': issue4, 'name': 'Heart' },
            { 'image': issue5, 'name': 'Dental Care' },
            { 'image': issue6, 'name': 'Ear Nose Throat' },
            { 'image': issue7, 'name': 'Digestive' },
            { 'image': issue8, 'name': 'Cancer' },
        ];

        this.owlOptions = {
            items: 6,
            autoplay: true,
            autoplayTimeout: 1000,
            loop: true,
            margin: 10,
            responsive: {
                0: {
                    items: 1,
                },
                600: {
                    items: 3,
                },
                1000: {
                    items: 6,
                },
            },
        };
    }

    render() {
        return (
            <div>
                <OwlCarousel className="owl-theme" {...this.owlOptions}>
                    {this.logos.map((logo, index) => (
                        <div key={index} className='py-4 logo-slide text-center'>
                            <div className='logo-image'>
                                <img src={logo.image} alt={logo.name} className='w-25 h-100' />
                            </div>
                            <h1 className='mt-3'>{logo.name}</h1>
                        </div>
                    ))}
                </OwlCarousel>
            </div>
        );
    }
}

export default LogoSlider;