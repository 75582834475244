
import React, { Component } from "react";
import { connect } from "react-redux";
import FooterSection from "../components/UI/FooterSection";
import Header from "../components/container/layout/pharmacy/Header";
import ReactDatatable from "@ashvin27/react-datatable";
import SideBar from "../components/container/layout/client/SideBar";
import BusinessUserHeader from "../components/container/layout/business_user/Header";
import BusinessUserSideBar from "../components/container/SideBar";
import PharmacyHeader from "../components/container/layout/pharmacy/Header";
import ClientSideBar from "../components/container/layout/client/SideBar";
import Spinner from "../components/UI/Spinner";
import axios from "axios";
import { CONFIG } from "../config/data";
import { getPrescription,getdigitalsignatureforbusinessuser,getBusinessShortDetail,getClientShortDetail,getCheckinDetails,getwrittenprescriptionbyid } from "../config/url.json";
import {getProfilePercentage} from "../constants/globalLevelFunctions";
import * as moment from "moment";
import { earthRadius } from "geolib";
import Dnaspinner from "../components/UI/Dnaspinner";
function mapStateToProps(state) {
  return {
    appdata: state.AppData,
    userdata: state.AuthReducer.user,
    access_token: state.AuthReducer.access_token,
    business_short_detail: state.SearchReducer.business_short_detail,
  };
}

class ViewPatientPrescription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      records: [],
      with_in_week: [],
      with_in_30: [],
      with_in_3_months: [],
      with_in_6_months: [],
      with_in_year: [],
      with_in_2_years: [],
      selectedOption: ["with_in_week0"],
      error: "",
    };
    this.columns = [
      {
        key: "medicine_description",
        text: "Medicine Description",
        className: "medicine_description",
        align: "left",
        sortable: true,
      },
      {
        key: "days",
        text: "Days",
        className: "days",
        align: "left",
        sortable: true,
      },
      {
        key: "before_food",
        text: "Before Food",
        className: "before_food",
        align: "left",
        sortable: true,
        cell: (record) => {
          return record.before_food === 1 ? (
            <div className="text-center">
              <i className="fa fa-check-circle grnbk" aria-hidden="true" />{" "}
            </div>
          ) : (
            ""
          );
        },
      },
      {
        key: "morning",
        text: "Morning",
        className: "morning",
        sortable: true,
        align: "left",
      },
      {
        key: "noon",
        text: "Noon",
        className: "noon",
        sortable: true,
        align: "left",
      },
      {
        key: "night",
        text: "Night",
        className: "night",
        sortable: true,
        align: "left",
      },
      {
        key: "total",
        text: "Total",
        className: "total",
        sortable: true,
        align: "left",
      },
    ];
    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50],
      button: {
        excel: false,
        print: false,
      },
    };
  }

  componentDidMount() {
       
   
   
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    console.log(this.props.checkin_id)
    const checkinData = { id: this.props.match.params.id };
    axios
      .post(getCheckinDetails, checkinData, CONFIG)
      .then((res) => {
        let completion_data = res.data.data || res.data.date;
        if (res.data.status !== "1") {
          return;
        }
        console.log(completion_data)
this.setState({client_id:completion_data.client_id,q_id:completion_data.q_id,Reg_id:completion_data.Reg_id,patient_name:completion_data.first_name,business_id:completion_data.business_id});
})

    const postData1 = { id: this.props.match.params.id };
    axios
      .post(getPrescription, postData1, CONFIG)
      .then((res) => {
        let data = res.data.consultantHistory || res.data.consultantHistory;
        console.log(data);
        console.log(res.data);
        // if (res.data.status !== "1" || !data || !data[0]) {
        //   return;
        // }
        console.log(res.data.status);
        if (res.data.status === "1") {
          console.log(res.data.data[0]);
          console.log(res.data.data[0].medicine_description);
          this.setState({
            // with_in_week: data.with_in_week,
            // with_in_30: data.with_in_30,
            // with_in_3_months: data.with_in_3_months,
            // with_in_6_months: data.with_in_6_months,
            // with_in_year: data.with_in_year,
            // with_in_2_years: data.with_in_2_years,
            records: (res.data.data[0] && null != res.data.data[0].medicine_description)
              ? res.data.data[0].medicine_description
              : [],client_id:res.data.data[0].client_id,business_id:res.data.data[0].business_id,
              pharmacy_location: res.data.data[0].pharmacy_location,records:res.data.data[0].medicine_description,
              loading:false
                         
          });
        
          
          axios
          .post(getClientShortDetail, { client_id: this.state.client_id })
          .then((short_details) => {
            if (short_details.data.status === "1") {
              let info = short_details.data.data[0];
              console.log(info);
              let headerInfo = {};
              headerInfo.avatar = info.avatar;
              headerInfo.full_name = info.first_name +''+info.last_name;
              headerInfo.registeration_id = info.patient_id;
              headerInfo.blood_group = info.blood_group;
              headerInfo.age = info.age;
              headerInfo.profile_percentage = info.profile_percentage;
              this.setState({ registeration_id:info.patient_id,patient_name:info.first_name,headerInfo });
              console.log(headerInfo);
              console.log(this.state.registeration_id);
            }
          })
          .catch((e) => {
            console.log("client short info failed",e);
          }); 

         
      
          //  const printableElements = document.getElementById("layout-wrapper").innerHTML;
            /* const orderHtml =
              "<html><head><title></title></head><body>" +
              printableElements +
              "</body></html>"; */
             /*   const orderHtml =
                  "<html><head><title></title><style>" +
                  "body { margin: 0; padding: 0; }" +
                  "body, table, td, th { font-family: Arial, sans-serif; font-size: 12px; }" +  */
                 /*  "table { border-collapse: collapse; width: 100%; }" +
                  "th, td { border: 1px solid #ddd; padding: 5px; text-align: left; color: #000; }" + */
                  /*  "</style></head><body>" +
                  printableElements +
                  "</body></html>"; 
            document.body.innerHTML = orderHtml; */
            console.log(this.props.match.params.id);
           

          //  window.print();

         /*   if (this.props.match.params.user_type.toLowerCase() === 'doctor') {
              document.location.href = '/consult/' + this.props.match.params.id
            } else {
              document.location.href = "/queue_assignment";
            }  */

           
        } else {
          this.setState({ error: res.data.message });
        }
      })
      .catch(() => { });
     
       let reqdata = {
       
        'user_id': this.props.location.state.business_user_id
        
      }
      axios
      .post(getdigitalsignatureforbusinessuser, reqdata, CONFIG)
      .then((res) => {
        let data = res.data;
        console.log(res);
        console.log(data);
        
        if (data.status === "1")
          this.setState({
           image: data.data[0].image
  
          });

})
   

axios
.post(getBusinessShortDetail, { business_id: this.state.business_id }, CONFIG)
.then((short_details) => {
 

  if (short_details.data.status === "1") {
    let info = short_details.data.data[0];
    info.profile_percentage_style = getProfilePercentage(info.profile_percentage);
    
    this.setState({ headerinfo: info, loading: false, profile_img : info.avatar });
    console.log(this.state.headerinfo);
    this.props.setBusinessShortData(this.state.headerinfo);
    
  }
})
.catch((e) => {
  console.log("business short info failed",e);
}); 
          
 

  }
Back=()=>
{
    window.onafterprint = function() {
        document.location.href = "/prescriptionlist";
      }
 
}
  render() {
    const {
      loading,
      error,
      image,
      writtenprescriptionimage,
      headerInfo,
      Reg_id,
      patient_name,
      doctor_name,
      first_name,
      speciality_name,
      records
    } = this.state;
    // console.log(with_in_week.length);
    console.log(this.props.userdata.id);
    return (
      <div id="layout-wrapper">
      
       {loading && <Dnaspinner />}
        <div className="main-content bacfot">
        { this.props.userdata.user_type === "pharmacy" ? (
                <PharmacyHeader />) :(<Header user_id={this.props.userdata.id} />)}
          <div className="mainpage main-box">
     
            <div className="sidemenu">
                            {this.props.userdata.user_type === "hospital" ? (
                                <BusinessUserSideBar />
                            ) : this.props.userdata.user_type === "doctor" ? (
                                <BusinessUserSideBar />
                            ) : this.props.userdata.user_type === "pharmacy" ? (
                                <BusinessUserSideBar />
                              ) : (
                                <ClientSideBar />
                            )}
                        </div>
            <section className="main-body hospital-profilegap">
           
              <div className="container-fluid">
                <div className="checkout-tabs">
                  <div className="row">
                   
                  <div className="col-sm-12">
                         
                         <div>
                           <div className="card">
                           <div class="card-header d-flex  justify-content-between align-items-center"> 
                           <div class="card-title">Prescription</div> 
                            <button className="signupbtn float-right" onClick={()=> this.props.history.goBack()}>Back</button>  
                           </div>
                           <table
                                          className="prescription_table"
                                          cellPadding="10%"
                                        >
                                          <tbody>
                                          <tr>
                                              <td><dt>Hospital Name</dt></td>
                                          
                                                 <td><dd>{this.props.location.state.business_name}</dd></td>
                                                  <td>Hospital Address</td>
                                              <td>
                                               
                                              </td>
                                            </tr>
                                              <tr>
                                              <td>Doctor Name</td>
                                              <td>{this.props.location.state.doctor_name}</td>
                                              <td>Speciality Name</td>
                                              <td>{this.props.location.state.speciality_name}</td>
                                            </tr> 
                                            <tr>
                                            <td>Registration. No.</td>
                                              <td>{this.props.location.state.Reg_id}</td>
                                              <td>Patient Name</td>
                                              <td>{this.props.location.state.patient_name}</td>
                                            </tr>
                                            <tr>
                                            <td>Pharmacy Name</td>
                                              <td>{this.props.location.state.pharmacy_name}</td>
                                              <td>Pharmacy Address</td>
                                              <td>{this.props.location.state.pharmacy_location}</td>
                                            </tr>
                                                                                                                                                                    
                                            <tr>
                                              <td>Date</td>

                                              <td>
                                                {/* {moment(new Date()).format(
                                                  "DD MMM YYYY hh:mm A"
                                                )} */}
                                                {this.props.location.state.date}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      {/*   <table>
                                          <tr>
                                            <td>
                                            <dt>Patient Information</dt>
      <dd>
        <dl>
          <dt>Patient Name</dt>
          <dd>{this.props.location.state.patient_name}</dd>

          <dt>Registration. No.</dt>
          <dd>{this.props.location.state.Reg_id}</dd>
        </dl>
      </dd>
                                            </td>
                                            <td>
                                            <dt>Doctor Information</dt>
      <dd>
        <dl>
          <dt>Doctor Name</dt>
          <dd>{this.props.location.state.doctor_name}</dd>

          <dt>Speciality Name</dt>
          <dd>{this.props.location.state.speciality_name}</dd>
        </dl>
      </dd>
                                            </td>
                                          </tr>

                                          <tr>
                                            <td>
                                            <dt>Hospital Information</dt>
      <dd>
        <dl>
          <dt>Hospital Name</dt>
          <dd>{this.props.location.state.business_name}</dd>

          <dt>Hospital Address</dt>
          <dd>
            {this.props.location.state.hospital_address}, 
            {this.props.location.state.hospital_city}, 
            {this.props.location.state.hospital_state} - 
            {this.props.location.state.hospital_zipcode}
          </dd>
        </dl>
      </dd>

                                            </td>
                                            <td>
                                            <dt>Pharmacy Information</dt>
      <dd>
        <dl>
          <dt>Pharmacy Name</dt>
          <dd>{this.props.location.state.pharmacy_name}</dd>

          <dt>Pharmacy Address</dt>
          <dd>{this.state.pharmacy_location}</dd>
        </dl>
      </dd>
                                            </td>
                                          </tr>
                                        </table> */}
                                         {/* <dl className="prescription-table">
     

      

      
     

      <dt>Date</dt>
      <dd>{new Date().toLocaleString()}</dd>
    </dl> */}
                             <div className="card-body ppb-6">
                               {error ? (
                                 <span className="text-danger">{error}</span>
                               ) : (
                                 ""
                               )}
                               <div
                                 className="tab-content"
                                 id="v-pills-tabContent"
                               >
                                 <div
                                   className="tab-pane fade show active"
                                   id="v-pills-shipping"
                                   role="tabpanel"
                                   aria-labelledby="v-pills-shipping-tab"
                                 >
                                  
                                  {loading ? <Dnaspinner /> : null}
                             {records.length > 0 ? (
                                
                                     <div className="col-sm-12">
                                       
                                       <ReactDatatable key={0}
                                         config={this.config}
                                         records={this.state.records}
                                         columns={this.columns}
                                         className="table table-bordered table-striped kas  mobile_table"
                                       />
                                       
                                     </div>) :""}
                                   
                                   <div className="row">
                                   <div className="col-sm-12 float-center">
                                   {this.props.location.state.writtenprescriptionimage ?   <img  className={'writtenprescriptionImage'} src={this.props.location.state.writtenprescriptionimage} />:""}
                                     </div> 
                                   </div>
                                   <div className="row">
                                   <div className="col-sm-12">
                                  
                                     </div> 
                                   </div>
                                   <div className="row">
                                   <div className="col-sm-12">
                                   <img alt="signature" className={'sigImage float-right'} src={image} />
                                   
                                     </div> 
                                   </div>
                                  
                                 </div>
                               </div>
                             </div>
                           </div>
                         </div>
                       </div>
                  </div>
                </div>
              </div>
          
            </section>
    <FooterSection />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(ViewPatientPrescription);
