import React, { Component } from 'react';
import { connect } from 'react-redux';
import QRCode from "react-qr-code";
import Header from "../components/container/layout/client/Header";
import BusinessHeader from "../components/container/layout/business/Header";
import BusinessUserHeader from "../components/container/layout/business_user/Header";
import BusinessUserSideBar from "../components/container/SideBar";
import ClientSideBar from "../components/container/layout/client/SideBar";
import FooterSection from "../components/UI/FooterSection";
import Footerad from '../components/UI/Footerad';
import axios from "axios";
import { CONFIG } from "../config/data";

import { getTimeoutbycategory } from "../config/url.json";
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModal } from '../components/container/modal/IdleModal';
import FooterWeb from './Footerweb';
import Headerweb from './Headerweb';
import SimpleSlider from './Simpleslider';
import LogoSlider from './LogoSlider';


function mapStateToProps(state) {
    return {
        appdata: state.AppData,
        userdata: state.AuthReducer.user,
        access_token: state.AuthReducer.access_token,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        logout: () => dispatch({ type: "SET_LOGOUT" }),
    };
}


class Home extends Component {
    constructor(props) {
        super(props);
        this.idleTimer = React.createRef()
        const hospitaltimeout = this.state;
        this.state = {
            timeout: hospitaltimeout,
            isTimedOut: false,
            showModal: false,
        }
        this.idleTimer = null
        this.onAction = this._onAction.bind(this)
        this.onActive = this._onActive.bind(this)
        this.onIdle = this._onIdle.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleLogout = this.handleLogout.bind(this)

       

    };
    handleClose() {
        this.setState({ showModal: false })
    }


    handleLogout = (e) => {
        this.setState({ showModal: false })
        e.preventDefault();
        localStorage.removeItem("token");
        this.props.logout();

    };

    _onAction(e) {
        console.log('user did something', e)

        this.setState({ isTimedOut: false })
    }

    _onActive(e) {

        console.log('user is active', e)
        this.setState({ isTimedOut: false })
    }

    _onIdle = (e) => {
        console.log('user is idle', e)
        let isTimedOut = this.state.isTimedOut
        let timeout = this.state.timeout;
        console.log(timeout);
        console.log(isTimedOut);
        if (isTimedOut===false) 
        {
         // console.log("the time is over");
          this.setState({showModal: true})
          console.log(this.state.showModal);
           this.idleTimer.reset();
           this.setState({isTimedOut: true})
    
        } 
       if(isTimedOut===true)
       {
        localStorage.removeItem("token");
        this.props.logout();
      }
    }

    componentDidMount() {
      // Add 'visible' class to trigger the animation after a short delay
      setTimeout(() => {
        const slideLeft = document.querySelector('.slide-left');
        const slideRight = document.querySelector('.slide-right');
        if (slideLeft) slideLeft.classList.add('visible');
        if (slideRight) slideRight.classList.add('visible');
      }, 500); // Adjust the delay as needed
    }

    render() {
        const { hospitaltimeout } = this.state;
        return (
            <div className="">

                <div className="">
                    {/* <section className="my-head">
                        <div className="container-fluid">
                            {this.props.userdata.user_type.toLowerCase() === "hospital" ? (
                                <BusinessHeader user_id={this.props.userdata.id} />
                            ) : this.props.userdata.user_type.toLowerCase() === "doctor" ? (
                                <BusinessUserHeader user_id={this.props.userdata.id} />
                            ) : (
                                <Header user_id={this.props.userdata.id} />
                            )}
                        </div>
                    </section> */}
                    <div className="">
                        {/* <div className="sidemenu ">
                            {this.props.userdata.user_type.toLowerCase() === "hospital" ? (
                                <BusinessUserSideBar />
                            ) : this.props.userdata.user_type.toLowerCase() === "doctor" ? (
                                <BusinessUserSideBar />
                            ) : (
                                <ClientSideBar />
                            )}
                        </div> */}

                        {/* <section className="main-body hospital-profilegap "> */}
                            {/* <IdleTimeOutModal
                                showModal={this.state.showModal}
                                handleClose={this.handleClose}
                                handleLogout={this.handleLogout}
                            />

                            <IdleTimer
                                ref={ref => { this.idleTimer = ref }}
                                element={document}
                                onActive={this.onActive}
                                onIdle={this.onIdle}
                                onAction={this.onAction}
                                debounce={250}
                                timeout={hospitaltimeout} /> */}

                            {/* <div className="container-fluid checkout-tabs"> */}
                                {/* <IdleTimeOutModal
                                    showModal={this.state.showModal}
                                    handleClose={this.handleClose}
                                    handleLogout={this.handleLogout}
                                /> */}

                                {/* <IdleTimer
                                    ref={ref => { this.idleTimer = ref }}
                                    element={document}
                                    onActive={this.onActive}
                                    onIdle={this.onIdle}
                                    onAction={this.onAction}
                                    debounce={250}
                                    timeout={hospitaltimeout} /> */}


                                    

                                {/* <div className="row">
                                    <div className="col-sm-12">
                                        <h4 className="font-weight-bold">QR Code</h4>
                                    </div>


                                </div> */}

                                {/* <div className="card pt-4">
                                    <div className="card-body">
                                        <div className="tab-content text-center" id="v-pills-tabContent">
                                            <h5 className="hedtxt2 ">Open the built-in camera app and point the camera at the QR Code Scanner for Check-in purposes.</h5>

                                            <div
                                                className="tab-pane fade show active qrcode pd-15"
                                                id="v-pills-shipping"
                                                role="tabpanel"
                                                aria-labelledby="v-pills-shipping-tab"

                                            >


                                                <QRCode value={this.props.userdata.phone_number} />
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                {/* <Footerad /> */}
                            {/* </div>
                          
                        </section> */}
                        
                              
                

<div className=''>
      <Headerweb />
     
      <section className='position-relative section-start'>
        <div className='position-relative'>
          <div className='row m-0 align-items-center'>
            <div className='col-lg-7 col-12 h-50 '>
              <div className='container-75 section-top ' >
                <h3>KlinicQ is  a renowned clinic providing exemplary online medical treatment services.</h3>
                <div className='d-flex align-items-center scheduel-section'>
                  <div>
                    <button type='button' className='me-4' >Schedule</button>
                    {/* <button type='button' className='me-4' onClick={booklogin} >Schedule</button> */}
                  </div>
                  <div className='play-around ml-3'>
                    {/* <FontAwesomeIcon icon={faPlay} style={{ color: '#FFF' }} beat className='icon' /> */}
                    <i class="fa-solid fa-play icons"  style={{ color: '#FFF' }} ></i>
                    
                  </div>
                  <div className='px-3'>
                    <p className='m-0'>See Video</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-5 col-12 right-img '>
              <img src= {require("../../src/assets/images/website/doctor.png")}className='w-75 pt-lg-5 pt-0 ' />
              {/* <img className='w-75 pt-lg-5 pt-0 slide-right' /> */}
            </div>
          </div>
     
          {/* <div className='goal-section'>
            <img src={goal} />
            <h1>100%</h1>
            <p>Sucess rate This Year</p>
          </div> */}
      
        </div>
        <div className='single-click py-5'>
          <div className='container'>
            <div className='row m-0'>
              <div className='col-lg-4 col-12 mb-lg-0 mb-3 slide-left visible'>
                <h1>Fostering Wellness with a Single Click </h1>
              </div>
              <div className='col-lg-7 col-12 py-lg-0 py-md-0 py-2 slide-right visible'>
                <p>KlinicQ is our Electronic Clinical Management System (e CMS) application.Electronic Clinical Management System (e CMS) application. It is user-friendly, convenient, comprehensive, and integrated to manage all hospital operations safely and with no manual error usually associated with handwriting & paper-based records.</p>
              </div>
              <div className='col-lg-1 d-lg-block d-none slide-right visible'>
                <div className='arrow-section'>
                  {/* <FontAwesomeIcon icon={faArrowRight}  style={{ color: '#FFF' }} className='icon' /> */}
                  <i class="fa-solid fa-arrow-right-long icons" style={{ color: '#FFF' }}></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className='m-0' style={{ background: '#D3F2FF' }} />
        <div className='hospital-details py-5'>
          <div className='container col-lg-11'>
            <div className='row m-0 align-items-stretch justify-content-center'>
              <div className='col-lg-4 col-md-6 col-sm-6 col-12 slide-left visible'>
                <div className='patient '>
                  <div className='img-section'>
                    <img src={require("../../src/assets/images/website/patient1.png")} className='w-100 ' />
                    {/* <img className='w-100' /> */}
                  </div>
                  <div className='doctor-content'>
                    <h1>Patient</h1>
                    <div className='inner-contentss'>
                      <div className=''>
                        <p>The patient portal is designed to manage primary & family members under the same account with a unique ID for each member.</p>
                      </div>
                      <div className=''>
                        <div className='arrow-section'>
                          {/* <FontAwesomeIcon icon={faArrowRight} style={{ color: '#00275A' }} className='icon' /> */}
                          {/* <i class="fa-solid fa-arrow-right"  style={{ color: '#00275A' }} ></i> */}
                          <img src={require("../../src/assets/images/website/blackarrow.png")} className='icons' />
                          {/* <img className='icons' /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 col-sm-6 col-12 my-lg-0 my-md-0 my-sm-0 my-2'>
                <div className='patient'>
                  <div className='img-section'>
                    <img src={require("../../src/assets/images/website/patient2.png")} className='w-100' />
                    {/* <img className='w-100' /> */}
                  </div>
                  <div className='doctor-content'>
                    <h1>Doctor</h1>
                    <div className='inner-contentss'>
                      <div className=''>
                        <p>A doctor portal is designed based on the physician's specialty and can work for any specialization.</p>
                      </div>
                      <div className=''>
                        <div className='arrow-section'>
                          {/* <FontAwesomeIcon icon={faArrowRight} style={{ color: '#00275A' }} className='icon' /> */}
                          <img src={require("../../src/assets/images/website/blackarrow.png")} className='icons' />
                          {/* <img  className='icon' /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 col-sm-6 col-12 my-lg-0 my-md-2 my-sm-2 slide-right visible'>
                <div className='patient'>
                  <div className='img-section'>
                    <img src={require("../../src/assets/images/website/patient3.png")} className='w-100' />
                  </div>
                  <div className='doctor-content'>
                    <h1>Hospital</h1>
                    <div className='inner-contentss'>
                      <div className=''>
                        <p>A hospital portal is designed to manage multi-specialty and different consultation modes.</p>
                      </div>
                      <div className=''>
                        <div className='arrow-section'>
                          {/* <FontAwesomeIcon icon={faArrowRight} style={{ color: '#00275A' }} className='icon' /> */}
                          <img src={require("../../src/assets/images/website/blackarrow.png")} className='icons' />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='fliter-section py-5 slide-left visible'>
          <div className='container-85'>
            <div className='box'>
              <div className='search-list search-option'>
                <div className='d-flex align-items-center'>
                  {/* <FontAwesomeIcon icon={faSearch} style={{ color: '#01285C', fontSize: '30px' }} /> */}
                  <i class="fa-solid fa-magnifying-glass"  style={{ color: '#01285C', fontSize: '30px' }}></i>
                  <div className='ps-3'>
                    <label className=''>Apollo hospital</label><br />
                    <input placeholder='Apollo hospital' className='border-0 mt-2' />
                  </div>
                </div>
              </div>
              <div className='search-list search-option '>
                <div>
                  <label className=''>Distance Type</label><br />
                  <select className='border-0  mt-2' placeholder="Kilometer">
                    <option className='option'>Kilometer</option>
                  </select>
                </div>
              </div>
              <div className='search-list search-option '>
                <div>
                  <label className=''>Distance Length</label><br />
                  <select className='border-0  mt-2'>
                    <option className='option'>20</option>
                  </select>
                </div>
              </div>
              <div className='search-list search-option '>
                <div>
                  <label className=''>Gender</label><br />
                  <select className='border-0  mt-2'>
                    <option className='option'>Male</option>
                  </select>
                </div>
              </div>
              <div className='search-list search-option '>
                <div>
                  <label className=''>City</label><br />
                  <select className='border-0  mt-2'>
                    <option className='option'>Madurai</option>
                  </select>
                </div>
              </div>
              <div className='search-option text-center lastbtn'>
                <button className='btn-ghost btn_sec'>Search</button>

              </div>
              {/* <div className='search-lists mx-3 d-flex align-items-center'>
                <button>Search</button>
              </div> */}
            </div>
          </div>

        </div>
        <div className='logo-slider container-90 slide-right visible'>
          <LogoSlider />
         
              
        </div>
        <div className='map-location container-85 py-5 slide-left visible'>
         

          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.2999410087273!2d77.57502788110729!3d12.952648982725313!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae15e8d9e80459%3A0x1895528330682b65!2s131%2C%20Rashtriya%20Vidyalaya%20Rd%2C%20Vishweshwarapura%2C%20Basavanagudi%2C%20Bengaluru%2C%20Karnataka%20560004!5e0!3m2!1sen!2sin!4v1718691093610!5m2!1sen!2sin" width="100%" height="450" style={{ border: 0, width: '100%' }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
        <hr />
        <div className='profile-section ps-lg-5 ps-0'>
          <SimpleSlider />
        </div>
        <div className='rest-section py-lg-5 py-md-4 py-0'>
          <div className='container-90'>
            <div className='row m-0 align-items-center'>
              <div className='col-lg-6 col-12 slide-left visible'>
                <h1 className='mb-4'>Rest assured, your health is in the most capable hands.</h1>
                <p className='mb-3'>With medical professionals in more than 100+ countries and a 98% customer satisfaction rate, Doctoralia offers reliable health information. With an extensive network of doctors, we are able to offer a wide range of high quality medical tests. What's more, our prices are up to 70% cheaper than other online providers.</p>
             
                <div className='d-lg-none d-md-none d-sm-none d-block'>
                  <div className='d-flex align-items-center'>
                    <img src={require("../../src/assets/images/website/rest3.png")} />
                    <div className='mar-top'>
                      <label className='ps-lg-3 ps-md-3 ps-0'>Available</label><br />
                      <p className='mb-0 ps-lg-3 ps-md-3 ps-0'>900+ specialists</p>
                    </div>
                  </div>
                  <div className='d-flex align-items-center pb-3'>
                    <img src={require("../../src/assets/images/website/rest1.png")} />
                    <div className='ms-2'>
                      <label className='ps-lg-3 ps-md-3 ps-0'>Available</label><br />
                      <p className='mb-0 ps-lg-3 ps-md-3 ps-0'>900+ specialists</p>
                    </div>
                  </div>
                </div>
                <div className='book-appointment'>
                  <div className='row m-0'>
                    <div className='col-9'>
                      <h1>Book an Appointment</h1>
                      <p>Book an Appointment</p>
                    </div>
                    <div className='col-3 d-flex align-items-center'>
                      <div className='calender'>
                        <img src={require("../../src/assets/images/website/calender.png")} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-6 col-12 text-end position-relative slide-right visible'>
                <img src={require("../../src/assets/images/website/rest3.png")} className='rest3' />
                <div className='available'>
                  <div className='d-flex align-items-center p-lg-3 p-md-3 p-1 '>
                    {/* <FontAwesomeIcon icon={faCircleDot} beat style={{ color: '#46E826', fontSize: '20px' }} className='beat-icon' /> */}
                    <i class="fa-solid fa-circle-dot"  beat style={{ color: '#46E826', fontSize: '20px' }} className='beat-icon'></i>
                    <div className='mar-top'>
                      <label className=''>Available Specialist</label><br />
                      <p className='mb-0 ps-3'>Select Specialist </p>
                    </div>
                  </div>
                  <div className='d-flex align-items-center p-lg-3 p-md-3 p-1'>
                    <img src={require("../../src/assets/images/website/rest4.png")} />
                    <div className='doctor-name'>
                      <h2 className='ps-3'>Dr Adinda</h2>
                      <p className='mb-0 text-start ps-3'>Eye Specialist</p>
                    </div>
                  </div>
                  <div className='d-flex align-items-center p-lg-3 p-md-3 p-1'>
                    <img src={require("../../src/assets/images/website/rest5.png")} />
                    <div className='doctor-name'>
                      <h2 className='ps-3'>Dr Jackson</h2>
                      <p className='mb-0 text-start ps-3'>Ear Specialist</p>
                    </div>
                  </div>
                  <div className='find-doctor text-center my-3'>
                    <button>Find Doctor <img src={require("../../src/assets/images/website/arrowup.png")} /></button>
                    {/* <button>Find Doctor </button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='online-section py-5'>
          <div className='container'>
            <div className='row m-0 align-items-center'>
              <div className='col-lg-6 col-12 slide-left visible'>
                <h1>Get 15-Minutes Complimentary online session.</h1>
                <p>Limited Period Offer. Claim Now.</p>
                {/* <button type='button' className='btn_sec' onClick={booklogin}>Book Appoinment <img src={arrowup} /></button> */}
                <button type='button' className='btn_sec'  >Book Appoinment<i class="fa-solid fa-arrow-right-long ml-2" style={{color: '#FFF'}}></i> </button>
              </div>
              <div className='col-lg-6  col-12 my-lg-0 mt-4 slide-right visible'>
                <img src={require("../../src/assets/images/website/online.png")} className='w-100' />
              </div>
            </div>
          </div>
        </div>
        <hr className='m-0' style={{ background: '#D3F2FF' }} />
        <div className='single-click py-5'>
          <div className='container'>
            <div className='row m-0'>
              <div className='col-lg-4 col-12 mb-lg-0 mb-3 slide-left visible'>
                <h1>Fostering Wellness with a Single Click </h1>
              </div>
              <div className='col-lg-7 col-12 py-lg-0 py-md-0 py-2'>
                <p>Electronic Clinical Management System (e CMS) application. It is user-friendly, convenient, comprehensive, and integrated to manage all hospital operations safely and with no manual error usually associated with handwriting & paper-based records.</p>
              </div>
              <div className='col-lg-1 d-lg-block d-none slide-right visible'>
                <div className='arrow-section'>
                  {/* <FontAwesomeIcon icon={faArrowRight}  style={{ color: '#FFF' }} className='icon' /> */}
                  <i class="fa-solid fa-arrow-right-long icons" style={{color:'#FFF'}} ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='patient-section py-5'>
          <div className='container'>
            <div className='row m-0 '>
              <div className='col-lg-6  col-12 d-flex align-items-stretch slide-left visible'>
                <div class="card" >
                  <img src={require("../../src/assets/images/website/patient4.png")} class="card-img-top" alt="..." />
                  <div class="card-body">
                    <h2 class="card-text">What to do if you think your child has the flu</h2>
                    <div className='row m-0'>
                      <div className='col-lg-11 col-md-11 col-12 p-0'>
                        <p>If you hear your child start coughing, it's natural to wonder: could this be the flu, or is it another virus like COVID or RSV, or even a common cold? Here's what to know about similarities between these illnesses and what to do if your child does have the flu.</p>
                      </div>
                      {/* <div className='col-1 p-0 d-flex align-items-center'>
                        <div className='d-lg-block d-md-block d-none'>
                          <img src={arrowupblack} className='' />
                        </div>
                      </div> */}
                    </div>
                    <a href="#" class="btn-text">Read more
                    
<svg width="14" height="35" className='ml-2' viewBox="0 0 80 35" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M54 0.075C53.8 -0.025 53.6 -0.025 53.5 0.075C53.3 0.175 53.2 0.374998 53.2 0.474998V10.475H0.5C0.2 10.475 0 10.675 0 10.975V23.575C0 23.875 0.2 24.075 0.5 24.075H53.2V34.075C53.2 34.275 53.3 34.475 53.5 34.475C53.6 34.475 53.7 34.575 53.7 34.575C53.8 34.575 53.9 34.575 54 34.475L79.8 17.575C79.9 17.475 80 17.275 80 17.175C80 17.075 79.9 16.875 79.8 16.775L54 0.075Z" fill="#5a49f8"/>
</svg>

                    </a>
                  </div>
                </div>
              </div>
              <div className='col-lg-6 col-12 my-lg-0 my-2 slide-right visible'>
                <div class="card" >
                  <img src={require("../../src/assets/images/website/patient5.png")} class="card-img-top" alt="..." />
                  <div class="card-body">
                    <h2 class="card-text">What to do if you think your child has the flu</h2>
                    <div className='row m-0'>
                      <div className='col-lg-11 col-md-11 col-12 p-0'>
                        <p>If you hear your child start coughing, it's natural to wonder: could this be the flu, or is it another virus like COVID or RSV, or even a common cold? Here's what to know about similarities between these illnesses and what to do if your child does have the flu.</p>
                      </div>
                      {/* <div className='col-lg-1 col-md-1 col-0 p-0 e d-flex align-items-center'>
                        <div className='d-lg-block d-md-block d-none'>
                          <img src={arrowupblack} className='' />
                        </div>
                      </div> */}
                    </div>
                    <a href="#" class="btn-text">Read more
                    
                    <svg width="14" height="35" className='ml-2' viewBox="0 0 80 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M54 0.075C53.8 -0.025 53.6 -0.025 53.5 0.075C53.3 0.175 53.2 0.374998 53.2 0.474998V10.475H0.5C0.2 10.475 0 10.675 0 10.975V23.575C0 23.875 0.2 24.075 0.5 24.075H53.2V34.075C53.2 34.275 53.3 34.475 53.5 34.475C53.6 34.475 53.7 34.575 53.7 34.575C53.8 34.575 53.9 34.575 54 34.475L79.8 17.575C79.9 17.475 80 17.275 80 17.175C80 17.075 79.9 16.875 79.8 16.775L54 0.075Z" fill="#5a49f8"/>
                    </svg>
                    
                                        </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterWeb className="qrCodeFooter" />
      </section>
      </div>

                        

                    
                    </div>

                </div>
            </div>
        );
    }
    componentDidMount = () => {
     /*    console.log(this.props.userdata.user_type);
        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${this.props.access_token}`;

        axios
            .post(getTimeoutbycategory, { category: this.props.userdata.user_type }, CONFIG)
            .then((res) => {
                let data = res.data;
                console.log(res);
                console.log(data);
                console.log(data.data.timeout);
                if (data.status === "1")
                    this.setState({
                        hospitaltimeout: data.data.timeout

                    });
            })
            .catch((e) => { }); */
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(Home);