import React, { Component,Fragment } from 'react';
import { connect } from 'react-redux';
import Header from "../components/container/layout/pharmacy/Header";
import BusinessHeader from "../components/container/layout/business/Header";
import BusinessUserHeader from "../components/container/layout/business_user/Header";
import BusinessUserSideBar from "../components/container/SideBar";
import ClientSideBar from "../components/container/layout/client/SideBar";
import { Spinner } from "../components/UI/Spinner";
import FooterSection from "../components/UI/FooterSection";
import ReactDatatable from "@ashvin27/react-datatable";
import { getAppointmentStatus } from "../constants/globalLevelFunctions";
import { getPharmacyPrescriptionList,getprescriptionbyphonenumber } from "../config/url.json";
import axios from "axios";
import { CONFIG } from "../config/data";
import Select from "react-select";
import { ExpirePopup } from "../components/UI/ExpirePopup";
import * as moment from "moment";
import { getDateISOFormat } from "../constants/globalLevelFunctions";
import { Popupinfo } from "../components/UI/Popupinfo";
import Dnaspinner from '../components/UI/Dnaspinner';
import Button from '@mui/material/Button';
import InputField from "../components/UI/InputField";
import SpanField from "../components/UI/SpanField";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function mapStateToProps(state) {
    return {
      appdata: state.AppData,
      userdata: state.AuthReducer.user,
      access_token: state.AuthReducer.access_token,
      validity: state.ValidityReducer.validity
    };
  }

function mapDispatchToProps(dispatch) {
    return {

    };
}

class PrescriptionList extends Component {
    constructor(props) {
        super(props);
    
        this.searchData = {
          phone_number: "",
          error: "",
        };
        this.state = {
          adminstatus: this.props.userdata.adminstatus,
          showexpirePopup: this.props.validity,
          timestamp: 'no timestamp yet',
          doc_list: [],
          selected_doctor: "",
          doctor_appointment: [],
          queue: {
            id: "",
            client_id: "",
            business_id: "",
            first_name: "",
            last_name: "",
            age: "",
            date_of_birth: "",
            gender: "",
            marital_status: "",
            phone_number: "",
            status: "cancelled",
            patient_name: "",
            actual_checkin_time: "",
            expected_time: "",
            waitTime: "",
          },
          queueList: [],
          selectedQueue: [],
          loading: false,
          searchData: this.searchData,
        };
        this.columns = [
        
            {
              key: "s_no",
              text: "S.No",
              className: "s_no",
              align: "left",
              sortable: true,
            },
            {
              key: "patient_name",
              text: "Patient Name",
              className: "patient_name",
              align: "left",
              sortable: true,
            },
           /*  {
              key: "patient_id",
              text: "Patient ID",
              className: "patient_id",
              align: "left",
              sortable: true,
            }, */
            {
              key: "phone_number",
              text: "Phone Number",
              className: "phone_number",
              align: "left",
              sortable: true,
              cell: (record) => {
                return record.phone_number ? record.phone_number:record.parent_phone_number ;
              },
            },
            {
                key: "business_name",
                text: "Hospital Name",
                className: "hospital name",
                align: "left",
                sortable: true,
              },
              {
                key: "doctor_name",
                text: "Doctor Name",
                className: "doctor_name",
                align: "left",
                sortable: true,
              },
         

            {
              key: "appt_status",
              text: "Status",
              className: "appt_status",
              sortable: true,
              align: "left",
              cell: (record) => {
                if(record.appt_status === 'RESCHEDULE')
                {
                 return "Rescheduled";
                } else if(record.appt_status==='SCHEDULED')
                {
                 return "Scheduled";
                }else
                return record.appt_status;
            },
            },
            {
              key: "action",
              text: "Action",
              className: "action",
              width: 100,
              align: "left",
              sortable: false,
              cell: (record) => {
                return (
                  <Fragment>
                    <button 
                      className="btn btn-primary btn-sm"
                     onClick={() => this.viewProfile(record)}
                      style={{ marginRight: "5px" ,marginLeft: "25px"}}
                    >
                      <i className="fa fa-eye"></i>
                    </button>
                  </Fragment>
                );
              },
            },
                     
        /*     {
              key: "appt_statusscheduled",
              text: "Scheduled",
              className: "text-center",
              sortable: true,
              align: "left",
              cell: (record) => {
               return (
                  <Fragment>
                    <a href="#" class="a-disable">
                      <i
                        className={
                          (getAppointmentStatus(record, "SCHEDULED")||getAppointmentStatus(record, "RESCHEDULE"))
                            ? "fa fa-check-circle grnbk"
                            : ""
                        }
                        aria-hidden="true"
                      ></i>{" "}
                    </a>
                  </Fragment>
                );
              },
            },
            {
              key: "appt_statuschekin",
              text: "Check-In",
              className: "text-center",
              sortable: true,
              align: "left",
              cell: (record) => {
                return (
                  <Fragment>
                    <a href="#" class="a-disable">
                      <i
                        className={
                          getAppointmentStatus(record, "Check-In")
                            ? "fa fa-check-circle grnbk"
                            : ""
                        }
                        aria-hidden="true"
                      ></i>{" "}
                    </a>
                  </Fragment>
                );
              },
            },
            {
              key: "appt_statusconsult",
              text: "Consultation",
              className: "text-center",
              sortable: true,
              align: "left",
              cell: (record) => {
                return (
                  <Fragment>
                    <a href="#" class="a-disable">
                      <i
                        className={
                          getAppointmentStatus(record, "Consulting")
                            ? "fa fa-check-circle grnbk"
                            : ""
                        }
                        aria-hidden="true"
                      ></i>{" "}
                    </a>
                  </Fragment>
                );
              },
            },
            {
              key: "appt_statuscheckout",
              text: "Check-Out",
              className: "text-center",
              sortable: true,
              align: "left",
              cell: (record) => {
                return (
                  <Fragment>
                    <a href="#" class="a-disable">
                      <i
                        className={
                          getAppointmentStatus(record, "Check-Out")
                            ? "fa fa-check-circle grnbk"
                            : ""
                        }
                        aria-hidden="true"
                      ></i>{" "}
                    </a>
                  </Fragment>
                );
              },
            },
            */
            
          ];
          this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            button: {
              excel: false,
              print: false,
            },
          };
    }


    componentDidMount = () => {

       
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${this.props.access_token}`;
          axios
            .post(getPharmacyPrescriptionList, {pharmacy_id:this.props.userdata.id}, CONFIG)
            .then((result) => {
           //  console.log(result);
             console.log(result.data.data);
             let resdata = result.data.data;
             let prescriptionList = [];
             resdata.forEach((element, index) => {
               let prescription = {};
              prescription.s_no = index + 1;
               prescription.patient_id = element.patient_id;
               prescription.doctor_name=element.DoctorFirstname + " " + element.DoctorLastname;
               prescription.patient_name = element.first_name + " " + element.last_name;
               prescription.business_name = element.business_name;
               prescription.dob = element.date_of_birth;
               prescription.gender = element.gender;
               prescription.blood_group = element.blood_group;
               prescription.pharmacy_location=element.pharmacy_location;
               prescription.writtenprescriptionimage=element.writtenprescriptionimage;
               prescription.pharmacy_name=element.pharmacy_name;
               prescription.created_at=element.created_at;
               prescription.phone_number = element.phone_number;
               prescription.app_time = element.expected_time;
               prescription.wait_time = element.waitTime;
               prescription.status = element.status;
               prescription.checkin_id = element.checkin_id;
               prescription.isfee = element.fee;
               prescription.isprescription = element.prescription;
               prescription.isfollowup = element.followup;
               prescription.client_id = element.client_id;
               prescription.business_user_id=element.business_user_id;
               prescription.id = element.id;
               prescription.q_id = element.q_id;
               prescription.schedule_day_id = element.schedule_day_id;
               prescription.pharmacy_details = element.pharmacy_details
                 ? element.pharmacy_details
                 : "";
                 prescription.consultation_fee = element.consultation_fee
                 ? element.consultation_fee
                 : "";
                 prescription.follow_up_details = element.follow_up_details
                 ? element.follow_up_details
                 : "";
                 prescriptionList[index] = prescription;
             });



              this.setState({ prescriptionList:prescriptionList, loading: false });
            }).catch((err) => { console.log('queue list error' + err) });
       
        this.setState({ loading: true });
    
        
    
      };
      getPharmacyPrescriptionList = (pharmacy_id)=>
      {

        if (pharmacy_id !== "" ) {
          let reqdata = {
            pharmacy_id: this.props.userdata.id
           
          }
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${this.props.access_token}`;
        axios
          .post(getPharmacyPrescriptionList,reqdata, CONFIG)
          .then((result) => {
         //  console.log(result);
           console.log(result.data.data);
           let resdata = result.data.data;
           let prescriptionList = [];
           resdata.forEach((element, index) => {
             let prescription = {};
            prescription.s_no = index + 1;
             prescription.patient_id = element.patient_id;
             prescription.doctor_name=element.DoctorFirstname + " " + element.DoctorLastname;
             prescription.patient_name = element.first_name + " " + element.last_name;
             prescription.business_name = element.business_name;
             prescription.dob = element.date_of_birth;
             prescription.gender = element.gender;
             prescription.blood_group = element.blood_group;
             prescription.pharmacy_location=element.pharmacy_location;
             prescription.writtenprescriptionimage=element.writtenprescriptionimage;
             prescription.pharmacy_name=element.pharmacy_name;
             prescription.created_at=element.created_at;
             prescription.phone_number = element.phone_number;
             prescription.app_time = element.expected_time;
             prescription.wait_time = element.waitTime;
             prescription.status = element.status;
             prescription.checkin_id = element.checkin_id;
             prescription.isfee = element.fee;
             prescription.isprescription = element.prescription;
             prescription.isfollowup = element.followup;
             prescription.client_id = element.client_id;
             prescription.business_user_id=element.business_user_id;
             prescription.id = element.id;
             prescription.q_id = element.q_id;
             prescription.schedule_day_id = element.schedule_day_id;
             prescription.pharmacy_details = element.pharmacy_details
               ? element.pharmacy_details
               : "";
               prescription.consultation_fee = element.consultation_fee
               ? element.consultation_fee
               : "";
               prescription.follow_up_details = element.follow_up_details
               ? element.follow_up_details
               : "";
               prescriptionList[index] = prescription;
           });



            this.setState({ prescriptionList:prescriptionList, loading: false });
          }).catch((err) => { console.log('queue list error' + err) });

      }
      }
      refreshData = () => {
 
        let searchData = this.state.searchData;
        searchData = { phone_number: "", errors: "" };
        this.setState({
            searchData: searchData});

            this.getPharmacyPrescriptionList(this.props.userdata.id);
       
     
      //this.setState({ loading: true });
  
      
      };
    
      viewProfile = (user) => {
        console.log(user);
       // this.props.setSearchData({ user_id: user.user_id, user_type: "employee" });
       this.props.history.push({
        pathname: "/viewpatientprescription/"+user.checkin_id,});
        this.props.history.replace({
          pathname: "/viewpatientprescription/"+user.checkin_id,
          state: {
            business_id:user.business_id,
            Reg_id:user.patient_id,
            patient_name:user.patient_name,
            business_user_id:user.business_user_id,
            business_name:user.business_name,
            doctor_name:user.doctor_name,
            pharmacy_name:user.pharmacy_name,
            pharmacy_location: user.pharmacy_location,
            writtenprescriptionimage:user.writtenprescriptionimage,
            date:user.created_at
          }
       }); 

      }

      handleselectChange = (selectedoption, event) => {
    
        let { doc_list } = this.state;
        let doctor_shift = [];
        if (event.name === "doc_list") {
    
          doc_list[event.name] = selectedoption
    
    
          selectedoption.doctor_Appointments.forEach((doc, k) => {
            doctor_shift.push({ value: doc.schedule_day_id, label: "Shift " + (k + 1) + "(" + doc.starttime + " - " + doc.endtime + ")" })
          })
    
          this.setState({ doc_list: doc_list, selected_doctor: selectedoption, doctor_shift: doctor_shift, selected_shift: doctor_shift[0] });
          this.getAppointments(selectedoption.value)
        }
        if (event.name === 'doctor_shift') {
    
          this.setState({ selected_shift: selectedoption })
          this.getAppointments(this.state.selected_doctor.value)
        }
      }

      getPatientDetail = () => {
        // event.preventDefault();
    
        let searchData = this.state.searchData;
        if (searchData.phone_number === "") {
          searchData.error = "Phone number is required";
          this.setState({ searchData: searchData });
          return;
        }
        if (searchData.phone_number.length !== 10) {
          searchData.error = "Enter a valid phone number";
          this.setState({ searchData: searchData });
          return;
        }
    
    
        if (searchData.phone_number !== "" && searchData.phone_number.length === 10) {
          this.setState({ loading: true });
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${this.props.access_token}`;
          axios
            .post(
              getprescriptionbyphonenumber,
              {pharmacy_id:this.props.userdata.id, phone_number: searchData.phone_number },
              CONFIG
            )
            .then((result) => {
              this.setState({ loading: false });
             
              if (result.data.status === "1") {
                let searchprescription = result.data.prescription;
                console.log(searchprescription);

                let searchprescriptionList = [];
                searchprescription.forEach((element, index) => {
                  let prescription = {};
                 prescription.s_no = index + 1;
                  prescription.patient_id = element.patient_id;
                  prescription.doctor_name=element.DoctorFirstname + " " + element.DoctorLastname;
                  prescription.patient_name = element.first_name + " " + element.last_name;
                  prescription.business_name = element.business_name;
                  prescription.dob = element.date_of_birth;
                  prescription.gender = element.gender;
                  prescription.blood_group = element.blood_group;
                  prescription.pharmacy_location=element.pharmacy_location;
                  prescription.writtenprescriptionimage=element.writtenprescriptionimage;
                  prescription.pharmacy_name=element.pharmacy_name;
                  prescription.created_at=element.created_at;
                  prescription.phone_number = element.phone_number;
                  prescription.app_time = element.expected_time;
                  prescription.wait_time = element.waitTime;
                  prescription.status = element.status;
                  prescription.checkin_id = element.checkin_id;
                  prescription.isfee = element.fee;
                  prescription.isprescription = element.prescription;
                  prescription.isfollowup = element.followup;
                  prescription.client_id = element.client_id;
                  prescription.business_user_id=element.business_user_id;
                  prescription.id = element.id;
                  prescription.q_id = element.q_id;
                  prescription.schedule_day_id = element.schedule_day_id;
                  prescription.pharmacy_details = element.pharmacy_details
                    ? element.pharmacy_details
                    : "";
                    prescription.consultation_fee = element.consultation_fee
                    ? element.consultation_fee
                    : "";
                    prescription.follow_up_details = element.follow_up_details
                    ? element.follow_up_details
                    : "";
                    searchprescriptionList[index] = prescription;
                });
   
   
                this.setState({
                  searchprescriptionList:searchprescriptionList,
                  loading: false,
               
                });
               
              } else {
                searchData.error = result.data.message;
                this.setState({
                 searchprescriptionList:[],
                  loading: false,
               
                });
              }
            })
            .catch((e) => {
              console.log(e);
              console.log(e.data);
              console.log("business user getbyid failed");
              //          searchData.error = e.data.message;
              this.setState({ loading: false });
            });
        }
      };
    
      handlePhoneInput = (event) => {
        event.preventDefault();
        let data = this.state.searchData;
        let errors = this.state.errors;
        data[event.target.name] = event.target.value
          .replace(/\s/g, "")
          .replace(/[^0-9+]+/g, "");
        data.error = "";
        this.setState({ searchData: data, errors });
      };
    render() {
        const { headerinfo, queueList, doc_list,loading,searchSuccess } = this.state;
    let loggedinUserId = this.props.userdata.id;
    let navigation = {};
    navigation.userId = loggedinUserId;
    console.log(this.state.showexpirePopup)
    let PopupClose = () => {
      window.location.reload();
      this.setState({ showexpirePopup: true })
    };
    let PopupHospitalClose = () => {
      this.setState({ hospitalpopup:true})
     
    };
    let PopupConfirm = () => {
      this.props.history.push({
        pathname: "/showsubscription",
        state: {}
      });
     
    };
        return (
            <div className="main-content bacfot">
           {/*  <ExpirePopup
              show={!this.state.showexpirePopup}
              onHide={PopupClose}
              onConfirm={PopupConfirm}
              title={"Alert"}
              message={"Your current plan has been expired. Please activate new plan and try again"}
            /> */}
            <div className="mainpage">
              <section className="my-head">
                <div className="container-fluid">
                  {this.props.userdata.user_type === "hospital" ? (
                    <BusinessHeader user_id={this.props.userdata.id} />
                  ) : this.props.userdata.user_type === "doctor" ? (
                    <BusinessUserHeader user_id={this.props.userdata.id} />
                  ) : (
                    <Header user_id={this.props.userdata.id} />
                  )}
                </div>
              </section>
              <div className="main-box">
    
                <div className="sidemenu pr0">
                  {this.props.userdata.user_type === "hospital" ? (
                    <BusinessUserSideBar />
                  ) : this.props.userdata.user_type === "pharmacy" ? (
                    <BusinessUserSideBar />
                  ) : (
                    <ClientSideBar />
                  )}
                </div>
                {(this.state.adminstatus !== "" && this.state.adminstatus === "Pending") ? (
                                        <div className="container-fluid">
                                                    <div className="checkout-tabs">
                                                        
                                    <div className="row">
                                        <div className="col-sm-12 pr0">
                                            <div className="card">
                                                <div className="card-body a-page-1-hight-right ">
                                                    {/* Admin not approved your account yet to view this page. Please contact the administrator to approve your account. */}
                                                    <Popupinfo
                                                     show={!this.state.hospitalpopup}
                                                     onHide={PopupHospitalClose}
                                                     title={"Alert!!"}
                                                      message={"Your Account is now waiting for our approval. Kindly contact the administrator to approve your request."}
                                                      />
                                               </div>

                                            </div>
                                        </div>
                                        </div>
                                        </div>

                                    </div>) :
           (
                <section className="main-body hospital-profilegap">
                  <div className="container-fluid">
                    <div className="checkout-tabs">
                   {/*    <Spinner loading={this.state.loading} /> */}
                   {loading && <Dnaspinner />}
                      <div className="row align-items-center mb-2">
                        <div className="col-lg-4">
    
                          {/* <p className="App-intro">
                            This is the timer value: {this.state.timestamp}
                          </p> */}
    
                          <h4 className="font-weight-bold mb-0"> Prescription List </h4>
                        </div>
                      
                        {/* <div className="col-lg-4">
                          <Select maxMenuHeight={120}
                            className="form-control mt-2 queuedropdown"
                            name="doctor_shift"
                            // value={selected_queue.first_name}
                            onChange={
                              this.handleselectChange
                            }
                            value={this.state.selected_shift}
                            options={this.state.doctor_shift}
                          // isClearable={true}
                          />
                        </div> */}
    
                      
                      </div>
                      <div className="row">
                      <div className="col-sm-12">
                      <div className="card mt-4">
                      <div className="card-body pad-btm0">
                  <div className="tab-content" id="v-pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="v-pills-shipping"
                      role="tabpanel"
                      aria-labelledby="v-pills-shipping-tab"
                    >
                           <div className="row">
                        <div className="col-sm-3">
                          <div className="required">
                            <label className="f12">
                              <img
                                alt=""
                                src={require("../assets/images/phone.png")}
                                className="imgsfd"
                              />{" "}
                              Mobile number 
                            </label>
                          </div>
                        </div>
                        <div className="col-sm-3 ">
                          <div className="form-group">
                            <div className="input-group">
                              <InputField
                                inputType={"text"}
                                name={"phone_number"}
                                controlFunc={this.handlePhoneInput}
                                content={this.state.searchData.phone_number}
                                placeholder={""}
                              />
                            </div>
                           
                          </div>
                        </div>
                        <div className="col-sm-3 bm-9 ">
                          <div className="form-group ">
                            <div className="row">
                              <table className="tablebtn">
                                <tbody>
                                  <tr>
                                    <td>
                                      <button
                                        type="button"
                                        className="signupbtn abtn"
                                        onClick={this.getPatientDetail}
                                      >
                                        Search
                                      </button>
                                    </td>
                                     <td>
                                      <button
                                        type="button"
                                        className="s1"
                                        onClick={this.refreshData}
                                      >
                                        Reset
                                      </button>
                                    </td>
                                  
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        </div>
                        </div>
                            </div>
                          </div>
                          </div>
                          <div className="card">
                            <div className="card-body ">
                            {/* <div className="col-lg-4 mg-btm2 float-right mg-right1"> */}
                            {/* <div className='row d-flex justify-content-center'>
                            
                            
                            <div className="col-lg-4">
                            <div className=''> */}
                            <div className="row justify-content-center">
                             
                             <div className="col-12 col-md-4 col-lg-4 mg-btm2">
                             <div className="">
                            <div className='form-group '>
                        {/*   <Select maxMenuHeight={120}
                            className="prescriptionWidth"
                            name="doc_list"
                           
                            onChange={
                              this.handleselectChange
                            }
                            value={this.state.selected_doctor}
                            options={doc_list}
                         
                          /> */}
                          </div>
                          </div>
                          </div>
                        </div>
                              <div
                                className=" tab-content d-inline"
                                id="v-pills-tabContent"
                              >
                                
                                <button className="float-right btn btn-primary d-inline" onClick={this.refreshData}>Refresh
                                  <i className="fa fa-refresh">
                                  </i></button>
                                <div>
 
     
 
                                <div
                                  className="tab-pane fade show active"
                                  id="v-pills-shipping"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-shipping-tab"
                                >
                                  <div className='ram'>
                                    {this.state.searchprescriptionList ?
                                    (<ReactDatatable
                                    config={this.config}
                                    records={this.state.searchprescriptionList}
                                    columns={this.columns}
                                    className="table table-bordered table-striped kas  mobile_table"
                                    // table-responsive remove from the classname
                                  />) :
                                (<ReactDatatable
                                  config={this.config}
                                  records={this.state.prescriptionList}
                                  columns={this.columns}
                                  className="table table-bordered table-striped kas  mobile_table"
                                  // table-responsive remove from the classname
                                />)}
                                
                                </div>
                                </div>
                                </div>
                                
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <Footerad /> */}
    
                    </div>
                  </div>
               
                  
                </section>)}

                <FooterSection />
              </div>
            </div>
          </div>
        );
    }
}

export default connect(
    mapStateToProps,
)(PrescriptionList);