
import React, { Fragment } from "react";
 import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  getReadCount
} from "../../config/url.json";
import { CONFIG } from "../../config/data";
import axios from "axios";

function SideBar(props) {

  const handleLogout = (e) => {
    e.preventDefault();
    localStorage.removeItem("token");
    props.logout();
    props.history.push("/");
  };
 // let message_count = props.appdata?props.appdata[0].message_count : "";
 const [click, setClick] = React.useState(false);

  const handleClick = () => setClick(!click);
  return (
    <div className={click ? "sidebar-toggleon toggle" : " toggle"} >

    <button  onClick={()=>handleClick()} >
    <i className="fa fa-bars" aria-hidden="true"></i>
    </button>
      
    <div className="vertical-menu displaymobilenone scrollbox">
      <div data-simplebar="">
        {/* <!--- Sidemenu --> */}
        <div id="sidebar-menu">
          {/*   <!-- Left Menu Start -->*/}
          <ul className="metismenu list-unstyled" id="side-menu">
          
           
            <li className="sidmnl">
              <a
                href="/dashboard"
                className=" waves-effect"
                aria-expanded="false"
              >
                <i className="fa-solid fa-house"></i>
                <span> Dashboard</span>
             </a>
              </li>
              
              {(props.user.user_type === 'doctor'||props.user.user_type === 'business_user'||props.user.user_type === 'Doctor') ?
              (
                <Fragment>

                <li className="sidmnl">
                <a
                  href="/consult"
                  className=" waves-effect"
                  aria-expanded="false"
                >
                  <i className="fa-solid fa-user-doctor"></i>
                  <span> Consult</span>
                </a>
              </li>
             
            
              <li className="sidmnl">
                <a
                  href="/doctorshift_planner"
                  className=" waves-effect"
                  aria-expanded="false"
                >
                 <i className="fas fa-sticky-note"></i>
                  <span> Shift Planner</span>
                </a>
              </li>
             {/*  <li className="sidmnl">
                <a
                  href="/canvas"
                  className=" waves-effect"
                  aria-expanded="false"
                >
                 <i className="fas fa-sticky-note"></i>
                  <span> Canvas</span>
                </a>
              </li> */}
             {/*  <li className="sidmnl">
                <a
                  href="/PrescriptionTemplate"
                  className=" waves-effect"
                  aria-expanded="false"
                >
                 <i className="fas fa-sticky-note"></i>
                  <span> PrescriptionTemplate</span>
                </a>
              </li> */}
              </Fragment>
              ):(props.user.user_type === 'business'||props.user.user_type === 'hospital')?(
                <Fragment>
                <li className="sidmnl">
                  <a
                    href="/queuelist"
                    className=" waves-effect"
                    aria-expanded="false"
                  >
                    {/* <i className="fas fa-key"></i> */}
                    <i class="fa-solid fa-clipboard-list"></i>
                    <span> Token List</span>
                  </a>
                </li>
                <li className="sidmnl">
                  <a
                    href="/appointmentlist"
                    className=" waves-effect"
                    aria-expanded="false"
                  >
                   <i className="fas fa-calendar-check"></i>
                    <span> Appointment List</span>
                  </a>
                </li>
                <li>
              <a
              
                className="has-arrow waves-effect"
                aria-expanded="false"
              >
                <i className="fas fa-key"></i>
                <span> Token</span>
              </a>
              <ul className="ulcls mm-collapse">
                <a href="/patient-checkin">
                  <li>
                  {/* <i className="fas fa-location-arrow"></i> */}
                  <i class="fa-solid fa-person-walking-arrow-right"></i>
                    Patient Check-In
                  </li>
                </a>
                <a href="/queue_assignment">
                  <li>
                  {/* <i className="fas fa-location-arrow"></i> */}
                  <i class="fa-solid fa-person-walking-arrow-loop-left"></i>
                   Patient Check-Out
                  </li>
                </a>
               
              </ul>
            </li>
            <li>
              <a
               /*  href="#" */
                className="has-arrow waves-effect"
                aria-expanded="false"
              >
               <i className="fas fa-calendar-week"></i>
                <span> Appointment</span>
              </a>
              <ul className="ulcls mm-collapse">
                <a href="/appointment-checkin">
                  <li>
                  {/* <i className="fas fa-location-arrow"></i> */}
                  <i class="fa-solid fa-person-walking-arrow-right"></i>
                    Patient Check-In
                  </li>
                </a>
                <a href="/ApptCheckout">
                  <li>
                  {/* <i className="fas fa-location-arrow"></i> */}
                  <i class="fa-solid fa-person-walking-arrow-loop-left"></i>
                   Patient Check-Out
                  </li>
                </a>
               
              </ul>
            </li>
                {/* <li className="sidmnl">
                <a
                  href="/patient-checkin"
                  className="waves-effect"
                  aria-expanded="false"
                >
                  <i className="">
                  <img alt="" src={require("../../assets/images/icon5.png")} />
                  </i>
                  <span> Patient Check-In</span>
                </a>
              </li>
              <li className="sidmnl">
                <a
                  href="/appointment-checkin"
                  className="waves-effect"
                  aria-expanded="false"
                >
                  <i className="">
                  <img alt="" src={require("../../assets/images/icon5.png")} />
                  </i>
                  <span> Appointment Check-In</span>
                </a>
              </li>
              <li className="sidmnl">
                <a
                  href="/queue_assignment"
                  className=" waves-effect"
                  aria-expanded="false"
                >
                  <i className="">
                  <img alt="" src={require("../../assets/images/icon14.png")} />
                  </i>
                  <span>Patient Check-Out</span>
                </a>
              </li>
              <li className="sidmnl">
                <a
                  href="/ApptCheckout"
                  className=" waves-effect"
                  aria-expanded="false"
                >
                  <i className="">
                  <img alt="" src={require("../../assets/images/icon14.png")} />
                  </i>
                  <span>Appt Patient Check-Out</span>
                </a>
              </li> */}
              <li className="sidmnl">
                <a
                  href="/shift_planner"
                  className=" waves-effect"
                  aria-expanded="false"
                >
                 {/* <i className="fas fa-sticky-note"></i> */}
                 {/* <i class="fa-solid fa-code-compare"></i>  */}
                 <i class="fa-solid fa-people-arrows"></i>
                  <span> Shift Planner</span>
                </a>
              </li>

              </Fragment>
              ):""}
              
              {(props.user.user_type === 'pharmacy') ? (
                <Fragment>
                <li className="sidmnl">
                  <a
                    href="/prescriptionlist"
                    className=" waves-effect"
                    aria-expanded="false"
                  >
                    {/* <i className="fas fa-key"></i> */}
                    <i class="fa-solid fa-clipboard-list"></i>
                    <span> Prescription List</span>
                  </a>
                </li>
                <li className="sidmnl">
                  <a
                    href="/pharmacydrugslist"
                    className=" waves-effect"
                    aria-expanded="false"
                  >
                   <i className="fas fa-calendar-check"></i>
                    <span>Drug List</span>
                  </a>
                </li>
                <li className="sidmnl">
                  <a
                    href="/pharmacydruglist"
                    className=" waves-effect"
                    aria-expanded="false"
                  >
                   <i className="fas fa-calendar-check"></i>
                    <span>Item List</span>
                  </a>
                </li>
                <li className="sidmnl">
                  <a
                    href="/manufacturerlist"
                    className=" waves-effect"
                    aria-expanded="false"
                  >
                   <i className="fas fa-calendar-check"></i>
                    <span>Manufacturer List</span>
                  </a>
                </li>
                <li className="sidmnl">
                  <a
                    href="/vendorlist"
                    className=" waves-effect"
                    aria-expanded="false"
                  >
                   <i className="fas fa-calendar-check"></i>
                    <span>Vendor List</span>
                  </a>
                </li>
                <li className="sidmnl">
                  <a
                    href="/purchaseentryvoucher"
                    className=" waves-effect"
                    aria-expanded="false"
                  >
                   <i className="fas fa-calendar-check"></i>
                    <span>Purchase Entry Voucher</span>
                  </a>
                </li>
                
               {/*  <li className="sidmnl">
                  <a
                    href="/adddrugs"
                    className=" waves-effect"
                    aria-expanded="false"
                  >
                   <i className="fas fa-calendar-check"></i>
                    <span>Add Drug List</span>
                  </a>
                </li> */}
                          

              </Fragment>
              ):""}


              {/* <li className="sidmnl">
              <ul
                className="ulcls mm-collapse"
                aria-expanded="false"
                style={{ height: "8px" }}
              >
                {(props.user.user_type === 'doctor'||props.user.user_type === 'business_user') ? 
                (<a href="/consult">
                  <li>
                    <img
                      alt=""
                      src={require("../../assets/images/icon5.png")}
                    />
                    Consult{" "}
                  </li>
                </a>):""}
                <a href="/patient-checkin">
                  <li>
                    <img
                      alt=""
                      src={require("../../assets/images/icon5.png")}
                    />
                    Patient Check-in{" "}
                  </li>
                </a>
                <a href="/appointments">
                  <li>
                    <img src={require("../../assets/images/icon14.png")} />
                    Appointments
                  </li>
                </a>
                <a href="/queue_assignment">
                  <li>
                    <img src={require("../../assets/images/icon14.png")} />
                    Queue Assignment
                  </li>
                </a>
                <a href="/queuelist">
                  <li>
                    <img src={require("../../assets/images/icon13.png")} />
                    Queue List
                  </li>
                </a>
                <a href={() => false}>
                  <li>
                    <img src={require("../../assets/images/icon15.png")} />
                    Prescription
                  </li>
                </a>
                <a href={() => false}>
                  <li>
                    <img src={require("../../assets/images/icon25.png")} />
                    QR code
                  </li>
                </a>
              </ul>
              </li> */}
{(props.user.user_type === 'doctor'||props.user.user_type === 'business_user'||props.user.user_type === 'Doctor'||props.user.user_type === 'pharmacy') ? "":(
  <Fragment>
  <li className="sidmnl">
              <a
               /*  href="#" */
                className="has-arrow waves-effect"
                aria-expanded="false"
              >
               <i className="fas fa-users"></i>
                <span> Profile</span>
              </a>

              <ul className="ulcls mm-collapse">
              <a href="/profile">
                  <li>
                  <i className="fas fa-user"></i>
                    My Account
                  </li>
                </a> 
                <a href="/businessuser-profile">
                  <li>
                  <i className="fas fa-users"></i>
                    Employee profile{" "}
                  </li>
                </a>              
                <a href="/client-profile">
                  <li>
                  <i className="fas fa-user"></i>
                    Patient profile
                  </li>
                </a>
              </ul>
            </li>

            <li>
              <a
               /*  href="#" */
                className="has-arrow waves-effect"
                aria-expanded="false"
              >
               <i className="fas fa-user-cog"></i>
                <span> Admin reports</span>
              </a>
              <ul className="ulcls mm-collapse">
                <a href="/clients">
                  <li>
                  <i className="fas fa-clipboard-list"></i>
                    Patients List
                  </li>
                </a>
                <a href="/businessuser-list">
                  <li>
                  <i className="fas fa-clipboard-list"></i>
                    Employee List
                  </li>
                </a>
                <a href="/facilitylist">
                  <li>
                  <i className="fas fa-laptop-house"></i>
                    Facility List
                  </li>
                </a>
                <a href="/VisitList">
                  <li>
                  <i className="fas fa-bahai"></i>
                    Token Visit List
                  </li>
                </a>
                <a href="/VisitApptList">
                  <li>
                  <i className="fas fa-eye"></i>
                    Appt Visit List
                  </li>
                </a>
               
              </ul>
            </li>

            <li>
              <a
              /*   href="#" */
                className="has-arrow waves-effect"
                aria-expanded="false"
              >
                <i className="fas fa-clinic-medical"></i>
                <span> Clinical management</span>
              </a>
              <ul className="ulcls mm-collapse" style={{ borderbottom: 0 }}>
                <a href="/drugs">
                  <li>
                  <i className="fas fa-pills"></i>
                    Drug List
                  </li>
                </a>
                <a href="/medicinesupplies">
                  <li>
                  <i className="fas fa-hand-holding-medical"></i>
                    Medicine Supplies List
                  </li>
                </a>
                {/* <a href={() => false}>
                  <li>
                    <img
                      alt=""
                      src={require("../../assets/images/icon10.png")}
                    />
                    Doctor List
                  </li>
                </a> */}
                                           
                 <a href="/pharmacyList">
                 <li>
                 <i className="fas fa-hospital-alt"></i>
                Pharmacy List
                </li>
              </a>
             
                 { /* < a href="/pharmacyProfile">
                  <li>
                    <img
                      alt=""
                      src={require("../../assets/images/icon22.png")}
                    />
                    Pharmacy List
                  
                   
                  </li>
                </a>
*/ }            
              
                <a href="/labList">
                  <li>
                  <i className="fas fa-vials"></i>
                    Lab List
                  </li>
                </a>
                <a href="/disease">
                  <li>
                  <i className="fas fa-disease"></i>
                    Disease List
                  </li>
                </a>

                <a href="/symptom">
                  <li>
                  <i className="fas fa-thermometer-full"></i>
                    Symptoms List
                  </li>
                </a>
              </ul>
            </li>
                        
            <li className="sidmnl">
              <a
                href="/inbox"
                className="waves-effect"
                aria-expanded="false"
              >
               <i className="fas fa-bell"></i>
                <span>Notifications</span><span className="badge badge-light notificationicon"></span>
                {props.message_count > 0 ? (<span className="pulse bg-danger"></span>):""}
              </a>
            </li>
            </Fragment> 
            )}
   

            <li className="sidmnl">
              <a
                href="/referrals"
                className="waves-effect"
                aria-expanded="false"
              >
               <i className="fas fa-sync-alt"></i>
                <span>Referrals</span>
              </a>
            </li>
            {(props.user.user_type === 'doctor'||props.user.user_type === 'business_user'|| props.user.user_type === 'Doctor') ? (
              <li className="sidmnl">
              <a
                href={"/profile"}
                className="waves-effect"
                aria-expanded="false"
              >
               <i className="fas fa-user-alt"></i>
                <span>My Account</span>
              </a>
            </li>
            ):""}
            
            {/* <li>
              <a href={() => false} className="waves-effect" aria-expanded="false">
                <i className="">
                  <img src={require("../../assets/images/icon17.png")} />
                </i>
                <span>Finance</span>
              </a>
            </li> */}
            <li>
              <a href="/supportweb" className="waves-effect" aria-expanded="false">
              <i className="fas fa-headset"></i>
                <span>Support</span>
              </a>
            </li>
           {(props.user.user_type === 'hospital')?(
            <li>
              <a href="/showsubscription" className="waves-effect" aria-expanded="false">
              <i className="fas fa-file-invoice-dollar"></i>
                <span>Show Subscription</span>
              </a>
            </li>):""}

            <li>
              <a href="/qr_code" className="waves-effect" aria-expanded="false">
              <i className="fas fa-qrcode"></i>
                <span>QR code</span>
              </a>
            </li>

            <li>
              <a href="/viewoffers" className="waves-effect" aria-expanded="false">
              <i className="fas fa-percent"></i>
                <span>Offers</span>
              </a>
            </li>

            <li>
              <a href="/faqweb" className="waves-effect" aria-expanded="false">
              <i className="fas fa-comment-dots"></i>
                <span>FAQ</span>
              </a>
            </li>

            <li className="sidmnl sidmnlss">
              <a
                href="#"
                className="waves-effect"
                aria-expanded="false"
                onClick={handleLogout}
              >
                <i className="fas fa-sign-out-alt"></i>
                <span>Sign out</span>
              </a>
            </li>
            
          </ul>
        </div>
        {/*<!-- Sidebar -->*/}
      </div>
    </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    loggedIn: state.AuthReducer.loggedIn,
    appdata: state.AppData,
    user: state.AuthReducer.user,
    message_count: state.AuthReducer.message_count,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch({ type: "SET_LOGOUT" }),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SideBar)
);
