 import React, { Component } from 'react';
 import { connect } from "react-redux";
 import axios from "axios";
 import {  CONFIG } from "../config/data";
 import { updatewrittenprescriptionimage } from "../config/url.json";
 import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
 function mapStateToProps(state) {
  return {
    appdata: state.AppData,
    userdata: state.AuthReducer.user,
    access_token: state.AuthReducer.access_token,
  };
}
 class Canvas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDrawing: false,
      showHeaders:true,
      color: "#3B3B3B",
      size: "3",
      cursor: "default",
      lastX: null,
  lastY: null,
  offsetX: 0,
      offsetY: 0,
      startX: 0,
      startY: 0
    };
    this.canvasRef = React.createRef();
    this.ctx = React.createRef();
    this.timeout = React.createRef();
  }

  componentDidMount() {
    console.log(this.props.userdata.id);
    console.log(this.props.match.params.id);
    const canvas = this.canvasRef.current;
    this.ctx.current = canvas.getContext("2d");

    //Resizing
     canvas.height = window.innerHeight;
    canvas.width = window.innerWidth; 
    const ctx = canvas.getContext("2d");
    this.ctx.current = ctx;
    this.ctx.current.lineWidth = this.state.size;
    this.ctx.current.lineCap = "round";
    this.ctx.current.strokeStyle = this.state.color;
  
    // Define the table dimensions
   // const canvas = document.getElementById('myCanvas');
//const ctx = canvas.getContext('2d');




const numCols = 7;
const columnWidths = [20, 250, 30, 30, 30, 30,50]; // specify the width of each column
const totalWidth = columnWidths.reduce((a, b) => a + b, 0);
const scaleFactor = canvas.width / totalWidth;

// Draw the table borders
ctx.strokeStyle = "black";
ctx.strokeRect(0, 0, canvas.width, canvas.height);

// Draw the table headers
if (this.state.showHeaders) {
  ctx.font = "bold 15px Arial";
  ctx.textAlign = "center";
  ctx.textBaseline = "top";
  const headers = ["S.no", "Medicine Description","Morning", "Noon", "Night","Days","Before/After Food"];
  let x = 0;
  for (let col = 0; col < numCols; col++) {
    const columnWidth = columnWidths[col] * scaleFactor;
    const headerX = x + columnWidth / 2;
    const y = 10; // adjust the y-coordinate to position the text at the top
    ctx.fillStyle = "black";
    ctx.fillText(headers[col], headerX, y);
    x += columnWidth;
  }
}

// Draw the table columns
let x = 0;
for (let col = 0; col < numCols; col++) {
  const columnWidth = columnWidths[col] * scaleFactor;
  ctx.beginPath();
  ctx.moveTo(x, 0); // adjust the y-coordinate to start the column line below the header text
  ctx.lineTo(x, canvas.height);
  ctx.stroke();
  x += columnWidth;
}
    //Load from local storage
    const canvasimg = localStorage.getItem("canvasimg"+ this.props.match.params.id);
    if (canvasimg) {
      var image = new Image();
      this.ctx.current = canvas.getContext("2d");
      image.onload = function () {
        this.ctx.current.drawImage(image, 0, 0);
        this.setState({ isDrawing: false });
      }.bind(this);
      image.src = canvasimg;
    }
    // Handle Wacom pen events
 //   canvas.addEventListener('pointerdown', this.handlePointerDown);
    canvas.addEventListener('pointermove', this.handlePointerMove);
    canvas.addEventListener('pointerup', this.handlePointerUp);
  
  }

  /* startPosition = ({ nativeEvent }) => {
    this.setState({ isDrawing: true });
    if (nativeEvent) {
    this.draw(nativeEvent);
    }
  }; */
  startPosition = ({ nativeEvent }) => {
    console.log(nativeEvent);
    this.setState({ isDrawing: true });
    if(nativeEvent.type === "mousedown")
      {
        console.log("mouse");
      }
    if (nativeEvent) {
    this.draw(nativeEvent);
    }
  };
  handlePointerDown = (event) => {
    if (event.pointerType === 'pen') {
      console.log(event);
      if (event.pointerType === "pen") {
        this.setState({ isDrawing: true });
        this.ctx.current.beginPath();
        this.ctx.current.moveTo(
          event.clientX - this.canvasRef.current.offsetLeft,
          event.clientY - this.canvasRef.current.offsetTop
        );
        this.draw(event);
      }
    }
  };

  finishedPosition = () => {
    this.setState({ isDrawing: false, lastX: null, lastY: null });
    this.ctx.current.beginPath();
  };

/*   draw = ({ nativeEvent }) => {
    if (!this.state.isDrawing) {
      return;
    }
    const canvas = this.canvasRef.current;
    this.ctx.current = canvas.getContext("2d");
    this.ctx.current.lineWidth = this.state.size;
    this.ctx.current.lineCap = "round";
    this.ctx.current.strokeStyle = this.state.color;

    this.ctx.current.lineTo(nativeEvent.clientX, nativeEvent.clientY);
    this.ctx.current.stroke();
    this.ctx.current.beginPath();
    this.ctx.current.moveTo(nativeEvent.clientX, nativeEvent.clientY);

    if (this.timeout.current !== undefined) clearTimeout(this.timeout.current);
    this.timeout.current = setTimeout(function () {
      var base64ImageData = canvas.toDataURL("image/png");
      localStorage.setItem("canvasimg", base64ImageData);
    }, 400);
  };  */

 /*  draw = ({ nativeEvent }) => {
    if (!this.state.isDrawing) {
      return;
    }
    const canvas = this.canvasRef.current;
    const ctx = canvas.getContext("2d");
    if (!ctx) {
      console.error("Failed to get canvas context");
      return;
    }
    this.ctx.current = ctx;
    this.ctx.current.lineWidth = this.state.size;
    this.ctx.current.lineCap = "round";
    this.ctx.current.strokeStyle = this.state.color;
  
    // Define the table dimensions
    const numRows = 6;
    const numCols = 6;
    const cellWidth = canvas.width / numCols;
    const cellHeight = canvas.height / numRows;
  
    // Draw the table borders
    this.ctx.current.strokeStyle = "black";
    this.ctx.current.strokeRect(0, 0, canvas.width, canvas.height);
  
    // Draw the table cells
    for (let row = 0; row < numRows; row++) {
      for (let col = 0; col < numCols; col++) {
        const x = col * cellWidth;
        const y = row * cellHeight;
        this.ctx.current.fillStyle = "white";
        this.ctx.current.fillRect(x, y, cellWidth, cellHeight);
        this.ctx.current.strokeStyle = "black";
        this.ctx.current.strokeRect(x, y, cellWidth, cellHeight);
      }
    }
  
    // Draw the table headers (optional)
    if (this.state.showHeaders) {
      this.ctx.current.font = "12px Arial";
      this.ctx.current.textAlign = "center";
      this.ctx.current.textBaseline = "middle";
      const headers = ["S.no", "Medicine", "Days", "Morning", "Noon", "Night"];
      for (let col = 0; col < numCols; col++) {
        const x = col * cellWidth + cellWidth / 2;
        const y = cellHeight / 2;
        this.ctx.current.fillStyle = "black";
        this.ctx.current.fillText(headers[col], x, y);
      }
    }
    
   
    this.ctx.current = canvas.getContext("2d");
    this.ctx.current.lineWidth = this.state.size;
    this.ctx.current.lineCap = "round";
    this.ctx.current.strokeStyle = "green";

    const columnIndex = Math.floor((nativeEvent.clientX - canvas.offsetLeft) / cellWidth);

    // Adjust the x coordinate to stay within the column boundaries
    const x = columnIndex * cellWidth + (nativeEvent.clientX - canvas.offsetLeft) % cellWidth;
  
    // Update the drawing logic to use the adjusted x coordinate
    this.ctx.current.beginPath();
    this.ctx.current.moveTo(x, nativeEvent.clientY);
this.ctx.current.lineTo(x, nativeEvent.clientY);
this.ctx.current.stroke();
    if (this.timeout.current !== undefined) clearTimeout(this.timeout.current);
    this.timeout.current = setTimeout(function () {
      var base64ImageData = canvas.toDataURL("image/png");
      localStorage.setItem("canvasimg", base64ImageData);
    }, 400); 
  
    // Rest of the drawing logic...
  }; */
 
/*   draw = ({ nativeEvent }) => {
  if (!this.state.isDrawing) {
    return;
  }
  const canvas = this.canvasRef.current;
  this.ctx.current = canvas.getContext("2d");
  this.ctx.current.lineWidth = this.state.size;
  this.ctx.current.lineCap = "round";
  this.ctx.current.strokeStyle = this.state.color;

  if (!this.state.lastX || !this.state.lastY) {
    this.ctx.current.beginPath();
    this.ctx.current.moveTo(nativeEvent.clientX, nativeEvent.clientY);
    this.setState({ lastX: nativeEvent.clientX, lastY: nativeEvent.clientY });
  } else {
    this.ctx.current.lineTo(nativeEvent.clientX, nativeEvent.clientY);
    this.ctx.current.stroke();
    this.ctx.current.beginPath();
    this.ctx.current.moveTo(nativeEvent.clientX, nativeEvent.clientY);
    this.setState({ lastX: nativeEvent.clientX, lastY: nativeEvent.clientY });
  }

  if (this.timeout.current !== undefined) clearTimeout(this.timeout.current);
  this.timeout.current = setTimeout(function () {
    var base64ImageData = canvas.toDataURL("image/png");
    localStorage.setItem("canvasimg", base64ImageData);
  }, 400);
};
 */
draw = (event) => {
  if (!this.state.isDrawing) {
    return;
  }
  const canvas = this.canvasRef.current;
  this.ctx.current = canvas.getContext("2d");
  this.ctx.current.lineWidth = this.state.size;
  this.ctx.current.lineCap = "round";
  this.ctx.current.strokeStyle = this.state.color;

  const nativeEvent = event.nativeEvent;
  if (nativeEvent) {
    const clientX = nativeEvent.clientX;
    const clientY = nativeEvent.clientY;

    if (!this.state.lastX || !this.state.lastY) {
      this.ctx.current.beginPath();
      this.ctx.current.moveTo(clientX, clientY);
      this.setState({ lastX: clientX, lastY: clientY });
    } else {
      this.ctx.current.lineTo(clientX, clientY);
      this.ctx.current.stroke();
      this.ctx.current.beginPath();
      this.ctx.current.moveTo(clientX, clientY);
      this.setState({ lastX: clientX, lastY: clientY });
    }
  }

  if (this.timeout.current !== undefined) clearTimeout(this.timeout.current);
  this.timeout.current = setTimeout(function () {
    var base64ImageData = canvas.toDataURL("image/png");
    localStorage.setItem("canvasimg", base64ImageData);
  }, 400);
};

clearCanvas = () => {
    localStorage.removeItem("canvasimg");
    const canvas = this.canvasRef.current;
    const context = canvas.getContext("2d");
    context.fillStyle = "white";
    context.fillRect(0, 0, canvas.width, canvas.height);
    canvas.height = window.innerHeight;
    canvas.width = window.innerWidth; 
    const ctx = canvas.getContext("2d");
    this.ctx.current = ctx;
    this.ctx.current.lineWidth = this.state.size;
    this.ctx.current.lineCap = "round";
    this.ctx.current.strokeStyle = this.state.color;
  
    // Define the table dimensions
   const numCols = 7;
const columnWidths = [20, 250, 30, 30, 30, 30,50]; // specify the width of each column
const totalWidth = columnWidths.reduce((a, b) => a + b, 0);
const scaleFactor = canvas.width / totalWidth;

// Draw the table borders
ctx.strokeStyle = "black";
ctx.strokeRect(0, 0, canvas.width, canvas.height);

// Draw the table headers
if (this.state.showHeaders) {
  ctx.font = "bold 15px Arial";
  ctx.textAlign = "center";
  ctx.textBaseline = "top";
  const headers = ["S.no", "Medicine Description", "Morning", "Noon", "Night","Days","Before/After Food"];
  let x = 0;
  for (let col = 0; col < numCols; col++) {
    const columnWidth = columnWidths[col] * scaleFactor;
    const headerX = x + columnWidth / 2;
    const y = 10; // adjust the y-coordinate to position the text at the top
    ctx.fillStyle = "black";
    ctx.fillText(headers[col], headerX, y);
    x += columnWidth;
  }
}

// Draw the table columns
let x = 0;
for (let col = 0; col < numCols; col++) {
  const columnWidth = columnWidths[col] * scaleFactor;
  ctx.beginPath();
  ctx.moveTo(x, 0); // adjust the y-coordinate to start the column line below the header text
  ctx.lineTo(x, canvas.height);
  ctx.stroke();
  x += columnWidth;
}
    //Passing clear screen
    if (this.timeout.current !== undefined) clearTimeout(this.timeout.current);
    this.timeout.current = setTimeout(function () {
      var base64ImageData = canvas.toDataURL("image/png");
      localStorage.setItem("canvasimg", base64ImageData);
    }, 400);
  };

  getPen = () => {
    this.setState({ cursor: "default", size: "3", color: "#3B3B3B" });
  };

  eraseCanvas = () => {
    this.setState({ cursor: "grab", size: "20", color: "#FFFFFF" });

    if (!this.state.isDrawing) {
      return;
    }
  };
  Back=()=>
    {
      document.location.href="/consult/" + this.props.match.params.id;
    }
  trimcanvas= () => {
      const canvas = this.canvasRef.current;
      const writtenprescriptionimage = canvas.toDataURL("image/png");
      console.log(writtenprescriptionimage);
     if(writtenprescriptionimage!=null)
     {
  
  let resultdata = {
    'writtenprescriptionimage': writtenprescriptionimage,
    'id': this.props.match.params.id
    
  }
  axios.post(updatewrittenprescriptionimage, resultdata, CONFIG).then((result) => {
   console.log("updated written prescription");
   
  })
   toast.success("Your prescription has been successfully updated.",{
    className:"toast-success"
  }); 
}
/* const link = document.createElement("a");
link.download = "canvas_image.png";
link.href = image;
link.click(); */

// get the image as blob
/* const canvas = this.canvasRef.current;
canvas.toBlob((blob) => {
  const url = URL.createObjectURL(blob);
  console.log(url);
  const link = document.createElement("a");
  link.download = "canvas_image.png";
  link.href = url;
  link.click();
}); */
  };

  render() {
    const {trimmedDataURL}=this.state;
    return (
      <>
       <ToastContainer position="bottom-center" hideProgressBar={true} autoClose={2000} showToast={true} closeOnClick={false} pauseOnHover />

        <div className="floating-button">
       
          <button onClick={this.getPen} className="btn-width">
            Pencil
          </button>
          <div className="btn-width">
            <input
              type="color"
              value={this.state.color}
              onChange={(e) => this.setState({ color: e.target.value })}
            />
          </div>
          <div>
            <select
              className="btn-width"
              value={this.state.size}
              onChange={(e) => this.setState({ size: e.target.value })}
            >
              <option> 1 </option>
              <option> 3 </option>
              <option> 5 </option>
              <option> 10 </option>
              <option> 15 </option>
              <option> 20 </option>
              <option> 25 </option>
              <option> 30 </option>
            </select>
          </div>
          <button onClick={this.clearCanvas} className="btn-width">
            Clear
          </button>
          <div>
            <button onClick={this.eraseCanvas} className="btn-width">
              Erase
            </button>
          </div>
          <div>
             <button onClick={this.trimcanvas} className="btn-width">
              Save
            </button> 
            <div>
             <button onClick={this.Back} className="btn-width">
              Back
            </button> 
          </div>
        </div>
        </div>
      

              <canvas 
          style={{ cursor: this.state.cursor }}
          onMouseDown={this.startPosition}
          onMouseUp={this.finishedPosition}
          onMouseMove={this.draw}
         // handlePointerDown={this.handlePointerDown}
          handlePointerMove={this.handlePointerDown}
          handlePointerUp={this.finishedPosition}
          ref={this.canvasRef}
        />
         {trimmedDataURL
        ? <img alt="" className={'sigImage'}
          src={trimmedDataURL} />
        : null}
      </>
    );
  }
}

export default connect(mapStateToProps)(Canvas);