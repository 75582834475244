
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";


import Register from "./views/register/Register";
import VerificationPage from "./views/register/VerificationPage";
import NotFound from "./views/NotFound";
import "../src/assets/css/bootstrap.min.css";
import "../src/assets/css/icons.min.css";
import "../src/assets/css/app.min.css";
import "../src/assets/css/mystyle.css";
import "../src/assets/css/javascript-calendar.css";
// import "../src/assets/js/app.js";

import ForgetPassword from "./views/ForgetPassword";
import Profile from "./views/Profile";
import video from "./views/video";
import BusinessUserList from "./views/BusinessUserList";
import GuestRoute from "./components/GuestRoute";
import AuthRoute from "./components/AuthRoute";
import Dashboard from "./views/Dashboard";
import QueueList from "./views/QueueList";
import AppointmentList from "./views/AppointmentList";

import Search from "./views/Search";
import ScheduleConfirmation from "./views/ScheduleConfirmation";

import ClientFamilyDetails from "./views/ClientFamilyDetails";

import ClientFavourites from "./views/ClientFavourites";
import ClientAppointments from "./views/ClientAppointments";
import UpdateProfile from "./views/UpdateProfile";
import Clients from "./views/Clients";
import Diseases from "./views/Diseases";
import Drugs from "./views/Drugs";
import AddDrugs from "./views/AddDrugs";
import DrugList from "./views/DrugList";
import ClientCheckin from "./views/ClientCheckin";

import BusinessLayout from "./views/Business/BusinessLayout";
import Promocode from "./views/Promocode";
import FooterAdverdisement from "./views/FooterAdverdisement";
import ViewProfile from "./views/Business/ViewProfile";
import Symptoms from "./views/Symptoms";
import Consult from "./views/Consult";
import DoctorConsultation from "./views/DoctorConsultation";
import Prescription from "./views/Prescription";
import ClientPrescription from "./views/ClientPrescription";
import ClientMedicineScheduling from "./views/ClientMedicineScheduling";
import ClientConsultation from "./views/ClientConsultation";
import Consultationsummary from "./views/Consultationsummary";
import ClientApptConsultation from "./views/ClientApptConsultation";
import ClientApptPrescription from "./views/ClientApptPrescription";
import Referrals from "./views/Referrals";
import QueueAssignment from "./views/QueueAssignment";
import PrintPrescription from "./views/PrintPrescription";
import QR_Code from "./views/QRCode";
import Support from "./views/Support";
import SupportDefault from "./views/SupportDefault";
import VendorForm from "./views/VendorForm";
import UpdateVendorForm from "./views/UpdateVendorForm";
import ClientBookAppointments from "./views/ClientBookAppointments";
import ShiftPlanner from "./views/Business/ShiftPlanner";
import DoctorShiftPlanner from "./views/Business/DoctorShiftPlanner";
import AddPrescription from "./views/Business/AddPrescription";
import InPatientPrescription from "./views/Business/InPatientPrescription";
import AdminDashboard from "./views/AdminDashboard";
import NewsFeeds from "./views/NewsFeeds";
import Notification from "./views/Notification";
import DoctorList from "./views/DoctorList";
import HospitalList from "./views/HospitalList";
import PatientList from "./views/PatientList";
import AdminPharmacyList from "./views/AdminPharmacyList";
import AdminLabList from "./views/AdminLabList";
import PharmacyProfile from "./views/Business/PharmacyProfile";
import SupplierList from "./views/Inventory/SupplierList";
import StorageBin from "./views/Inventory/StorageBin";
import PurchaseInfo from "./views/Inventory/PurchaseInfo";
import StockReport from "./views/Inventory/StockReport";
import PlabList from "./views/PlabList";
import DrugImport from "./views/DrugImport";
import SymptomImport from "./views/SymptomImport";
import SpecialityList from "./views/SpecialityList";
import SubscriptionPlan from "./views/SubscriptionPlan";
import Showsubscription from "./views/ShowSubscription";
import ShowSubscriptionClient from "./views/ShowSubscriptionClient";
import Timeout from "./views/Timeout";
import DoctorCountBySubscription from "./views/DoctorCountBySubscription";
import InboxController from "./views/InboxController";
import PrivacyPolicy from "./views/PrivacyPolicy";
import TermsAndCondition from "./views/TermsAndCondition";
import Faq from "./views/Faq";
import FaqWeb from "./views/FaqWeb";
import Offers from "./views/Offers";
import ViewOffers from "./views/ViewOffers";
import OffersWeb from "./views/OffersWeb";
import Videoupload from "./views/Videoupload";
import DoctorAppointment from "./views/DoctorAppointment";
import QueueCompletedReport from "./views/QueueCompletedReport";
import QueueReport from "./views/QueueReport";
import VisitList from "./views/Business/VisitList";
import AppointmentCompletedReport from "./views/AppointmentCompletedReport";
import AppointmentReport from "./views/AppointmentReport";
import ApptCheckout from "./views/ApptCheckout";
import Addapptprescription from "./views/Business/Addapptprescription";
import Printapptprescription from "./views/Printapptprescription";
import VisitApptList from "./views/Business/VisitApptList";
import ResetPassword from "./views/ResetPassword";
import JoinRoom from "./views/Video/JoinRoom";
import Feeds from "./views/Feeds";
import RequirementList from "./views/Inventory/RequirementList";
import MaterialMovement from "./views/Inventory/MaterialMovement";
import Transfertype from "./views/Transfertype";
import help from "./views/help";
import Hospitalprintprescription from "./views//Hospitalprintprescription";
import InPatientCheckin from "./views/InpatientModule/InPatientCheckin";
import InpFacilityList from "./views/InpatientModule/InpFacilityList";
import InpRoomTransfer from "./views/InpatientModule/InpRoomTransfer";
import InpatientList from "./views/InpatientModule/InpatientList";
import InpatientConsultation from "./views/InpatientModule/InpatientConsultation";
import InPatientBilling from "./views/InpatientModule/InPatientBilling";
import AmbulanceManagement from "./views/InpatientModule/AmbulanceManagement";
import SupportNew from "./views/SupportNew";
import SupportLatest from "./views/SupportLatest";
import ServiceCharges from "./views/ServiceCharges";
import Canvas from "./views/Canvas";
import PrintPrescriptionnew from "./views/PrintPrescriptionnew";
import PrescriptionTemplate from "./views/PrescriptionTemplate";
import PrescriptionList from "./views/PrescriptionList";
import ViewPatientPrescription from "./views/ViewPatientPrescription";
import PharmacyDruglist from "./views/PharmacyDruglist";
import PharmacyDrugslist from "./views/PharmacyDrugslist";
import PurchaseEntryVoucher from "./views/PurchaseEntryVoucher";
import Manufacturerlist from "./views/Manufacturerlist";
import VendorList from "./views/VendorList";
import About from "./views/Website";
import Webproduct from "./views/Webproduct";
import Benifits from "./views/Benifits";
import Simpleslider from "./views/Simpleslider";
import LogoSlider from "./views/LogoSlider";
import BookDemo from "./views/BookDemo";
import Home from "./views/TestHome";
import Footerweb from "./views/Footerweb";
import Headerweb from "./views/Headerweb";


const Routes = ({ users = [], Errors = [] }) => {
  return (
    <div>
      <Router>
        {/* {(Errors) ? <ShowErrors /> : null} */}
        <Switch>
          {/* <Route exact path="/updatebusiness" component={UpdateBusiness} /> */}
          {/* <Route exact path="/updateclient" component={UpdateClient} /> */}
          <AuthRoute exact path="/dashboard" component={Dashboard} />
           <AuthRoute exact path="/queuelist" component={QueueList} />
          <AuthRoute exact path="/appointmentlist" component={AppointmentList} />
          {/* <Route exact path="/updatebuser" component={UpdateBusinessUser} /> */}
          <GuestRoute exact path="/verify" component={VerificationPage} />
          <GuestRoute exact path="/register" component={Register} />
          <GuestRoute exact path="/" component={Home} />
          <GuestRoute exact path="/referral-code/:referral_code" component={Register} />
          <Route exact path="/vendorform" component={VendorForm} />
          <Route exact path="/updatevendor/:id" component={VendorForm} />
          <Route exact path="/vendor/:id" component={UpdateVendorForm} />
          <GuestRoute
            exact
            path="/reset-password"
            component={ForgetPassword}
          />
          <GuestRoute exact path="/reset-password/:id" component={ResetPassword} />
          
          <Route exact path="/video" component={video} />
          <Route exact path="/profile" component={Profile} />
          <Route exact path="/search" component={Search} />
          <Route
            exact
            path="/scheduleconfirmation/:id"
            component={ScheduleConfirmation}
          />
          <Route exact path="/updateprofile" component={UpdateProfile} />

          <AuthRoute
            exact
            path="/businessuser-list"
            component={BusinessUserList}
          />
          <AuthRoute
            exact
            path="/VisitList"
            component={VisitList}
          />
         <AuthRoute
            exact
            path="/VisitApptList"
            component={VisitApptList}
          />
          <AuthRoute exact path="/viewprofile" component={ViewProfile} />

          <AuthRoute exact path="/businessuser-profile">
            {" "}
            <BusinessLayout page="businessuserProfile" />{" "}
          </AuthRoute>

          <AuthRoute exact path="/client-profile">
            {" "}
            <BusinessLayout page="clientProfile" />{" "}
          </AuthRoute>

          <AuthRoute
            exact
            path="/familydetails"
            component={ClientFamilyDetails}
          />
          <AuthRoute exact path="/favourites" component={ClientFavourites} />
          <AuthRoute
            exact
            path="/appointments"
            component={ClientAppointments}
          />
          <AuthRoute
            exact
            path="/client_book_appointments"
            component={ClientBookAppointments}
          />
          <AuthRoute
            exact
            path="/shift_planner"
            component={ShiftPlanner}
          />
          <AuthRoute
            exact
            path="/doctorshift_planner"
            component={DoctorShiftPlanner}
          />
          <AuthRoute
            exact
            path="/queue_assignment"
            component={QueueAssignment}
          />
          <AuthRoute
            exact
            path="/ApptCheckout"
            component={ApptCheckout}
          />
           <AuthRoute
            exact
            path="/viewpatientprescription/:id"
            component={ViewPatientPrescription}
          />
          <AuthRoute
            exact
            path="/print_prescription/:id"
            component={PrintPrescription}
          />
           <AuthRoute
            exact
            path="/hospitalprint_prescription/:id"
            component={Hospitalprintprescription}
          />
           <AuthRoute
            exact
            path="/hospitalprint_prescription/:id/:user_type"
            component={Hospitalprintprescription}
          />
          <AuthRoute
            exact
            path="/print_prescription/:id/:user_type"
            component={PrintPrescription}
          />
           <AuthRoute
            exact
            path="/print_apptprescription/:id"
            component={Printapptprescription}
          />
          <AuthRoute
            exact
            path="/print_apptprescription/:id/:user_type"
            component={Printapptprescription}
          />

          <AuthRoute exact path="/clients" component={Clients} />
          <AuthRoute exact path="/disease" component={Diseases} />
          <AuthRoute exact path="/drugs" component={Drugs} />
          <AuthRoute exact path="/adddrugs" component={AddDrugs} />
          <AuthRoute exact path="/editdrug/:id" component={AddDrugs} />
          <AuthRoute exact path="/druglist" component={DrugList} />
          <AuthRoute exact path="/supplierlist" component={SupplierList} />
          <AuthRoute exact path="/storagebin" component={StorageBin} />
          <AuthRoute exact path="/purchaseinfo" component={PurchaseInfo} />
          <AuthRoute exact path="/stockreport" component={StockReport} />
          <AuthRoute exact path="/RequirementList" component={RequirementList} />
          <AuthRoute exact path="/MaterialMovement" component={MaterialMovement} />
          <AuthRoute exact path="/plablist" component={PlabList} />

          <AuthRoute exact path="/InPatientCheckin" component={InPatientCheckin} />
          <AuthRoute exact path="/InpFacilityList" component={InpFacilityList} />
          <AuthRoute exact path="/InpRoomTransfer" component={InpRoomTransfer} />
          <AuthRoute exact path="/InpatientList" component={InpatientList} />
          <AuthRoute exact path="/inpatient/:id" component={InpatientConsultation} />
          <AuthRoute exact path="/InPatientBilling" component={InPatientBilling} />
          <AuthRoute exact path="/AmbulanceManagement" component={AmbulanceManagement} />

          <AuthRoute exact path="/clientcheckin" component={ClientCheckin} />
          <AuthRoute exact path="/facilitylist">
            {" "}
            <BusinessLayout page="facilityList" />{" "}
          </AuthRoute>
          <AuthRoute exact path="/rooms">
            {" "}
            <BusinessLayout page="rooms" />{" "}
          </AuthRoute>
          <AuthRoute exact path="/VisitList">
            {" "}
            <BusinessLayout page="VisitList" />{" "}
          </AuthRoute>
         
          <AuthRoute exact path="/add_prescription" component={AddPrescription} />
          <AuthRoute exact path="/inpatient/:room_id" component={InPatientPrescription} />
          <AuthRoute exact path="/add_prescription/:checkin_id" component={AddPrescription} />
          <AuthRoute exact path="/add_apptprescription" component={Addapptprescription} />
          <AuthRoute exact path="/add_apptprescription/:checkin_id" component={Addapptprescription} />


          <AuthRoute exact path="/pharmacyList">
            {" "}
            <BusinessLayout page="pharmacyList" />{" "}
          </AuthRoute>
          <AuthRoute exact path="/pharmacyProfile">
            {" "}
            <BusinessLayout page="pharmacyProfile" />{" "}
          </AuthRoute>
          <AuthRoute exact path="/labList">
            {" "}
            <BusinessLayout page="labList" />{" "}
          </AuthRoute>
          <AuthRoute exact path="/patient-checkin">
            {" "}
            <BusinessLayout page="patientCheckin" />
          </AuthRoute>

          <AuthRoute exact path="/referral-details">
            {" "}
            <BusinessLayout page="ReferralDetails" />
          </AuthRoute>
          <AuthRoute exact path="/medicinesupplies">
            {" "}
            <BusinessLayout page="MedicineSupplies" />
          </AuthRoute>


          <AuthRoute exact path="/symptom" component={Symptoms} />
          <AuthRoute exact path="/consult/:id" component={Consult} />
          <AuthRoute exact path="/appointment/:id" component={Consult} />
          <AuthRoute exact path="/consult" component={DoctorConsultation} />
          <AuthRoute exact path="/appointment" component={DoctorAppointment} />
          <AuthRoute
            exact
            path="/prescription"
            component={ClientPrescription}
          />
           <AuthRoute
            exact
            path="/clientmedicinescheduling"
            component={ClientMedicineScheduling}
          />
          <AuthRoute
            exact
            path="/consultation"
            component={ClientConsultation}
          />
           <AuthRoute
            exact
            path="/apptprescription"
            component={ClientApptPrescription}
          />
          <AuthRoute
            exact
            path="/apptconsultation"
            component={ClientApptConsultation}
          />
          <AuthRoute
            exact
            path="/consultationsummary"
            component={Consultationsummary}
          />
          <AuthRoute
            exact
            path="/business-prescription"
            component={Prescription}
          />
          <AuthRoute exact path="/referrals" component={Referrals} />
          <AuthRoute exact path="/qr_code" component={QR_Code} />
          <AuthRoute exact path="/showsubscription" component={Showsubscription} />
          <AuthRoute exact path="/showsubscriptionclient" component={ShowSubscriptionClient} />
          <AuthRoute exact path="/supportweb" component={Support} />
          <Route exact path="/promocode" component={Promocode} />
          <Route exact path="/footeradverdisement" component={FooterAdverdisement} />
          <Route exact path="/admindashboard" component={AdminDashboard} />
          <Route exact path="/news_feeds" component={NewsFeeds} />
          <Route exact path="/notification" component={Notification} />
          <Route exact path="/doctorlist" component={DoctorList} />
          <Route exact path="/hospitallist" component={HospitalList} />
          <Route exact path="/patientlist" component={PatientList} />
          <Route exact path="/timeout" component={Timeout} />
          <Route exact path="/servicecharges" component={ServiceCharges} />
          <Route exact path="/doctorcount" component={DoctorCountBySubscription} />
          <Route exact path="/Transfertype" component={Transfertype} />
          <Route exact path="/videoupload" component={Videoupload} />
          <Route exact path="/QueueCompletedReport" component={QueueCompletedReport} />
          <Route exact path="/QueueReport" component={QueueReport} />
          <Route exact path="/AppointmentCompletedReport" component={AppointmentCompletedReport} />
          <Route exact path="/AppointmentReport" component={AppointmentReport} />
          <Route exact path="/subscriptionplan" component={SubscriptionPlan} />
          <Route exact path="/adminpharmacylist" component={AdminPharmacyList} />
          <Route exact path="/drugimport" component={DrugImport} />
          <Route exact path="/symptomimport" component={SymptomImport} />
          <Route exact path="/specialitylist" component={SpecialityList} />
          <Route exact path="/pharmacyProfile" component={PharmacyProfile} />

          <Route exact path="/adminlablist" component={AdminLabList} />
          <Route exact path="/inbox" component={InboxController} />
          <Route exact path="/privacy_policy" component={PrivacyPolicy} />
          <Route exact path="/terms" component={TermsAndCondition} />
          <Route exact path="/terms/faq" component={Faq} />
          <Route exact path="/offers" component={Offers} />
          <Route exact path="/viewoffers" component={ViewOffers} />
          <Route exact path="/help" component={help}/>
          <Route exact path="/feeds/:id" component={Feeds} />
          <Route exact path="/supportnew" component={SupportDefault} />
          <Route exact path="/support" component={SupportNew} />
          <Route exact path="/supportlat" component={SupportLatest} />
          <AuthRoute exact path="/canvas/:id" component={Canvas}/>

          <Route exact path="/prescriptionlist" component={PrescriptionList} />
          <Route exact path="/PrescriptionTemplate" component={PrescriptionTemplate} />
          <Route exact path="/pharmacydruglist" component={PharmacyDruglist} />
          <Route exact path="/pharmacydrugslist" component={PharmacyDrugslist} />
          <Route exact path="/purchaseentryvoucher" component={PurchaseEntryVoucher} />
          <Route exact path="/manufacturerlist" component={Manufacturerlist} />
          <Route exact path="/vendorlist" component={VendorList} />
 {/* Website */}
          <Route exact path="/website" component={About} />
          <Route exact path="/webproduct" component={Webproduct} />
          <Route exact path="/benifits" component={Benifits} />
          <Route exact path="/bookdemo" component={BookDemo} />
          <Route exact path="/simpleslider" component={Simpleslider} />
          <Route exact path="/logslider" component={LogoSlider} />
          <Route exact path="/testhome" component={Home} />
          <Route exact path="/footerweb" component={Footerweb} />
          <Route exact path="/headerweb" component={Headerweb} />
{/* Website */}
          <AuthRoute exact path="/offersweb" component={OffersWeb} />
          <AuthRoute exact path="/faqweb" component={FaqWeb} />
          <AuthRoute exact path="/appointment-checkin">
            {" "}
            <BusinessLayout page="appointmentCheckin" />{" "}
          </AuthRoute>
          <AuthRoute exact path="/join-room/:client_appt_id/:roomName" component={JoinRoom} />
          

          <Route component={NotFound} />
        </Switch>
      </Router>
    </div>
  );
};

export default Routes;
