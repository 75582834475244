import React from 'react';
import { getPrescription,getdigitalsignatureforbusinessuser,getBusinessShortDetail,getClientShortDetail,getCheckinDetails,getwrittenprescriptionbyid } from "../config/url.json";



const PrescriptionTemplate = ({ prescriptionData }) => {
  return (
    <div className="prescription-template">
      <div className="prescription-header">
        <h2>Prescription</h2>
        <p>
          {prescriptionData.hospitalName} <br />
          {prescriptionData.hospitalAddress}
        </p>
      </div>
      <div className="prescription-body">
        <table>
          <thead>
            <tr>
              <th>Medicine</th>
              <th>Days</th>
              <th>Morning</th>
              <th>Noon</th>
              <th>Night</th>
            </tr>
          </thead>
          <tbody>
            {prescriptionData.medicines.map((medicine, index) => (
              <tr key={index}>
                <td>{medicine.name}</td>
                <td>{medicine.dosage}</td>
                <td>{medicine.frequency}</td>
                <td>{medicine.duration}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="prescription-footer">
        <p>
          Date: {prescriptionData.date} <br />
          Doctor's Signature: {prescriptionData.doctorSignature}
        </p>
      </div>
    </div>
  );
};

export default PrescriptionTemplate;