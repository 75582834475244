import axios from "axios";
import {
 
  getCredentials
} from "../config/url.json";
export const imageconfig = {
  bucketName: 'wtmprofileimagesbucket',
  // dirName: props.dirName /* optional */,
  region: 'ap-south-1',
  // accessKeyId: 'AKIAXFMR4BMA6KP4TTN4',
  // secretAccessKey: '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',
};
export const clientmedimageconfig = {
  bucketName: 'wtmprofileimagesbucket',
   dirName: 'client_previous_medicalrecords' ,
  region: 'ap-south-1',
  // accessKeyId: 'AKIAXFMR4BMA6KP4TTN4',
  // secretAccessKey: '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',
};

export const videoconfig = {
  bucketName: 'wtmprofileimagesbucket',
   dirName: 'videos' ,
  region: 'ap-south-1',
  // accessKeyId: 'AKIAXFMR4BMA6KP4TTN4',
  // secretAccessKey: '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',
};

axios
.post(getCredentials, {
  "credential_type":"aws"
})
.then((res) => {
  console.log(res.data);
  imageconfig.accessKeyId = res.data.data[0].value;
  imageconfig.secretAccessKey = res.data.data[1].value;
  videoconfig.accessKeyId = res.data.data[0].value;
  videoconfig.secretAccessKey = res.data.data[1].value;
  clientmedimageconfig.accessKeyId = res.data.data[0].value;
  clientmedimageconfig.secretAccessKey = res.data.data[1].value;

}) 
.catch((e) => { 
  console.log(e)
});
console.log(imageconfig)
console.log(videoconfig)
export const nationalityArray = [
  "Afghan",
  "Albanian",
  "Algerian",
  "American",
  "Andorran",
  "Angolan",
  "Antiguans",
  "Argentinean",
  "Armenian",
  "Australian",
  "Austrian",
  "Azerbaijani",
  "Bahamian",
  "Bahraini",
  "Bangladeshi",
  "Barbadian",
  "Barbudans",
  "Batswana",
  "Belarusian",
  "Belgian",
  "Belizean",
  "Beninese",
  "Bhutanese",
  "Bolivian",
  "Bosnian",
  "Brazilian",
  "British",
  "Bruneian",
  "Bulgarian",
  "Burkinabe",
  "Burmese",
  "Burundian",
  "Cambodian",
  "Cameroonian",
  "Canadian",
  "Cape Verdean",
  "Central African",
  "Chadian",
  "Chilean",
  "Chinese",
  "Colombian",
  "Comoran",
  "Congolese",
  "Costa Rican",
  "Croatian",
  "Cuban",
  "Cypriot",
  "Czech",
  "Danish",
  "Djibouti",
  "Dominican",
  "Dutch",
  "East Timorese",
  "Ecuadorean",
  "Egyptian",
  "Emirian",
  "Equatorial Guinean",
  "Eritrean",
  "Estonian",
  "Ethiopian",
  "Fijian",
  "Filipino",
  "Finnish",
  "French",
  "Gabonese",
  "Gambian",
  "Georgian",
  "German",
  "Ghanaian",
  "Greek",
  "Grenadian",
  "Guatemalan",
  "Guinea-Bissauan",
  "Guinean",
  "Guyanese",
  "Haitian",
  "Herzegovinian",
  "Honduran",
  "Hungarian",
  "I-Kiribati",
  "Icelander",
  "Indian",
  "Indonesian",
  "Iranian",
  "Iraqi",
  "Irish",
  "Israeli",
  "Italian",
  "Ivorian",
  "Jamaican",
  "Japanese",
  "Jordanian",
  "Kazakhstani",
  "Kenyan",
  "Kittian and Nevisian",
  "Kuwaiti",
  "Kyrgyz",
  "Laotian",
  "Latvian",
  "Lebanese",
  "Liberian",
  "Libyan",
  "Liechtensteiner",
  "Lithuanian",
  "Luxembourger",
  "Macedonian",
  "Malagasy",
  "Malawian",
  "Malaysian",
  "Maldivan",
  "Malian",
  "Maltese",
  "Marshallese",
  "Mauritanian",
  "Mauritian",
  "Mexican",
  "Micronesian",
  "Moldovan",
  "Monacan",
  "Mongolian",
  "Moroccan",
  "Mosotho",
  "Motswana",
  "Mozambican",
  "Namibian",
  "Nauruan",
  "Nepalese",
  "New Zealander",
  "Nicaraguan",
  "Nigerian",
  "Nigerien",
  "North Korean",
  "Northern Irish",
  "Norwegian",
  "Omani",
  "Pakistani",
  "Palauan",
  "Panamanian",
  "Papua New Guinean",
  "Paraguayan",
  "Peruvian",
  "Polish",
  "Portuguese",
  "Qatari",
  "Romanian",
  "Russian",
  "Rwandan",
  "Saint Lucian",
  "Salvadoran",
  "Samoan",
  "San Marinese",
  "Sao Tomean",
  "Saudi",
  "Scottish",
  "Senegalese",
  "Serbian",
  "Seychellois",
  "Sierra Leonean",
  "Singaporean",
  "Slovakian",
  "Slovenian",
  "Solomon Islander",
  "Somali",
  "South African",
  "South Korean",
  "Spanish",
  "Sri Lankan",
  "Sudanese",
  "Surinamer",
  "Swazi",
  "Swedish",
  "Swiss",
  "Syrian",
  "Taiwanese",
  "Tajik",
  "Tanzanian",
  "Thai",
  "Togolese",
  "Tongan",
  "Trinidadian or Tobagonian",
  "Tunisian",
  "Turkish",
  "Tuvaluan",
  "Ugandan",
  "Ukrainian",
  "Uruguayan",
  "Uzbekistani",
  "Venezuelan",
  "Vietnamese",
  "Welsh",
  "Yemenite",
  "Zambian",
  "Zimbabwean",
];
export const blood_group = [
  { label: "A+", value: "A+" },
  { label: "A-", value: "A-" },
  { label: "B+", value: "B+" },
  { label: "B-", value: "B-" },
  { label: "AB+", value: "AB+" },
  { label: "AB-", value: "AB-" },
  { label: "O+", value: "O+" },
  { label: "O-", value: "O-" },
];
export const gender = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
  { label: "Other", value: "Other" },
];

export const category = [
  { label: "Hospital", value: "Hospital" },
  { label: "Doctor", value: "Doctor" },
  { label: "Patient", value: "Patient" },
];


export const time_limit = [
  { label: "Daily", value: "Daily" },
  { label: "Weekly", value: "Weekly" },
  { label: "Monthly", value: "Monthly" },
  { label: "Quarterly", value: "Quarterly" },
  { label: "Yearly", value: "Yearly" },
];

export const subscription_type = [
  { label: "Silver", value: "Silver" },
  { label: "Gold", value: "Gold" },
  { label: "Platinum", value: "Platinum" },
];


export const payment_status = [
  { label: "Completed", value: "Completed" },
  { label: "Pending", value: "Pending" },
];

export const supplier_status = [
  { label: "Active", value: "Active" },
  { label: "Not Active", value: "Not Active" },
];

export const language = [
  { label: "English", value: "English" },
  { label: "French", value: "French" },
  { label: "Spanish", value: "Spanish" },
  { label: "Arabic", value: "Arabic" },
  { label: "Mandarin", value: "Mandarin" },
  { label: "Russian", value: "Russian" },
  { label: "Portuguese", value: "Portuguese" },
  { label: "German", value: "German" },
  { label: "Japanese", value: "Japanese" },
  { label: "Hindi", value: "Hindi" },
  { label: "Malay", value: "Malay" },
  { label: "Persian", value: "Persian" },
  { label: "Swahili", value: "Swahili" },
  { label: "Tamil", value: "Tamil" },
  { label: "Telugu", value: "Telugu" },
  { label: "Italian", value: "Italian" },
  { label: "Dutch", value: "Dutch" },
  { label: "Bengali", value: "Bengali" },
  { label: "Turkish", value: "Turkish" },
  { label: "Vietnamese", value: "Vietnamese" },
  { label: "Polish", value: "Polish" },
  { label: "Javanese", value: "Javanese" },
  { label: "Punjabi", value: "Punjabi" },
  { label: "Thai", value: "Thai" },
  {
    label: "Korean",
    value: "Korean",
  },
];
export const relationship = [
  { label: "Father", value: "Father" },
  { label: "Mother", value: "Mother" },
  { label: "Son", value: "Son" },
  { label: "Daughter", value: "Daughter" },
  { label: "Husband", value: "Husband" },
  { label: "Wife", value: "Wife" },
  { label: "Brother", value: "Brother" },
  { label: "Sister", value: "Sister" },
  { label: "Grandfather", value: "Grandfather" },
  { label: "Grandmother", value: "Grandmother" },
  { label: "Grandson", value: "Grandson" },
  { label: "Granddaughter", value: "Granddaughter" },
];
export const employee_status = [
  { label: "Employed", value: "Employed" },
  { label: "Unemployed", value: "Unemployed" },
];
export const marital_status = [
  { label: "Married", value: "Married" },
  { label: "Unmarried", value: "Unmarried" },
];
export const maritial_status = [
  { label: "Married", value: "Married" },
  { label: "Unmarried", value: "Unmarried" },
];
export const designation = [
  { label: "Doctor", value: "doctor" },
  { label: "Staff", value: "Staff" },
  { label: "Nurse", value: "Nurse" },
];
export const CONFIG = {
  headers: {
    // application_id: "c86ae3f5-3a96-49f2-af05-94746357d0ec",
    "Content-Type": "application/json",
  },
};

export const Razor_CONFIG = {
  headers: {
    // application_id: "c86ae3f5-3a96-49f2-af05-94746357d0ec",
    "Content-Type": "application/x-www-form-urlencoded"
  }
}
export const IMAGE_CONFIG = {
  headers: {
    // application_id: "c86ae3f5-3a96-49f2-af05-94746357d0ec",
    // "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "access-control-allow-methods": "GET, POST, PUT, DELETE, OPTIONS",

  },
};
let token = localStorage.getItem("token");

if (token == null) token = localStorage.getItem("token");
export const AUTH_CONFIG = {
  headers: {
    // Authorization: `Bearer ${token}`,
    // application_id: "c86ae3f5-3a96-49f2-af05-94746357d0ec",
    "Content-Type": "application/json",
  },
};

export const card_type = [
  { label: "Visa", value: "Visa" },
  { label: "MasterCard", value: "MasterCard" },
  { label: "American Express", value: "American Express" },
  // { label: "Discover", value: "Discover" },
];

export const MonthsForPicker = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC",
];

export const socialHabitsData = ["Daily", "Weekly", "Monthly", "Never"];

export const medicalHistoryDiseases = {
  group1: ["Asthma", "Alergy", "Hyper tension", "Thyroid", "Diabities"],
  group2: ["Blood pressure", "Back Pain", "Cardiac", "Neck Pain", "Cold"],
  group3: ["Fever", "Corona", "Dengu", "Flu", "Typhoid"],
  group4: ["Cancer", "Obesity", "Pregnancy", "Infection", "Skin Alergy"],
};

export const referral_categories = [
  { label: "Business", value: "Business" },
  { label: "Professional", value: "Professional" },
  { label: "Client", value: "Client" },
];
export const support_categories = [
  { label: "Hospital", value: "Hospital" },
  { label: "Doctor", value: "Doctor" },
  { label: "Patient", value: "Patient" },
];

export const hospitaltimeout = 600000;
export const doctortimeout = 600000;
export const patienttimeout = 60000;

export const issue_description = [
  { label: "Password locked", value: "Password locked" },
  { label: "Queue not working", value: "Queue not working" },
  { label: "Not able to sign up", value: "Not able to sign up" },
  { label: "Others", value: "Others" },
];

export const limit_times_to = [
  { label: "All Available", value: "available" },
  { label: "All Slot", value: "all" },
];

export const vendor_category = [
  { label: "Clinic", value: "Clinic" },
  { label: "Pharmacy", value: "Pharmacy" },
]

export const storagecondition = [
  { value: 0, label: "Below 0°C" },
  { value: 1, label: "2°C-8°C" },
  { value: 2, label: "20°C-25°C" },
  { value: 3, label: "Below 30°C" },
  { value: 4, label: "Room Temperature" },
];


export const categorylist = [
  { value: 0, label: "Medicines" },
  { value: 1, label: "Surgical Items" },
  { value: 2, label: "Consumables" },
  { value: 3, label: "Personal Care" },
  { value: 4, label: "Health & Fitness" },
  { value: 5, label: "Homeopathy Care" },
  { value: 6, label: "Elderly Care" },
  { value: 7, label: "Healthcare Devices" },
  { value: 8, label: "Health Food & Drinks" },
  { value: 9, label: "Skin Care" },
  { value: 10, label: "Mother & Baby Care" },
  { value: 11, label: "Healthcare Accessories" },
  { value: 12, label: "Ayurvedic Care" },
  { value: 13, label: "Sexual Wellness" },
  { value: 14, label: "Room Temperature" },
  { value: 15, label: "Home Care" },
  { value: 16, label: "Food & Beverages" },
  { value: 17, label: "Pet Supplies" },
  { value: 18, label: "Ortho Belts & Supports" },
  
];


















