import React, { Component } from 'react';
import PrescriptionTemplate from './PrescriptionTemplate';
import axios from "axios";
import { CONFIG } from "../config/data";
import { getPrescription,getdigitalsignatureforbusinessuser,getBusinessShortDetail,getClientShortDetail,getCheckinDetails,getwrittenprescriptionbyid } from "../config/url.json";
import FooterSection from "../components/UI/FooterSection";
import * as moment from "moment";
class PrintPrescriptionnew extends Component {
  // ...
  handlePrint = () => {
    window.print();
  };
  componentDidMount() {
  
    const postData1 = { id: this.props.match.params.id };
    axios
      .post(getPrescription, postData1, CONFIG)
      .then((res) => {
        let data = res.data.consultantHistory || res.data.consultantHistory;
        console.log(data);
        console.log(res.data);
        // if (res.data.status !== "1" || !data || !data[0]) {
        //   return;
        // }
        console.log(res.data.status);
        if (res.data.status === "1") {
          console.log(res.data.data[0]);
          this.setState({
            // with_in_week: data.with_in_week,
            // with_in_30: data.with_in_30,
            // with_in_3_months: data.with_in_3_months,
            // with_in_6_months: data.with_in_6_months,
            // with_in_year: data.with_in_year,
            // with_in_2_years: data.with_in_2_years,
            records: (res.data.data[0] && null != res.data.data[0].medicine_description)
              ? res.data.data[0].medicine_description
              : [],client_id:res.data.data[0].client_id,business_id:res.data.data[0].business_id,
              pharmacy_location: res.data.data[0].pharmacy_location
                         
          });
        }
})
  }

  render() {
    const { records, headerInfo, pharmacy_location } = this.state;

    return (
      <div id="layout-wrapper">
        <PrescriptionTemplate
          prescriptionData={{
            logo: headerInfo.logo, // pass the logo URL as a prop
            hospitalName: headerInfo.businessName,
            hospitalAddress: headerInfo.businessAddress,
            medicines: records,
            date: moment(new Date()).format('DD MMM YYYY hh:mm A'),
            doctorSignature: headerInfo.doctorSignature,
          }}
        />
         <button onClick={this.handlePrint}>Print Prescription</button>
        <FooterSection />
      </div>
    );
  }
}
export default PrintPrescriptionnew;