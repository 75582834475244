import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import FooterSection from "../components/UI/FooterSection";
import ReactDatatable from "@ashvin27/react-datatable";
import BusinessUserSideBar from "../components/container/SideBar";
import Header from "../components/container/layout/pharmacy/Header";
import CheckboxOrRadioGroup from "../components/UI/CheckboxOrRadioGroup";
import InputField from "../components/UI/InputField";
import axios from "axios";
import { Popup } from "../components/UI/Popup";
import { Spinner } from "../components/UI/Spinner";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Label from "../components/UI/Label";
import {
  getAllMasterDrug,
  getAllPharmacyDrugs,
  getAllPharmacyItemList,
  addPharmacyDrugPlanning,
  updatePharmacyDrugPlanning,
  getAllDrugs,
  getAllDrugTypes,
  getAllDrugCategories,
  addDrug,
  updateDrug,
  deleteDrug,
  searchDrug,
  getAllDrugMeasures,
  getTimeoutbycategory,
  hospitaldrugimport,
  getAllActiveingredientsforPharmacyDrugs,
  getAllgenericnameforPharmacyactiveingredients,
} from "../config/url.json";
import { CONFIG,categorylist } from "../config/data";

import Select from "react-select";
import Validation from "../actions/Validation";
import SpanField from "../components/UI/SpanField";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
// Import React Table
import "react-table/react-table.css";
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModal } from '../components/container/modal/IdleModal';
import ExpirePopup from "../components/UI/ExpirePopup"
import Tooltip from '@mui/material/Tooltip';
import DrugsExport from "./DrugsExport";
import DrugTemplate from "./DrugTemplate";
import LoadingSpinner from "../components/UI/LoadingSpinner";
import LoadingHeartrate from "../components/UI/Heartrate";
import readXlsxFile from 'read-excel-file';
import { Popupinfo } from "../components/UI/Popupinfo";
import Dnaspinner from "../components/UI/Dnaspinner";


function mapStateToProps(state) {
  return {
    appdata: state.AppData,
    userdata: state.AuthReducer.user,
    access_token: state.AuthReducer.access_token,
    validity: state.ValidityReducer.validity
  };
}
function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch({ type: "SET_LOGOUT" }),
  };
}
const drug_status = ["Active", "Inactive"];


class PharmacyDruglist extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = React.createRef()
    const hospitaltimeout = this.state;

    this.state = {
      adminstatus: this.props.userdata.adminstatus,
      showexpirePopup: this.props.validity,
      timeout: hospitaltimeout,
      isTimedOut: false,
      showModal: false,

      records: [],

      searchrecords: [],
      drug_action: "Add",
      loading: false,
      addDrugSuccess: "",
      showDeleteConfirmPopup: false,
      confirmedDelete: false,
      search_action: false,
      recordToDelete: [],
      drugTypes: [],
      drugCategories: [],
      masterdrugNames: [],
      drugList: [],
      ActiveIngredientList:[],
      GenericNameList:[],
      newDatatoAdd: {},
      addPopup: false,
      formSuccess: {
        message: "",
        status: "",
    },

      drugList: {
        s_no: "",
        drug_id: "",
        drug_name: "",
        drug_category: "",
        drug_type: "",
        manufacturer: "",
        active_ingredients: "",
        drug_type:"",
      },
      pharmacydrugData: {
        id: "",
        drug_id: "",
        drug_name: "",
        generic_name:"",
        active_ingredients: "",
        manufacturer: "",
        hsn_code:"",
        igst:"",
        sgst:"",
        cgst:"",
        unit_of_measure: "",
        conversion_factor:"",
        category:"",
        storage_bin:"",
        storage_condition:"",
        rx_category:"",
        minimum_quantity:"",
        maximum_quantity:"",
        reorder_quantity:"",
       drug_status:"Active",
       disable_drug_name:true,
      },
       errors: {
        drug_id: "",
        drug_name: "",
        generic_name:"",
        active_ingredients: "",
        manufacturer: "",
        hsn_code:"",
        igst:"",
        sgst:"",
        cgst:"",
        unit_of_measure: "",
        conversion_factor:"",
        category:"",
        storage_bin:"",
        storage_condition:"",
        rx_category:"",
        minimum_quantity:"",
        maximum_quantity:"",
        reorder_quantity:"",
       drug_status:"",
      },
      searchData: {
        drug_name: "",
        drug_category: "",
        drug_type: "",
        generic_name:"",
        manufacturer: "",
        active_ingredients: "",
      },
      tableSuccess: {
        message: "",
        status: "",
      },
      formSuccess: {
        message: "",
        status: "",
      },
      editformSuccess: {
        message: "",
        status: "",
      },
      searchTableSuccess: {
        message: "",
        status: "",
      },
      columns: [
        { title: "Drug Id", field: "drug_id" },
        { title: "Drug Name", field: "drug_name" },
        { title: "Drug Category", field: "drug_category" },
        { title: "Drug Type", field: "drug_type" },
        { title: "Manufacturer", field: "manufacturer" },
        { title: "Active Ingredients", field: "active_ingredients" },
      ],
      data: [
        {
          drug_id: "1",
          drug_name: "da",
          drug_category: "ds",
          drug_type: "sdd",
          manufacturer: "sdsd",
          active_ingredients: "adsfd",
        },
      ],
      searchErrorMessage: "",
    };
    this.columns = [
      {
        key: "s_no",
        text: "S.No",
        className: "s_no",
        align: "left",
        sortable: true,
        width: 10,
      },
      {
        key: "drug_id",
        text: "Drug ID",
        className: "drug_id",
        align: "left",
        sortable: true,
        width: 100,
      },
      {
        key: "drug_name",
        text: "Drug Name",
        className: "drug_name",
        align: "left",
        sortable: true,
        width: 100,
      },
      {
        key: "generic_name",
        text: "Generic Name",
        className: "generic_name",
        sortable: true,
        align: "left",
        width: 100,

      
      },
      {
        key: "active_ingredients",
        text: "Active Incredients",
        className: "active_ingredients",
        sortable: true,
        align: "left",
        width: 100,
      },
      /*       {
              key: "drug_category",
              text: "Drug Category",
              className: "drug_category",
              sortable: true,
              align: "left",
              width: 100,
            },
      
            {
              key: "drug_type",
              text: "Drug Type",
              className: "drug_type",
              sortable: true,
              align: "left",
              width: 100,
            },
       */
            {
                key: "unit_of_measure",
                text: "Unit of Measure",
                className: "unit_of_measure",
                sortable: true,
                align: "left",
                width: 100,
              },
      {
        key: "manufacturer",
        text: "Manufacturer",
        className: "manufacturer",
        sortable: true,
        align: "left",
        width: 100,
      },
           
      {
        key: "action",
        text: "Action",
        className: "action",
        width: 100,
        align: "left",
        sortable: false,
        cell: (record) => {
          return (
            <Fragment>
                 <Tooltip title="Edit">
              <button
                className="btn btn-primary btn-sm"
                onClick={() => this.editRecord(record)}
                style={{ marginRight: "5px" }}
              >
                <i className="fa fa-edit"></i>
              </button>
              </Tooltip>
              <Tooltip title="Delete">
              <button
                className="btn btn-danger btn-sm"
                onClick={() => this.onDeleteClick(record)}
              >
                <i className="fa fa-trash"></i>
              </button>
              </Tooltip>
            </Fragment>
          );
        },
      },
    ];
    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50],
      button: {
        excel: false,
        print: false,
      },
    };
    this.searchconfig = {
      page_size: 10,
      length_menu: [10, 20, 50],
      button: {
        excel: false,
        print: false,
      },
    };

    this.searchcolumns = [
      // {
      //   key: "s_no",
      //   text: "S.No",
      //   className: "s_no",
      //   align: "left",
      //   sortable: true,
      // },
      {
        key: "drug_id",
        text: "Drug ID",
        className: "drug_id",
        align: "left",
        sortable: true,
        width: 10,
        cell: (record) => {
          // let obj = {
          //   label: record.availability,
          //   value: "Yes" === record.availability ? 1 : 0,
          // };
          return (
            <Fragment>
              <InputField
                inputType={"text"}
                isMandatory={true}
                name={"searchdrug_id"}
                controlFunc={this.handleInputsearch.bind(this, record)}
                content={record.searchdrug_id}
                placeholder={""}
                max={5}
              />
              <SpanField classname="text-danger" title={record.drugid_error} />
            </Fragment>
          );
        },
      },
      {
        key: "drug_name_hide",
        text: "drug_name_hide",
        className: "drug_name hide",
        TrOnlyClassName: "hide",
        align: "left",
        sortable: true,
        width: 100,
      },
    /*   {
        key: "drug_category_hide",
        text: "drug_category_hide",
        className: "drug_category_hide hide",
        TrOnlyClassName: "hide",
        align: "left",
        sortable: true,
        width: 100,
      },
      {
        key: "drug_type_hide",
        text: "drug_type_hide",
        className: "drug_type_hide hide",
        TrOnlyClassName: "hide",
        align: "left",
        sortable: true,
        width: 100,
      },
     */  {
        key: "unit_of_measure_hide",
        text: "unit_of_measure_hide",
        className: "unit_of_measure_hide hide",
        TrOnlyClassName: "hide",
        align: "left",
        sortable: true,
        width: 100,
      },
      {
        key: "drug_name",
        text: "Drug Name",
        className: "drug_name",
        align: "left",
        sortable: true,
        width: 100,

        // cell: (record) =>
        //  {
        //   return (
        //     <Fragment>

        /* <InputField
          inputType={"text"}
           isMandatory={true}
            name={"drug_name"}
            controlFunc={this.handleInput.bind(this)}
            content={record.drug_name.label}
             placeholder={""}
             max={50}
             />
              */
        //       <SpanField
        //         classname="text-danger"
        //         title={record.drugname_error}
        //       />
        //     </Fragment>
        //   );
        // },
      },
      {
        key: "generic_name",
        text: "Generic Name",
        className: "generic_name",
        sortable: true,
        align: "left",
        width: 100,

      
      },
      {
        key: "unit_of_measure",
        text: "Unit of Measure",
        className: "unit_of_measure",
        align: "left",
        sortable: true,
        width: 100,

        // cell: (record) => {
        //   return (
        //     <Fragment>
           
        //       <InputField
        //         inputType={"text"}
        //         isMandatory={true}
        //         name={"unit_of_measure"}
        //         controlFunc={this.handleInputsearch.bind(this, record)}
        //         content={record.unit_of_measure ? record.unit_of_measure.label : ""}
        //         placeholder={""}
        //         max={50}
        //       />

        //       <SpanField
        //         classname="text-danger"
        //         title={record.measure_error}
        //       />
        //     </Fragment>
        //   );
        // },

      },

    /*   {
        key: "drug_category",
        text: "Drug Category",
        className: "drug_category",
        sortable: true,
        align: "left",
        width: 100,

      },

      {
        key: "drug_type",
        text: "Drug Type",
        className: "drug_type",
        sortable: true,
        align: "left",
        width: 100,

      
      },
 */
      {
        key: "manufacturer",
        text: "Manufacturer",
        className: "manufacturer",
        sortable: true,
        align: "left",
        width: 20,

        // cell: (record) => {
        // let obj = {
        //   label: record.availability,
        //   value: "Yes" === record.availability ? 1 : 0,
        // };
        //   return (
        //     <InputField
        //       inputType={"text"}
        //       isMandatory={true}
        //       name={"manufacturer"}
        //       controlFunc={this.handleInput.bind(this)}
        //       content={record.manufacturer}
        //       placeholder={""}
        //     />
        //   );
        // },
      },

      {
        key: "active_ingredients",
        text: "Active Incredients",
        className: "active_ingredients",
        sortable: true,
        align: "left",
        width: 20,

        // cell: (record) => {
        // let obj = {
        //   label: record.availability,
        //   value: "Yes" === record.availability ? 1 : 0,
        // };
        //   return (
        //     <InputField
        //       inputType={"text"}
        //       isMandatory={true}
        //       name={"active_ingredients"}
        //       controlFunc={this.handleInput.bind(this)}
        //       content={record.active_ingredients}
        //       placeholder={""}
        //     />
        //   );
        // },
      },

      {
        key: "action",
        text: "Action",
        className: "action",
        width: 20,
        align: "left",
        sortable: false,
        cell: (record) => {
          return (
            <Fragment>
              <button
                className="btn btn-primary btn-sm"
                onClick={() => this.addRecord(record)}
                style={{ marginRight: "5px" }}
              >
                <i className="fa fa-plus"></i>
              </button>
              {/* <button
                className="btn btn-danger btn-sm"
                onClick={() => this.deleteRecord(record)}
              >
                <i className="fa fa-trash"></i>
              </button> */}
            </Fragment>
          );
        },
      },
    ];
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleLogout = this.handleLogout.bind(this)

  }
  handleClose() {
    this.setState({ showModal: false })
  }


  handleLogout = (e) => {
    this.setState({ showModal: false })
    e.preventDefault();
    localStorage.removeItem("token");
    this.props.logout();

  };

  _onAction(e) {
    console.log('user did something', e)

    this.setState({ isTimedOut: false })
  }

  _onActive(e) {

    console.log('user is active', e)
    this.setState({ isTimedOut: false })
  }

  _onIdle = (e) => {
    console.log('user is idle', e)
    let isTimedOut = this.state.isTimedOut
    let timeout = this.state.timeout;
    console.log(timeout);
    console.log(isTimedOut);
    if (isTimedOut===false) 
    {
     // console.log("the time is over");
      this.setState({showModal: true})
      console.log(this.state.showModal);
       this.idleTimer.reset();
       this.setState({isTimedOut: true})

    } 
  if(isTimedOut===true)
  {
    localStorage.removeItem("token");
    this.props.logout();
    
  }
  }

  handleCategoryColumnChange = (value, record, field) => {
    // console.log(field);
    // console.log(value);
    // console.log(record);
    value[field] = record;
    // console.log(record);
    // record.availability = "Yes" === record.availability ? "No" : "Yes";
    this.UpdateColumnChange(value);
  };
  handledrugstatus = (e) => {
    let data = this.state.pharmacydrugData;
        data.drug_status = [e.target.value];
        this.setState({ pharmacydrugData: data });
  };

  handlestorageconditionChange=(selectedOptions, e)=>{
    let data = this.state.pharmacydrugData;
    let errors = this.state.errors;
    errors[e.name] = ""
    data[e.name] = selectedOptions;
    
    this.setState({ pharmacydrugData: data, errors,searchErrorMessage: "" });
  }
  handlecategoryChange=(selectedOptions, e)=>{
    let data = this.state.pharmacydrugData;
    let errors = this.state.errors;
    errors[e.name] = ""
    data[e.name] = selectedOptions;
    console.log(selectedOptions.label);
    console.log(data[e.name]);
    
    if(selectedOptions.label=="Medicines")
    {
        this.setState({disable_drug_name:true})
    }
    if(selectedOptions.label!=="Medicines")
        {
            this.setState({disable_drug_name:false})
        }
    
    this.setState({ pharmacydrugData: data, errors,searchErrorMessage: "" });
  }
  handleInputsearch = (record, event) => {
    record[event.target.name] = event.target.value;
    let max = event.target.max;
    let value=event.target.value;
     if (
       event.target.name === "searchdrug_id"
 
     ) {
       if (value.length > max) {
         const value1 = value.slice(0, max);
         record.drugid_error  = "Maximum Length has exceeded.";
         record[event.target.name] = value1;
       }
      else {
       record.drugid_error  = "";
       record[event.target.name] = event.target.value;
     }
   }
    this.UpdateColumnChange(record);
  };
  UpdateColumnChange = (record) => {
    let records = this.state.searchrecords;
    var recIndex = findWithAttr(records, "id", record.id);
    records[recIndex].drug_id = record.drug_id;
    records[recIndex].drug_name = record.drug_name;
    records[recIndex].drug_category = record.drug_category;
    records[recIndex].drug_type = record.drug_type;

    this.setState({ searchrecords: records });
  };
  componentDidMount = () => {
    console.log(this.props.userdata.user_type);
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;

    axios
      .post(getTimeoutbycategory, { category: this.props.userdata.user_type }, CONFIG)
      .then((res) => {
        let data = res.data;
        console.log(res);
        console.log(data);
        console.log(data.data.timeout);
        if (data.status === "1")
          this.setState({
            hospitaltimeout: data.data.timeout

          });
      })
      .catch(() => { });


    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
    .post(getAllPharmacyDrugs, { user_id: this.props.userdata.id }, CONFIG)
    .then(({ data }) => {
      const masterdrugNameList = data.data;
      const uniqueDrugNames = new Set();
      const masterdrugNames = masterdrugNameList
        .filter((element) => {
          
          if (!uniqueDrugNames.has(element.drug_name)) {
            uniqueDrugNames.add(element.drug_name); 
            return true; 
          }
          return false; 
        })
        .map((element) => ({
          value: element.id,
          label: element.drug_name,
          manufacturer: "", 
          active_incredient: "", 
        }));
  
      this.setState({ masterdrugNames });
    })
    .catch((error) => {
      console.error("Error fetching drug data:", error);
     
    });

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
      .post(getAllDrugTypes, { user_id: this.props.userdata.id }, CONFIG)
      .then((drugTypeResponse) => {
        let drugTypeList = drugTypeResponse.data.data;
        let drugTypes = [];
        drugTypeList.forEach((element) => {
          drugTypes.push({ value: element.id, label: element.drug_type });
        });

        this.setState({ drugTypes });
      })
      .catch(() => { });

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
      .post(getAllDrugCategories, { user_id: this.props.userdata.id }, CONFIG)
      .then((drugCategoryResponse) => {
        let list = drugCategoryResponse.data.data;

        let drugCategories = [];
        list.forEach((element) => {
          drugCategories.push({
            value: element.id,
            label: element.drug_category,
          });
        });
        this.setState({ drugCategories });
      })
      .catch(() => { });

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
      .post(getAllPharmacyItemList  , { user_id: this.props.userdata.id }, CONFIG)
      .then((list) => {
        let drugList = list.data.data;
        let drugDataArr = [];
        drugList.forEach((element, index) => {
          let drugData = {};
          drugData.s_no = index + 1;
          drugData.id = element.id;
          drugData.user_id = this.props.userdata.id;
          drugData.drug_id = element.drug_id;
          drugData.drug_name = element.drug_name;
          drugData.manufacturer = element.manufacturer;
          drugData.active_ingredients = element.active_ingredients;
          drugData.unit_of_measure = element.unit_of_measure;
          drugData.generic_name=element.generic_name;
          drugData.hsn_code=element.hsn_code;
          drugData.igst=element.igst;
          drugData.sgst=element.sgst;
          drugData.cgst=element.cgst;
          drugData.conversion_factor=element.conversion_factor;
          drugData.category=element.category;
          drugData.storage_bin=element.storage_bin;
          drugData.storage_condition=element.storage_condition;
          drugData.rx_category=element.rx_category;
          drugData.minimum_quantity=element.minimum_quantity;
          drugData.maximum_quantity=element.maximum_quantity;
          drugData.reorder_quantity=element.reorder_quantity;
          drugDataArr[index] = drugData;
        });

        this.setState({ records: drugDataArr });
      })
      .catch(() => { });


    axios
      .post(getAllDrugMeasures, "", CONFIG)
      .then((drugMasterRespons) => {
        let masterdrugmeasureList = drugMasterRespons.data.data;
        console.log(masterdrugmeasureList);
        let masterdrugmeasure = [];
        masterdrugmeasureList.forEach((element) => {
          masterdrugmeasure.push({
            value: element.unit_of_measure,
            label: element.unit_of_measure,

          });
        });

        this.setState({ masterdrugmeasure });
      })
      .catch(() => { });

      axios
      .post(getAllMasterDrug, "", CONFIG)
      .then((drugMasterRespons) => {
        let masterdrugList = drugMasterRespons.data.data;
        const uniqueDrugNames = new Set();
      const mastermanufaturerNames = masterdrugList
        .filter((element) => {
          
          if (!uniqueDrugNames.has(element.manufacturer)) {
            uniqueDrugNames.add(element.manufacturer); 
            return true; 
          }
          return false; 
        })
        .map((element) => ({
            value: element.manufacturer,
            label: element.manufacturer,

        }));
       

        this.setState({ mastermanufaturerNames });
      })
      .catch(() => { });
  };
  addRecord = (record) => {
    let formvalid = true;
    if (record.searchdrug_id === "") {
      record.drugid_error = "Please enter drug id";
      formvalid = false;
    } else {
      record.drugid_error = "";
    }
    if (record.drug_name.label === "") {
      formvalid = false;
      record.drugname_error = "Please enter drug name";
    } else {
      record.drugname_error = "";
    }
    if(record.generic_name==="")
    {
      formvalid = false;
      record.generic_name_error = "Please enter generic name";
    } else {
      record.generic_name_error = "";
    }
    if (record.drug_category && record.drug_category.label === "") {
      formvalid = false;
      record.drugcategory_error = "Please enter drug category";
    } else {
      record.drugcategory_error = "";
    }
    if (record.drug_type && record.drug_type.label === "") {
      record.drugtype_error = "Please enter drug type";
      formvalid = false;
    } else {
      record.drugtype_error = "";
    }
    let records = this.state.searchrecords;
    var recIndex = findWithAttr(records, "id", record.id);
    records[recIndex] = record;
    this.setState({ searchrecords: records });
    if (formvalid) {
      this.setState({ drug_action: "Add" });
    //  this.setState({ newDatatoAdd: record, loading: true });
      // this.addorupdateDrug();

      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.props.access_token}`;

      let selectedData = record;
      // console.log(selectedData); return false;
      let data = {};
      data.user_id = this.props.userdata.id;
      data.drug_id = selectedData.searchdrug_id;
      data.drug_name = selectedData.drug_name;
      data.drug_type = selectedData.drug_type;
      data.drug_category = selectedData.drug_category;
      data.unit_of_measure = selectedData.unit_of_measure;
      data.manufacturer = selectedData.manufacturer;
      data.active_ingredients = selectedData.active_ingredients;
      data.generic_name=selectedData.generic_name;
      axios
        .post(addDrug, data, CONFIG)
        .then((addDrugResponse) => {
          let res = addDrugResponse.data;
          console.log(addDrugResponse)
          if (res.status === "1") {
            let drug = {};
            let records = this.state.records;
            drug.s_no = this.state.records.length + 1;
            drug.drug_id = data.drug_id;
            drug.id = res.data[0].id;
            drug.drug_name = data.drug_name;
            drug.drug_category = data.drug_category;
            drug.unit_of_measure = data.unit_of_measure;
            drug.manufacturer = data.manufacturer;
            drug.drug_type = data.drug_type;
            drug.active_ingredients = data.active_ingredients;
            drug.generic_name=data.generic_name;
            records.push(drug);
            // this.setState({ searchrecords: records });
            this.setState({
              records: records,
              searchTableSuccess: res,
              loading: false,
            });
            toast.success("The Drug details have been added successfully. ", {
              className: "toast-success"
            });
            this.clearMessage();
            this.clearAll();
          } else {
            this.setState({
              searchrecords: records,
              searchTableSuccess: res,
              loading: false,
            });
            toast.error("Drug could not be Added. ", {
              className: "toast-error"
            });
            this.clearMessage();
          }
        })
        .catch(() => { });
    }
  };
  editRecord(record) {
    let drug_action = this.state.drug_action;
    drug_action = "Edit";
    this.setState({ drug_action });

    let pharmacydrugData = this.state.pharmacydrugData;
    pharmacydrugData.s_no = record.s_no;
    pharmacydrugData.id = record.id;
    pharmacydrugData.drug_id = record.drug_id;
   // pharmacydrugData.drug_name = record.drug_name;
    pharmacydrugData.manufacturer = record.manufacturer;
    pharmacydrugData.drug_name = {
        label: record.drug_name,
        value: record.drug_name,
      };
    pharmacydrugData.manufacturer = {
        label: record.manufacturer,
        value: record.manufacturer,
      };
    pharmacydrugData.unit_of_measure = {
      label: record.unit_of_measure,
      value: record.unit_of_measure,
    };
    pharmacydrugData.active_ingredients = {
        label: record.active_ingredients,
        value: record.active_ingredients,
      };
    //pharmacydrugData.active_ingredients = record.active_ingredients;
    pharmacydrugData.generic_name=record.generic_name;
    pharmacydrugData.hsn_code=record.hsn_code;
    pharmacydrugData.igst=record.igst;
    pharmacydrugData.sgst=record.sgst;
    pharmacydrugData.cgst=record.cgst;
    pharmacydrugData.conversion_factor=record.conversion_factor;
  //  pharmacydrugData.category=record.category;
    pharmacydrugData.category = {
        label: record.category,
        value: record.category,
      };
    pharmacydrugData.storage_bin=record.storage_bin;
    pharmacydrugData.storage_condition = {
        label: record.storage_condition,
        value: record.storage_condition,
      };
   // pharmacydrugData.storage_condition=record.storage_condition;
    pharmacydrugData.rx_category = {
        label: record.rx_category,
        value: record.rx_category,
      };
   // pharmacydrugData.rx_category=record.rx_category;
    pharmacydrugData.minimum_quantity=record.minimum_quantity;
    pharmacydrugData.maximum_quantity=record.maximum_quantity;
    pharmacydrugData.reorder_quantity=record.reorder_quantity;
    this.setState({ pharmacydrugData: pharmacydrugData });
  }
  clearMessage = () => {
    setTimeout(
      () =>
        this.setState({
          formSuccess: { message: "" },
          tableSuccess: { message: "" },
          errorMessage:"",
        }),
      5000
    );
  };

  cancel = () => {
   
    let data = this.state.pharmacydrugData;
    data.drug_id = "";
    data.drug_name = "";
    data.user_id = "";
    data.generic_name="";
    data.active_ingredients= "";
    data.manufacturer= "";
    data.hsn_code="";
    data.igst="";
    data.sgst="";
    data.cgst="";
    data.unit_of_measure="";
    data.conversion_factor="";
    data.category="";
    data.storage_bin="";
    data.storage_condition="";
    data.rx_category="";
    data.minimum_quantity="";
    data.maximum_quantity="";
    data.reorder_quantity="";
   
    let errors = this.state.errors;

    errors = this.clearErrors(errors);

    this.setState({ pharmacydrugData: data,loading:false, actions: "add", errors: errors,errorMessage:"",file:"" });
  };
  clearErrors = (errors) => {
    errors.drug_id = "";
    errors.drug_name = "";
    errors.user_id = "";
    errors.generic_name="";
    errors.active_ingredients= "";
    errors.manufacturer= "";
    errors.hsn_code="";
    errors.igst="";
    errors.sgst="";
    errors.cgst="";
    errors.unit_of_measure="";
    errors.conversion_factor="";
    errors.category="";
    errors.storage_bin="";
    errors.storage_condition="";
    errors.rx_category="";
    errors.minimum_quantity="";
    errors.maximum_quantity="";
    errors.reorder_quantity="";
    return errors;
  };
  handleselectChange = (selectedOptions, e) => {
    let data = this.state.pharmacydrugData;
    let errors = this.state.errors;
    errors[e.name] = ""
    data[e.name] = selectedOptions;
    
    this.setState({ pharmacydrugData: data, errors,searchErrorMessage: "" });

    axios
    .post(getAllActiveingredientsforPharmacyDrugs,{user_id:this.props.userdata.id,drug_name:selectedOptions.label}, CONFIG)
    .then((ActiveIngredientdrugRespons) => {
      let ActiveIngredientNameList = ActiveIngredientdrugRespons.data.data;
      let ActiveIngredientList = [];
      ActiveIngredientNameList.forEach((element, index) => {
        ActiveIngredientList.push({
          value: element.id,
          label: element.active_ingredients,
          manufacturer: ""
          
        });
      });

      this.setState({ ActiveIngredientList });
    }).catch((e) => { });
  };

  handleactiveingredientsSelect= (activeselectedOptions, e) => {
    let data = this.state.pharmacydrugData;
    data[e.name] = activeselectedOptions;
    this.setState({ pharmacydrugData: data });
 
         
   
      axios
      .post(getAllgenericnameforPharmacyactiveingredients,{user_id:this.props.userdata.id,active_ingredients:activeselectedOptions.label}, CONFIG)
      .then((GenericNameRespons) => {
        let GenericNameFullList = GenericNameRespons.data.data;
        let GenericNameList = [];
        GenericNameFullList.forEach((element, index) => {
          GenericNameList.push({
            value: element.id,
            label: element.generic_name,
            manufacturer: ""
            
          });
        });

        this.setState({ GenericNameList });
      
      }).catch((e) => { });
    }  

    handleSearchGenericNameselect= (selectedOptions, e) => {
        let data = this.state.pharmacydrugData;
        data[e.name] = selectedOptions;
        this.setState({ pharmacydrugData: data });
    
        
      };

      handlemanufacturerselect= (selectedOptions, e) => {
        let data = this.state.pharmacydrugData;
        data[e.name] = selectedOptions;
        this.setState({ pharmacydrugData: data });
    
        
      };

  handlesearchinputChange = (eve) => {
    let data = this.state.searchData;

    data[eve.target.name] = eve.target.value;
    // }
    this.setState({ searchData: data });
  };
  handlesearchselectChange = (selectedOptions, e) => {
    let data = this.state.searchData;
   
    data[e.name] = selectedOptions;
    // }
    this.setState({ searchData: data });
  };
  validateMaxLength(name, value, max) {
    let data = this.state.pharmacydrugData;
    let errors = this.state.errors;

    if (value.length > max) {
      const value1 = value.slice(0, max);
      errors[name] = "Maximum Length has exceeded.";
      data[name] = value1;
    } else {
      errors[name] = "";
      data[name] = value;
    }
    this.setState({ pharmacydrugData: data, errors: errors });
  }
  handleInput = (e) => {
    let data = this.state.pharmacydrugData;
    let errors = this.state.errors;
    let name = e.target.name;
    let value = e.target.value;
    let max = e.target.max;
    if (
      name === "drug_id" ||
      name === "drug_name" ||
      name === "manufacturer" ||
      name === "active_ingredients"||
      name === "generic_name"

    ) {
      this.validateMaxLength(name, value, max);
    } else {
      data[name] = value;
      errors[name] = ""
    }
    this.setState({ pharmacydrugData: data, errors,searchErrorMessage: "" });
  };

  addorupdateDrug() {
   // let url = this.state.drug_action === "Add" ? addDrug : updateDrug;
  /*   axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
 */

    let { pharmacydrugData, errors } = this.state;
    pharmacydrugData.type = "item";
    errors = Validation(pharmacydrugData, errors);
    this.setState({ errors: errors });

    if (errors.formIsValid) {
      this.setState({ loading: true });

      let data = {};
    
      data.user_id = this.props.userdata.id;
      data.id=pharmacydrugData.id;
      data.drug_status = pharmacydrugData.drug_status;
      data.drug_id = pharmacydrugData.drug_id;
      data.drug_name = pharmacydrugData.drug_name.label;
      data.generic_name=pharmacydrugData.generic_name;
      data.active_ingredients = pharmacydrugData.active_ingredients.label;
      data.manufacturer = pharmacydrugData.manufacturer.label;
      data.hsn_code=pharmacydrugData.hsn_code;
      data.igst=pharmacydrugData.igst;
      data.sgst=pharmacydrugData.sgst;
      data.cgst=pharmacydrugData.cgst;
      data.storage_bin = pharmacydrugData.storage_bin;
      data.storage_condition = pharmacydrugData.storage_condition.label;
      data.rx_category=pharmacydrugData.rx_category.label;
      data.category = pharmacydrugData.category.label;
      data.unit_of_measure = pharmacydrugData.unit_of_measure.label;
      data.conversion_factor=pharmacydrugData.conversion_factor;
      data.minimum_quantity=pharmacydrugData.minimum_quantity;
      data.maximum_quantity=pharmacydrugData.maximum_quantity;
      data.reorder_quantity=pharmacydrugData.reorder_quantity;
      
     
     
      let records = this.state.records;
      if (this.state.drug_action === "Add") {
        axios
          .post(addPharmacyDrugPlanning, data, CONFIG)
          .then((addDrugResponse) => {
            let res = addDrugResponse.data;
            console.log(res)
            if (res.status === "1") {
             
              toast.success("The Pharmacy Drug Planning details have been added successfully.", {
                className: "toast-success"
              });
              this.clearMessage();
              this.cancel();
            } else {
              this.setState({
                searchrecords: records,
                searchTableSuccess: res,
                editformSuccess: res,
                loading: false,
              });
              toast.error("Drug could not be Added. ", {
                className: "toast-error"
              });
              this.clearMessage();
            }
          })
          .catch(() => { });
      } else {
        axios
          .post(updatePharmacyDrugPlanning, data, CONFIG)
          .then((updateDrugResponse) => {
            let updateResponse = updateDrugResponse.data;
            if (updateResponse.status === "1") {
              let addDrugSuccess = this.state.addDrugSuccess;
              addDrugSuccess = updateResponse.message;
              this.setState({ addDrugSuccess: addDrugSuccess });

              /** Updating the data table */
              let existingRecords = this.state.records;
              var index = existingRecords.findIndex(
                (item) => item.id === data.id
              );
              existingRecords.splice(index, 1, data);
              this.setState({ records: existingRecords });
               this.setState({ tableSuccess: updateResponse });
              this.setState({ editformSuccess: updateResponse });
              toast.success("The Drug details have been updated successfully.", {
                className: "toast-success"
              });
              this.clearMessage();
              this.clearAll();
            } else {
              this.setState({ tableSuccess: updateResponse });
              this.setState({ editformSuccess: updateResponse });
              toast.error("Drug could not be updated. ", {
                className: "toast-error"
              });
              this.clearMessage();
            }
            this.setState({ loading: false });
          })
          .catch(() => { });
      }
    }
  }

  onDeleteClick(record) {
    this.setState({ addPopup: true, record: record });

  }

  cancelDelete() {
    this.setState({
      showDeleteConfirmPopup: !this.state.showDeleteConfirmPopup,
    });
  }

  deleteRecord(record) {
    let userId = record.id;
    this.setState({ loading: true });
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    axios
      .delete(deleteDrug, { data: { id: userId } }, CONFIG)
      .then((res) => {
        let response = res.data;
        if (response.status === "1") {
          let records = this.state.records;
          const deleteIndex = records.map((item) => item.id).indexOf(record.id);
          records.splice(deleteIndex, 1);
          this.setState({ records });
        }
        this.setState({ addPopup: false, record: "", loading: false });
        this.setState({ tableSuccess: res.data });
        toast.error("The Drug detail has been deleted successfully.", {
          className: "toast-error"
        });
        this.clearMessage();
        this.clearAll();
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${this.props.access_token}`;
        axios
          .post(getAllDrugs, { user_id: this.props.userdata.id }, CONFIG)
          .then((list) => {
            let drugList = list.data.data;
            let drugDataArr = [];
            drugList.forEach((element, index) => {
              let drugData = {};
              drugData.s_no = index + 1;
              drugData.id = element.id;
              drugData.user_id = this.props.userdata.id;
              drugData.drug_id = element.drug_id;
              drugData.drug_name = element.drug_name;
              drugData.drug_type = element.drug_type;
              drugData.drug_category = element.drug_category;
              drugData.generic_name=element.generic_name;
              drugData.manufacturer = element.manufacturer;
              drugData.active_ingredients = element.active_ingredients;
              drugData.unit_of_measure = element.unit_of_measure;
              drugDataArr[index] = drugData;
            });
    
            this.setState({ records: drugDataArr });
          })
          .catch(() => { });
    
      })
      .catch(() => { });
  
  }
  clearMessage = () => {
    setTimeout(
      () =>
        this.setState({
          formSuccess: { message: "" },
          tableSuccess: { message: "" },
          searchTableSuccess: { message: "" },
          editformSuccess: { message: "" },
        }),
      5000
    );
  };
  clearAll=()=> {
    let drug_action = this.state.drug_action;
    drug_action = "Add";
    this.setState({ drug_action });
    let drugData = {};
    drugData.drug_id = "";
    drugData.drug_name = "";
    drugData.manufacturer = "";
    drugData.drug_category = "";
    drugData.generic_name="";
    drugData.drug_type = "";
    drugData.active_ingredients = "";
    drugData.unit_of_measure = "";
    let errors = {
      drug_id: "",
      drug_name: "",
      drug_type: "",
      drug_category: "",
      unit_of_measure: "",
    }
    let tableSuccess = {
      message: "",
      status: "",
    };
    let formSuccess = {
      message: "",
      status: "",
    };
    let editformSuccess = {
      message: "",
      status: "",
    };
    let searchTableSuccess = {
      message: "",
      status: "",
    }
    

    this.setState({ drugData, errors, tableSuccess, formSuccess, editformSuccess, searchTableSuccess, searchErrorMessage: "",searchrecords: [],search_action:false });
    

  }
  clearimport=()=>{
    this.setState({ file:"" });
  };
  searchandshowDrug() {
    let drugData = this.state.drugData;
    console.log(drugData);
    if (
      (drugData.drug_name === "" || null === drugData.drug_name) &&
      (drugData.drug_category === "" || null === drugData.drug_category) &&
      (drugData.drug_type === "" || null === drugData.drug_type) &&
      (drugData.unit_of_measure === "" || null === drugData.unit_of_measure) &&
      drugData.manufacturer === "" &&
      drugData.active_ingredients === "" &&
      drugData.generic_name===""
    ) {
      this.setState({
        searchErrorMessage:
          "Choose any one of the search filters other than Drug ID.",
      });
      return;
    } else {
      this.setState({
        searchErrorMessage: "",
      });
    }
    let data = {};
    data.drug_name = drugData.drug_name;
    data.drug_category = drugData.drug_category
      ? drugData.drug_category.label
      : "";
    data.drug_type = drugData.drug_type ? drugData.drug_type.label : "";
    data.manufacturer = drugData.manufacturer;
    data.active_ingredients = drugData.active_ingredients;
    data.generic_name=drugData.generic_name;
    data.unit_of_measure=drugData.unit_of_measure.label;
    data.type = "admin";
    data.user_id = this.props.userdata.id;
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.access_token}`;
    this.setState({ loading: true }, () => {
      axios.post(searchDrug, data, CONFIG).then((searchres) => {
        this.setState({ loading: false });
        let result = searchres.data;
        let data = [];
        if (result.countOfRecords > 0) {
          let records = result.data;
          //  console.log(this.state.masterdrugmeasure[0]);
          records.forEach((element) => {
            console.log(element);
            console.log(element.drug_name);
            console.log(element.drug_category);
            console.log(element.unit_of_measure);
            data.push({
              drug_id: "",
              drug_name: element.drug_name,
              drug_name_hide: element.drug_name,
              drug_category: element.drug_category,
              unit_of_measure: element.unit_of_measure,
              drug_category_hide: element.drug_category,
              drug_type: element.drug_type,
              drug_type_hide: element.drug_type,
              generic_name:element.generic_name,
              manufacturer: element.manufacturer,
              active_ingredients: element.active_ingredients,
              id: element.id,
              drugid_error: "",
              drugcategory_error: "",
              drugtype_error: "",
              drugname_error: "",
              generic_name_error:"",
              manufacturer_error:"",
              active_incredients_error:"",
            });
          });

          this.setState({ searchrecords: data });
          this.setState({
            search_action: true,
          });
        } else {
          this.setState({ searchErrorMessage:"No Drug Found",searchrecords: [], search_action: true });
        }
      });
    });
  }
  onFileChange = event => {

    // Update the state
    this.setState({ file: event.target.files[0] });
    console.log(event.target.files[0]);
    readXlsxFile(event.target.files[0]).then((rows) => {
        console.log(rows);
        console.log("rows");
        this.setState({ drugData: rows });
        // `rows` is an array of rows
        // each row being an array of cells.
    })

};
onFileUpload = () => {

   if (this.state.drugData.length) {
      axios.post(hospitaldrugimport, {data: this.state.drugData,user_id:this.props.userdata.id}).then((result) => {
          console.log(result);
          this.setState({ formSuccess: result.data, file: "", drugData: [] })
          axios
          .post(getAllDrugs, { user_id: this.props.userdata.id }, CONFIG)
          .then((list) => {
            let drugList = list.data.data;
            let drugDataArr = [];
            drugList.forEach((element, index) => {
              let drugData = {};
              drugData.s_no = index + 1;
              drugData.id = element.id;
              drugData.user_id = this.props.userdata.id;
              drugData.drug_id = element.drug_id;
              drugData.drug_name = element.drug_name;
              drugData.drug_type = element.drug_type;
              drugData.drug_category = element.drug_category;
              drugData.generic_name=element.generic_name;
              drugData.manufacturer = element.manufacturer;
              drugData.active_ingredients = element.active_ingredients;
              drugData.unit_of_measure = element.unit_of_measure;
              drugDataArr[index] = drugData;
            });
    
            this.setState({ records: drugDataArr });
          })
          .catch(() => { });
          this.clearMessage();
      });
  }

};
  render() {


     const storagecondition = [
        { value: 0, label: "Below 0°C" },
        { value: 1, label: "2°C-8°C" },
        { value: 2, label: "20°C-25°C" },
        { value: 3, label: "Below 30°C" },
        { value: 4, label: "Room Temperature" },
      ];

      const rx_category=[
        { value: 0, label: "Regular" },
        { value: 1, label: "Schedule H" },
        { value: 2, label: "Schedule H1" },
        { value: 3, label: "Schedule X" },

      ];
      
    let ExpirePopupClose = () => {
      window.location.reload();
      this.setState({ showexpirePopup: true })
    };
    let ExpirePopupConfirm = () => {
      window.location.href = "/showsubscription";

    };
    let PopupClose = () => this.setState({ addPopup: false });
    let PopupConfirm = () => this.deleteRecord(this.state.record);
    let PopupHospitalClose = () => {
      this.setState({ hospitalpopup:true})
     
    };
    const {
      drugData,
      pharmacydrugData,
      drugTypes,
      drugCategories,
      errors,
      loading,
      masterdrugNames,
      mastermanufaturerNames,
      ActiveIngredientList,
      GenericNameList,
      searchTableSuccess,
      editformSuccess,
      tableSuccess,
      searchErrorMessage,
      hospitaltimeout,

      formSuccess,
    } = this.state;
    const modalVar = this.state.showDeleteConfirmPopup;

    return (
      <div id="layout-wrapper">
        {/* <SideBarNew /> */}
       {/*  <ExpirePopup
          show={!this.state.showexpirePopup}
          onHide={ExpirePopupClose}
          onConfirm={ExpirePopupConfirm}
          title={"Alert"}
          message={"Your current plan has been expired. Please activate new plan and try again"}
        /> */}
        <IdleTimeOutModal
          showModal={this.state.showModal}
          handleClose={this.handleClose}
          handleLogout={this.handleLogout}
        />

        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={hospitaltimeout} />

 {loading && <Dnaspinner />} 
{/* {loading && <LoadingHeartrate />} */}

        <div className="main-content bacfot">
          <div className="mainpage">
            <Header user_id={this.props.userdata.id} />
            <div className="main-box">
              <div className="sidemenu pr0">
                <BusinessUserSideBar />
              </div>

              {
                                   (this.state.adminstatus !== "" && this.state.adminstatus === "Pending") ? (
                                        <div className="container-fluid">
                                                    <div className="checkout-tabs">
                                                        
                                    <div className="row">
                                        <div className="col-sm-12 pr0">
                                            <div className="card">
                                                <div className="card-body a-page-1-hight-right ">
                                                    {/* Admin not approved your account yet to view this page. Please contact the administrator to approve your account. */}
                                                    <Popupinfo
                                                     show={!this.state.hospitalpopup}
                                                     onHide={PopupHospitalClose}
                                                     title={"Alert!!"}
                                                      message={" Your Account is now waiting for our approval. Kindly contact the administrator to approve your request."}
                                                      />
                                               </div>

                                            </div>
                                        </div>
                                        </div>
                                        </div>

                                    </div>) :
           (

              <section className="main-body hospital-profilegap">
                <div className="container-fluid">
                  <div className="checkout-tabs">

                    <div className="col-sm-12">
                      <h4 className="font-weight-bold"> Item List</h4>
                      <div className="row mt-4">

                        <div className="col-sm-12">
                          <div className="card ">
                            <div className="card-body ">
                              <div
                                className="tab-content"
                                id="v-pills-tabContent"
                              >
                                    <div className="float-right btn "> <DrugsExport business_id={this.props.userdata.id} records={this.state.records}/></div>
                              
                                <div
                                  className="tab-pane fade show active"
                                  id="v-pills-shipping"
                                  role="tabpanel"
                                  aria-labelledby="v-pills-shipping-tab"
                                >
                                  <div className="user-notification">
                                    <SpanField
                                      classname={
                                        "0" === tableSuccess.status
                                          ? "text-danger user-notification"
                                          : "text-success user-notification"
                                      }
                                      title={tableSuccess.message}
                                    />
                                  </div>
                                  <ReactDatatable
                                    className="table table-bordered table-striped kas  mobile_table"
                                    config={this.config}
                                    records={this.state.records}
                                    columns={this.columns}
                                  />
                                  <Popup
                                    show={this.state.addPopup}
                                    onHide={PopupClose}
                                    onConfirm={PopupConfirm}
                                    title={"Confirmation"}
                                    message={
                                      "Are you sure you want to delete this record ?"
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                       
                        <div className="col-sm-12 pr0">
                          <div className="card m565">
                          <div className="card-header"> <div className="card-title"> {this.state.drug_action === "Add"
                                  ? "Add"
                                  : "Edit"}{" "}
                                 Item &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;Additional Details</div> </div>
                            <div className="card-body">
                              
                              <div className="user-notification-box text-center">
                                <SpanField
                                  classname={
                                    "0" === editformSuccess.status
                                      ? "text-danger user-notification"
                                      : "text-success user-notification"
                                  }
                                  title={editformSuccess.message}
                                />
                                <ToastContainer position="bottom-center" hideProgressBar={true} autoClose={2000} closeOnClick={false} pauseOnHover />

                              </div>
                              <div className="user-notification">
                                {searchErrorMessage ? (
                                  <div
                                    className="alert alert-danger"
                                    role="alert"
                                  >
                                    {searchErrorMessage}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              {/* <div>

                                <hr className="rowline" />
                              </div> */}


                              
                              <div className="row">
                                
                              <div className="col-sm-6">
                                  <div className="frllftfull">
                                    <div className="form-group frllft mbbre">
                                    <Label title="Drug Status" isMandatory={true} />
                                    </div>
                                    <div className="form-group frlrit">
                                    
                                            <CheckboxOrRadioGroup
                                              setName={"drug_status"}
                                              type={"radio"}
                                              controlFunc={this.handledrugstatus}
                                              options={drug_status}
                                              selectedOptions={this.state.pharmacydrugData.drug_status}
                                              checked={this.state.pharmacydrugData.drug_status==="Active"? true : false}
                                            />
                                      <SpanField
                                        classname="text-danger"
                                        title={errors.drug_status}
                                      />
                                    </div>
                                  </div>
                                </div>
                                
                               

                                <div className="col-sm-6">
                                  <div className="frllftfull">
                                    <div className="form-group frllft mbbre">
                                    <Label title="Category"  />  
                                    </div>
                                    <div className="form-group frlrit">
                                    <div className="position-relative">
                                        <div className="input-group">
                                          <div className="input-group-prepend">
                                            <span className="input-group-text inpico">
                                              <i className="fas fa-search"></i>
                                            </span>
                                          </div>
                                    <Select maxMenuHeight={120}
                                            className="selectcls p-0"
                                            name="category"
                                            isMandatory={true}
                                            value={pharmacydrugData.category}
                                            onChange={this.handlecategoryChange}
                                            options={categorylist}
                                          />
                                      <SpanField
                                          classname="text-danger"
                                          title={errors.category}
                                        />
                                    </div>
                                    </div>
                                    </div>
                                  </div>
                                </div>


                                <div className="col-sm-6">
                                  <div className="frllftfull">
                                    <div className="form-group frllft mbbre">
                                    <Label title="Drug ID" isMandatory={true} />
                                    </div>
                                    <div className="form-group frlrit">
                                      <InputField
                                        inputType={"text"}
                                        isMandatory={true}
                                        name={"drug_id"}
                                        controlFunc={this.handleInput.bind(
                                          this
                                        )}
                                        content={pharmacydrugData.drug_id}
                                        placeholder={""}
                                        max={5}

                                      />
                                      <SpanField
                                        classname="text-danger"
                                        title={errors.drug_id}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="col-sm-6">
                                  <div className="frllftfull">
                                    <div className="form-group frllft mbbre">
                                    <Label title="Storage Bin"  />  
                                    </div>
                                    <div className="form-group frlrit">
                                      <InputField
                                        inputType={"text"}
                                        name={"storage_bin"}
                                        controlFunc={this.handleInput}
                                        content={pharmacydrugData.storage_bin}
                                        placeholder={""}
                                        max={30}
                                      />
                                      <SpanField
                                          classname="text-danger"
                                          title={errors.storage_bin}
                                        />
                                    </div>
                                  </div>
                                </div>
                                
                              
                              </div>

                              <div className="row">

                              <div className="col-sm-6">
                                  <div className="frllftfull">
                                    <div className="form-group frllft mbbre">
                                    <Label title="Generic Name" isMandatory={true} />  
                                    </div>
                                    <div className="form-group frlrit">
                                    <div className="position-relative">
                                        <div className="input-group">
                                         
                                          <InputField
                                        inputType={"text"}
                                        name={"generic_name"}
                                        controlFunc={this.handleInput}
                                        content={pharmacydrugData.generic_name}
                                        placeholder={""}
                                        max={30}
                                      />
                                     
                                    </div>
                                    <SpanField
                                          classname="text-danger"
                                          title={errors.generic_name}
                                        />
                                    </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-sm-6">
                                  <div className="frllftfull">
                                    <div className="form-group frllft mbbre">
                                    <Label title="Storage Condition" /> 
                                    </div>
                                    <div className="form-group frlrit">
                                      <div className="position-relative">
                                        <div className="input-group">
                                          <div className="input-group-prepend">
                                            <span className="input-group-text inpico">
                                              <i className="fas fa-search"></i>
                                            </span>
                                          </div>
                                          <Select maxMenuHeight={120}
                                            className="selectcls p-0"
                                            name="storage_condition"
                                            isMandatory={true}
                                            value={pharmacydrugData.storage_condition}
                                            onChange={this.handlestorageconditionChange}
                                            options={storagecondition}
                                          />
                                        </div>
                                        <SpanField
                                          classname="text-danger"
                                          title={errors.storage_condition}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  </div>

                              <div className="col-sm-6">
                                  <div className="frllftfull">
                                    <div className="form-group frllft mbbre">
                                    <Label title="Drug Name" isMandatory={true} /> 
                                    </div>
                                    <div className="form-group frlrit">
                                      <div className="position-relative">
                                        <div className="input-group">
                                          <div className="input-group-prepend">
                                            <span className="input-group-text inpico">
                                              <i className="fas fa-search"></i>
                                            </span>
                                          </div>
                                          <Select maxMenuHeight={120}
                                            className="selectcls p-0"
                                            name="drug_name"
                                            isMandatory={true}
                                            value={pharmacydrugData.drug_name}
                                            onChange={this.handleselectChange}
                                            isDisabled={this.state.disable_drug_name?false:true}
                                            options={masterdrugNames}
                                          />
                                        </div>
                                        <SpanField
                                          classname="text-danger"
                                          title={errors.drug_name}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>

                            
                                <div className="col-sm-6">
                                  <div className="frllftfull">
                                    <div className="form-group frllft mbbre">
                                    <Label title="RX Category"  /> 
                                    </div>
                                    <div className="form-group frlrit">
                                      <div className="position-relative">
                                        <div className="input-group">
                                          <div className="input-group-prepend">
                                            <span className="input-group-text inpico">
                                              <i className="fas fa-search"></i>
                                            </span>
                                          </div>
                                          <Select maxMenuHeight={120}
                                            className="selectcls p-0"
                                            name="rx_category"
                                            isMandatory={true}
                                            value={pharmacydrugData.rx_category}
                                            onChange={this.handleselectChange}
                                            options={rx_category}
                                          />
                                        </div>
                                        <SpanField
                                          classname="text-danger"
                                          title={errors.rx_category}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  </div>

                              
                                  <div className="col-sm-6">
                                  <div className="frllftfull">
                                    <div className="form-group frllft mbbre">
                                    <Label title="Active Ingredient" isMandatory={true} /> 
                                    </div>
                                    <div className="form-group frlrit">
                                      <div className="position-relative">
                                        <div className="input-group">
                                          <div className="input-group-prepend">
                                            <span className="input-group-text inpico">
                                              <i className="fas fa-search"></i>
                                            </span>
                                          </div>
                                          <Select maxMenuHeight={120}
                                            className="selectcls p-0"
                                            name="active_ingredients"
                                            isMandatory={true}
                                            value={pharmacydrugData.active_ingredients}
                                            onChange={this.handleactiveingredientsSelect}
                                            isDisabled={this.state.disable_drug_name?false:true}
                                            options={ActiveIngredientList}
                                          />
                                        </div>
                                        <SpanField
                                          classname="text-danger"
                                          title={errors.active_ingredients}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>

                               
                               
                                <div className="col-sm-6">
                                  <div className="frllftfull">
                                    <div className="form-group frllft mbbre">
                                    <Label title="Minimum Qty"  />  
                                    </div>
                                    <div className="form-group frlrit">
                                      <InputField
                                        inputType={"text"}
                                        name={"minimum_quantity"}
                                        controlFunc={this.handleInput}
                                        content={pharmacydrugData.minimum_quantity}
                                        placeholder={""}
                                        max={30}
                                      />
                                      <SpanField
                                          classname="text-danger"
                                          title={errors.minimum_quantity}
                                        />
                                    </div>
                                  </div>
                                </div>

                                <div className="col-sm-6">
                                  <div className="frllftfull">
                                    <div className="form-group frllft mbbre">
                                    <Label title="Manufacturer" isMandatory={true} /> 
                                    </div>
                                    <div className="form-group frlrit">
                                      <div className="position-relative">
                                        <div className="input-group">
                                          <div className="input-group-prepend">
                                            <span className="input-group-text inpico">
                                              <i className="fas fa-search"></i>
                                            </span>
                                          </div>
                                          <Select maxMenuHeight={120}
                                            className="selectcls p-0"
                                            name="manufacturer"
                                            isMandatory={true}
                                            value={pharmacydrugData.manufacturer}
                                            onChange={this.handlemanufacturerselect}
                                            options={mastermanufaturerNames}
                                          />
                                        </div>
                                        <SpanField
                                          classname="text-danger"
                                          title={errors.manufacturer}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-sm-6">
                                  <div className="frllftfull">
                                    <div className="form-group frllft mbbre">
                                    <Label title="Maximum Qty"  />  
                                    </div>
                                    <div className="form-group frlrit">
                                      <InputField
                                        inputType={"text"}
                                        name={"maximum_quantity"}
                                        controlFunc={this.handleInput}
                                        content={pharmacydrugData.maximum_quantity}
                                        placeholder={""}
                                        max={30}
                                      />
                                      <SpanField
                                          classname="text-danger"
                                          title={errors.maximum_quantity}
                                        />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div className="frllftfull">
                                    <div className="form-group frllft mbbre">
                                    <Label title="HSN Code" isMandatory={true} />  
                                    </div>
                                    <div className="form-group frlrit">
                                      <InputField
                                        inputType={"text"}
                                        name={"hsn_code"}
                                        controlFunc={this.handleInput}
                                        content={pharmacydrugData.hsn_code}
                                        placeholder={""}
                                        max={30}
                                      />
                                      <SpanField
                                          classname="text-danger"
                                          title={errors.hsn_code}
                                        />
                                    </div>
                                    
                                  </div>
                                </div>


                                <div className="col-sm-6">
                                  <div className="frllftfull">
                                    <div className="form-group frllft mbbre">
                                    <Label title="Reorder Qty"  />  
                                    </div>
                                    <div className="form-group frlrit">
                                      <InputField
                                        inputType={"text"}
                                        name={"reorder_quantity"}
                                        controlFunc={this.handleInput}
                                        content={pharmacydrugData.reorder_quantity}
                                        placeholder={""}
                                        max={30}
                                      />
                                      <SpanField
                                          classname="text-danger"
                                          title={errors.reorder_quantity}
                                        />
                                    </div>
                                  </div>
                                </div>

                                <div className="col-sm-6">
                                  <div className="frllftfull">
                                    <div className="form-group frllft mbbre">
                                    <Label title="IGST"  />  
                                    </div>
                                    <div className="form-group frlrit">
                                      <InputField
                                        inputType={"text"}
                                        name={"igst"}
                                        controlFunc={this.handleInput}
                                        content={pharmacydrugData.igst}
                                        placeholder={""}
                                        max={30}
                                      />
                                      <SpanField
                                          classname="text-danger"
                                          title={errors.igst}
                                        />
                                    </div>
                                    <div className="frllftfull d-flex">
                                    <div className="form-group frllft mbbre">
                                    <Label title="CGST" />  
                                    </div>
                                    <div className="form-group frlrclfgf">
                                      <InputField
                                        inputType={"text"}
                                        name={"cgst"}
                                        controlFunc={this.handleInput}
                                        content={pharmacydrugData.cgst}
                                        placeholder={""}
                                        max={30}
                                      />
                                      <SpanField
                                          classname="text-danger"
                                          title={errors.cgst}
                                        />
                                    </div>
                                    <div className="form-group ml-4 mr-5">
                                    <Label title="SGST"  />  
                                    </div>
                                    <div className="form-group frlrclfgf">
                                      <InputField
                                        inputType={"text"}
                                        name={"sgst"}
                                        controlFunc={this.handleInput}
                                        content={pharmacydrugData.sgst}
                                        placeholder={""}
                                        max={30}
                                      />
                                      <SpanField
                                          classname="text-danger"
                                          title={errors.sgst}
                                        />
                                    </div>
                                  </div>
                                  </div>
                                </div>

                                
                                <div className="col-sm-6">
                                  
                                </div>
                                
                                
                               

                                <div className="col-sm-6">
                                  <div className="frllftfull">
                                    <div className="form-group frllft mbbre">
                                    <Label title="Pack" isMandatory={true} /> 
                                    </div>
                                    <div className="form-group frlrit">
                                      <div className="position-relative">
                                        <div className="input-group">
                                          <div className="input-group-prepend">
                                            <span className="input-group-text inpico">
                                              <i className="fas fa-search"></i>
                                            </span>
                                          </div>
                                          <Select maxMenuHeight={120}
                                            className="selectcls p-0"
                                            name="unit_of_measure"
                                            isMandatory={true}
                                            value={pharmacydrugData.unit_of_measure}
                                            onChange={this.handleselectChange}
                                            options={this.state.masterdrugmeasure}
                                          />
                                        </div>
                                        <SpanField
                                          classname="text-danger"
                                          title={errors.unit_of_measure}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                
                              </div>

                          
                              <div className="row">

                              
                                
                                

                                

                                <div className="col-sm-6">
                                  <div className="frllftfull">
                                    <div className="form-group frllft mbbre">
                                    <Label title="Conversion Factor" />  
                                    </div>
                                    <div className="form-group frlrit">
                                      <InputField
                                        inputType={"text"}
                                        name={"conversion_factor"}
                                        controlFunc={this.handleInput}
                                        content={pharmacydrugData.conversion_factor}
                                        placeholder={""}
                                        max={30}
                                      />
                                      <SpanField
                                          classname="text-danger"
                                          title={errors.conversion_factor}
                                        />
                                    </div>
                                  </div>
                                </div>

                                
                              </div>

                              <div className="row">
                               
                                <div className="col-sm-12 text-right">
                                  <button
                                    className="signupbtn abtn mr-2 phone-button"
                                    onClick={this.addorupdateDrug.bind(this)}
                                  >
                                    {" "}
                                    {this.state.drug_action === "Add"
                                      ? "Add"
                                      : "Update"}
                                  </button>
                              {/*     <button
                                    className="signupbtn abtn mr-2 phone-button" disabled={this.state.drug_action=="Edit"?true:false}
                                    onClick={this.searchandshowDrug.bind(
                                      this
                                    )}
                                  >
                                    Search
                                  </button> */}
                                  <button className="signupbtn abtn phone-button" align="left" onClick={this.cancel}>
                                    Reset
                                  </button>
                                </div>
                                {" "}
                               
                                {""}
                                
                              </div>

                            </div>
                          </div>
                        </div>

                        {/* <div className="col-sm-6 pr0">
                          <div className=" card m565"> */}
                        {/* <div className="card-body">
                              <div>
                                <h5 className="regxs">Search Drug</h5>
                                <hr className="rowline" />
                              </div>
                              <div className="user-notification">
                                {searchErrorMessage ? (
                                  <div
                                    className="alert alert-danger"
                                    role="alert"
                                  >
                                    {searchErrorMessage}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="row">
                                <div className="col-sm-10">
                                  <div className="frllftfull">
                                    <div className="form-group frllft mbbre">
                                      <label>Drug Name</label>
                                    </div>

                                    <div className="form-group frlrit">
                                      <div className="position-relative">
                                        <div className="input-group">
                                          <div className="input-group-prepend">
                                            <span className="input-group-text inpico">
                                              <i className="fas fa-search"></i>
                                            </span>
                                          </div>
                                          <Select maxMenuHeight={120}
                                            isClearable={true}
                                            className="selectcls p-0"
                                            name="drug_name"
                                            isMandatory={true}
                                            value={searchData.drug_name}
                                            onChange={
                                              this.handlesearchselectChange
                                            }
                                            options={masterdrugNames}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-sm-10">
                                  <div className="frllftfull">
                                    <div className="form-group frllft mbbre">
                                      <label>Drug Category</label>
                                    </div>
                                    <div className="form-group frlrit">
                                      <div className="position-relative">
                                        <div className="input-group">
                                          <div className="input-group-prepend">
                                            <span className="input-group-text inpico">
                                              <i className="fas fa-search"></i>
                                            </span>
                                          </div>
                                          <Select maxMenuHeight={120}
                                            isClearable={true}
                                            className="selectcls p-0"
                                            name="drug_category"
                                            isMandatory={true}
                                            value={searchData.drug_category}
                                            onChange={
                                              this.handlesearchselectChange
                                            }
                                            options={drugCategories}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-sm-10">
                                  <div className="frllftfull">
                                    <div className="form-group frllft mbbre">
                                      <label>Drug Type</label>
                                    </div>
                                    <div className="form-group frlrit">
                                      <div className="position-relative">
                                        <div className="input-group">
                                          <div className="input-group-prepend">
                                            <span className="input-group-text inpico">
                                              <i className="fas fa-search"></i>
                                            </span>
                                          </div>
                                          <Select maxMenuHeight={120}
                                            isClearable={true}
                                            className="selectcls p-0"
                                            name="drug_type"
                                            isMandatory={true}
                                            value={searchData.drug_type}
                                            onChange={
                                              this.handlesearchselectChange
                                            }
                                            options={drugTypes}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-sm-10">
                                  <div className="frllftfull">
                                    <div className="form-group frllft mbbre">
                                      <label>Manufacturer</label>
                                    </div>
                                    <div className="form-group frlrit">
                                      <InputField
                                        inputType={"text"}
                                        name={"manufacturer"}
                                        controlFunc={
                                          this.handlesearchinputChange
                                        }
                                        content={searchData.manufacturer}
                                        placeholder={""}
                                        max={50}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-sm-10">
                                  <div className="frllftfull">
                                    <div className="form-group frllft mbbre">
                                      <label>Active Ingredients</label>
                                    </div>
                                    <div className="form-group frlrit">
                                      <InputField
                                        inputType={"text"}
                                        name={"active_ingredients"}
                                        controlFunc={
                                          this.handlesearchinputChange
                                        }
                                        content={searchData.active_ingredients}
                                        placeholder={""}
                                        max={50}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="row m-b-50">
                                <div className="col-sm-4"></div>
                                <div className="col-sm-3">
                                  <div className="form-group mb-0">
                                    <button
                                      className="signupbtn abtn btdpml"
                                      onClick={this.searchandshowDrug.bind(
                                        this
                                      )}
                                    >
                                      {/* {" "}
                                      {loading ? (
                                        <LoadingSpinner title="Search" />
                                      ) : (
                                        "Search"
                                      )} */}
                        {/* Search
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div> */}
                        {/* </div>
                        </div>  */}



                        <div
                          className="w100"
                          style={{
                            display: this.state.search_action
                              ? "block"
                              : "none",
                          }}
                        >
                         {/*  <div className="col-sm-12">
                            <div className="hmeclr bgligtrn">
                              <h5 className="fnsz hptd"> Search Result </h5>
                            </div>
                          </div>
                          <div className="col-sm-12">
                            <div className="card">
                              <div className="card-body">
                                <div
                                  className="tab-content"
                                  id="v-pills-tabContent"
                                >
                                  <div
                                    className="tab-pane fade show active"
                                    id="v-pills-shipping"
                                    role="tabpanel"
                                    aria-labelledby="v-pills-shipping-tab"
                                  >
                                    <div className="user-notification">
                                      <SpanField
                                        classname={
                                          "0" === searchTableSuccess.status
                                            ? "text-danger user-notification"
                                            : "text-success user-notification"
                                        }
                                        title={searchTableSuccess.message}
                                      />
                                    </div>
                                    <ReactDatatable
                                      className="table table-bordered table-striped kas  mobile_table"
                                      config={this.searchconfig}
                                      records={this.state.searchrecords}
                                      columns={this.searchcolumns}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> */}

                         
                        </div>
                      </div>
                      {/* <Footerad /> */}
                    </div>
                    <Modal isOpen={modalVar}>
                      <ModalHeader>
                        <label className="text-center wdt100">
                          Confirmation
                        </label>
                      </ModalHeader>
                      <ModalBody>
                        <div className="col-sm-12">
                        Are you sure you want to delete this record ?
                        </div>
                        <div className="row">
                          <div className="col-sm-3">
                            <Button
                              color="success"
                              onClick={() => this.okDelete.bind(this)}
                            >
                              Ok
                            </Button>
                          </div>
                          <div className="col-sm-3">
                            <Button
                              color="danger"
                              onClick={this.cancelDelete.bind(this)}
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                      </ModalBody>
                    </Modal>

                  </div>
                </div>

          
              </section>)}
              <FooterSection />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PharmacyDruglist);
function findWithAttr(array, attr, value) {
  for (var i = 0; i < array.length; i += 1) {
    if (array[i][attr] === value) {
      return i;
    }
  }
  return -1;
}
