import React, { Fragment } from "react";
import PropTypes from "prop-types";
import StarField from "../UI/StarField";
const InputField = (props) => (
  <Fragment>
    {props.title ? (
      <label>
        {props.title}
        {props.isMandatory ? <StarField /> : ""}
      </label>
    ) : (
      ""
    )}
    <input
      className="form-control"
      id={props.id}
      name={props.name}
      type={props.inputType}
      value={props.content}
      max={props.max}
      min={props.min}
      step="any"
      onChange={props.controlFunc}
      placeholder={props.placeholder}
      disabled={props.isvisible}
      readOnly={props.readOnly}
    />
  </Fragment>
);

InputField.propTypes = {
  inputType: PropTypes.oneOf(["text", "number", "password", "date"]).isRequired,
  // title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  controlFunc: PropTypes.func.isRequired,
  // content: PropTypes.oneOfType([
  //     PropTypes.string,
  //     PropTypes.number,
  // ]).isRequired,
  placeholder: PropTypes.string,
  isMandatory: PropTypes.bool,
};

export default InputField;
