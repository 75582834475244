import React, { Component } from "react";

import LoginSection from "../login/login";
import LogoSection from "../../components/UI/LogoSection";
import FooterSection from "../../components/UI/FooterSection";
import ClientSignup from "../../components/container/ClientSignup";
import BusinessSignup from "../../components/container/BusinessSignup";
import ConsumerSignup from "../../components/container/ConsumerSignup";
import Heading4 from "../../components/UI/Heading4";
import queryString from "query-string";

import { connect } from "react-redux";
import PharmacySignup from "../../components/container/PharmacySignup";

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    setReferralData: (referral_code) =>
      dispatch({ type: "SET_REFERALDATA", payload: referral_code }),
  };
}

class Register extends Component {
  constructor(props) {
    super(props);
    console.log(props.location.search.referral_code);
    this.state = {
      user_type: "client",
    };
  }
  componentDidMount() {
    const values = queryString.parse(this.props.location.search);
let ref_value = values.referral_code != undefined ? values.referral_code : this.props.match.params.referral_code != undefined ? this.props.match.params.referral_code:"";
// console.log(values);
    // console.log(this.props.match.params.referral_code);
    console.log(ref_value);
// return false;
    this.props.setReferralData(ref_value);
  }
  changeUsertype = (user_type, e) => {
    console.log(e);
    console.log(e.target);
    console.log(user_type);
    console.log(user_type.target);
    document.getElementsByName("first_name")[0].value = "";
    // var value = this.refs.clientform.getValue();
    // console.log(value);

    console.log(this.props);
  };
  render() {
    return (
      <div id="layout-wrapper">
        {/* <!-- ============================================================== --> */} 
        {/* <!-- Start right Content here --> */}
        {/* <!-- ============================================================== --> */}
        <div className="main-content grayback">
          <div className="mainpage">
            {/* <section className="my-head registerHead">
              <div className="container-fluid">
                <div className="row">
                 


                  <LoginSection />
                </div>
              </div>
            </section> */}

            <section className="registerpage loginbg">
            <div className="container">
            <div className="row">
               
               
               
               <div className="col-md-6">
               <div>
                <div className="d-flex align-items-center justify-content-between mb-4">
                <div className="logocenter registerLogo">
                      <a href="/testhome">
                        <img alt="LOGO" src={require("../../assets/images/loginlogo.png")} />
                      </a>
                    </div>
                <ul class="nav nav-pills mb-0" id="pills-tab" role="tablist">
                 <li class="nav-item">
                   <a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Login</a>
                 </li>
                 <li class="nav-item">
                   <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Register</a>
                 </li>

               </ul>
                </div>
              
               <div class="tab-content" id="pills-tabContent">
                 <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                 <LoginSection />
                 </div>
                 <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                 <div>
                 <div className="card-body p-0">
                   {/* <!-- Nav tabs -->*/}
                   <div className="hmeclr mt-2">
                     <ul className="nav nav-pills manag registerTab" role="tablist">
                       <li className="nav-item waves-effect waves-light">
                         <a
                           className="nav-link  active"
                           data-toggle="tab"
                           href="#client"
                           role="tab"
                         >
                           <span className="d-block d-sm-none">
                             <i className="fas fa-home"></i>
                           </span>
                           <span
                             className="d-none d-sm-block"
                             onClick={this.changeUsertype.bind(this, "client")}
                           >
                             Client
                           </span>
                         </a>
                       </li>
                       <li className="nav-item waves-effect waves-light">
                         <a
                           className="nav-link "
                           data-toggle="tab"
                           href="#doctor"
                           role="tab"
                         >
                           <span className="d-block d-sm-none">
                             <i className="far fa-user"></i>
                           </span>
                           <span
                             className="d-none d-sm-block"
                             onClick={this.changeUsertype.bind(
                               this,
                               "business_user"
                             )}
                           >
                             Doctor
                           </span>
                         </a>
                       </li>
                       <li className="nav-item waves-effect waves-light">
                         <a
                           className="nav-link "
                           data-toggle="tab"
                           href="#business"
                           role="tab"
                         >
                           <span className="d-block d-sm-none">
                             <i className="far fa-envelope"></i>
                           </span>
                           <span
                             className="d-none d-sm-block"
                             onClick={this.changeUsertype.bind(
                               this,
                               "business"
                             )}
                           >
                             Business
                           </span>
                         </a>
                       </li>
                      <li className="nav-item waves-effect waves-light">
                         <a
                           className="nav-link "
                           data-toggle="tab"
                           href="#pharmacy"
                           role="tab"
                         >
                           <span className="d-block d-sm-none">
                             <i className="far fa-envelope"></i>
                           </span>
                           <span
                             className="d-none d-sm-block"
                             onClick={this.changeUsertype.bind(
                               this,
                               "pharmacy"
                             )}
                           >
                             Pharmacy
                           </span>
                         </a>
                       </li>
                        {/* <li className="nav-item waves-effect waves-light">
                         <a
                           className="nav-link "
                           data-toggle="tab"
                           href="#lab"
                           role="tab"
                         >
                           <span className="d-block d-sm-none">
                             <i className="far fa-envelope"></i>
                           </span>
                           <span
                             className="d-none d-sm-block"
                             onClick={this.changeUsertype.bind(
                               this,
                               "lab"
                             )}
                           >
                             Lab
                           </span>
                         </a>
                       </li> */}
                     </ul>
                   </div>

                   <div className="tab-content  text-muted">
                     <div
                       id="client"
                       className="tab-pane fade show active min-hghtpanel bghome "
                     >
                       <div>
                         <Heading4
                           classname="card-title text_white"
                           title="SIGN UP - CLIENT"
                         />
                       </div>
                       <ClientSignup />
                     </div>
                     <div
                       id="business"
                       className="tab-pane fade min-hghtpanel bghome "
                     >
                       <div>
                         <Heading4
                           classname="card-title text_white"
                           title="SIGN UP - BUSINESS"
                         />
                       </div>
                       <BusinessSignup />
                     </div>
                    <div
                       id="pharmacy"
                       className="tab-pane fade min-hghtpanel bghome "
                     >
                       <div>
                         <Heading4
                           classname="card-title text_white"
                           title="SIGN UP - PHARMACY"
                         />
                       </div>
                       <PharmacySignup />
                     </div>
                    {/*   <div
                       id="lab"
                       className="tab-pane fade min-hghtpanel bghome "
                     >
                       <div>
                         <Heading4
                           classname="card-title text_white"
                           title="SIGN UP - LAB"
                         />
                       </div>
                       <BusinessSignup />
                     </div> */}
                     <div
                       id="doctor"
                       className="tab-pane fade min-hghtpanel bghome "
                     >
                       <div>
                         <Heading4
                           classname="card-title text_white"
                           title="SIGN UP - DOCTOR"
                         />
                       </div>
                       <ConsumerSignup />
                     </div>
                   </div>
                 </div>
               </div>
                 </div>
               </div>
               </div>
          
             </div>
             </div>
            </div>
             

            
            </section>
          </div>
        </div>
        {/* <FooterAds /> */}
        {/* <footer className="footer registerFooter adv mt-auto ">
      <div>
        <div className="row">
          <div className="col-sm-12">
            <div className=" d-sm-block text-center ">
              © 2020 Nucleo software solutions, All rights reserved
      </div>
          </div>
        </div>
      </div>
    </footer> */}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Register);

