const SearchReducer = (state = {}, actions) => {
  switch (actions.type) {
    case "SET_SEARCH_DATA":
      return { searchData: actions.payload };
      case "SET_BUSINESS_SHORT_DETAIL":
        return { business_short_detail : actions.payload };
        case "SET_PHARMACY_SHORT_DETAIL":
          return { pharmacy_short_detail : actions.payload };
  
    default:
      return state;
  }
};

export default SearchReducer;
